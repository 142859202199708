import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import { ToastContainer, toast } from 'react-toastify';
 import './Newstaff.css'
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import  { encrypt , decrypt } from 'react-crypt-gsm';
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";
 import html2canvas from "html2canvas";
 import Select1 from 'react-select'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
//import bcrypt from "bcrypt";
import axios from "axios";
import Select from 'react-dropdown-select'
import useKeyboardShortcut from 'use-keyboard-shortcut'
import bcrypt from 'bcryptjs'
import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation} from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import './Addstudent.css'

const Newstaff=({props,isAuthenticated,checkAuthenticated,load_user,store,removeInvoice,id,isadmin,useremail,payment,match,username1})=> {
 
  const [itemname, setItem]=useState();
  const [itemError,setIError]=useState(false);
  const [quantity, setQunatity]= useState();
  const [categoryValue, setCategoryValue]= useState('');
  const [unitValue, setUnitValue]= useState('');
  const [quantityError,setQError]=useState(false);
  const [sellingprice, setSPrice]=useState();
  const [sellingPError,setSPError]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [edit,setEdit]=useState(false);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [grandtotal,setGrandTotal]=useState('');
  const [notInternet,setNoInternet]=useState(false);
  const [buyingprice,setBPrice]=useState();
  const [buyingPError,setBPError]=useState(false);
  const [savebtn,setSavebtn]=useState(true)
  const [wait,setWait]=useState(false)
  const [itemNError,setINError]=useState(false)
  const [data,setData]=useState([""]);
  const [serial,setSerial]=useState();
  const [validated,setValidated]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [all,setAll]=useState('');
  const [dob,setDob]=useState("");
  const [names, setName] = useState("");
  const [kra,SetKra]=useState("");
  const [idno,setIdno]=useState("");
  const [search,setSearch]=useState("");
  const [success,setSuccess]=useState(false);
  const [result,setResult]=useState(false);
  const [result1,setResult1]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [email1,setEmail1]=useState("");
  const [data1,setData1]=useState([""]);
  const [names2, setNames2] = useState("");
  const [email,setEmail]=useState("");
  const [mobile,setMobile]=useState("");
  const [paybill,setPaybill]=useState("");
  const [town,setTown]=useState("");
  const [code,setCode]=useState('');
  const [amountvisible,setAmountVisible]=useState(false);
  const [category, setCategory] = useState( [] );
const [categoryError,setCategoryError]=useState(false);
  const [serialError,setSerialError]=useState(false)
  const wrapper_ref = React.useRef();
  const [unit,setUnit]=useState([
    { id: 1, country: "Male" },
    { id: 2, country: "Female" }, 
  ])
  const [unitError,setunitError]=useState(false)
  //const bcrypt = require('bcrypt');
  const getTotal1=()=>{
  
    const res=data.reduce((prev,item)=>{
      
        return prev +(item.price*item.quantity);
    },0)
    setGrandTotal(res)
   
    }
  const onClick=(e)=>{
    const opt = {
       scale: 4
   }
   

   const elem = wrapper_ref.current;
   html2canvas(elem, opt).then(canvas => {
       const iframe = document.createElement('iframe')
       iframe.name = 'printf'
       iframe.id = 'printf'
       iframe.height = 0;
       iframe.width = 0;
       document.body.appendChild(iframe)

       const imgUrl = canvas.toDataURL({
           format: 'pdf',
           quality: '5.0'
       })

       const style=`
          
           width:100vw;
           position:relative;
           left:0:
           top:0;
           
       `;

       const url = `<img style="${style}" src="${imgUrl}"/>`;
       var newWin = window.frames["printf"];
    
        newWin.document.write(`<body onload="window.print()">${url}</body>`);
      
       newWin.document.close();

   });
 }
  const showToastMessage = () => {
    toast.success('Record saved succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };   
  const showToastMessage5 = () => {

    toast.error('Operation not allowed on admin account', {
        position: toast.POSITION.BOTTOM_CENTER
    });
  };  
  const showToastMessage1 = () => {
    toast.success('Record updated succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };   
  const showToastMessage2 = () => {

    toast.error('You dont have permission to perform this action', {
        position: toast.POSITION.TOP_CENTER
    });
  }; 
  const removeErrors=()=>{
    setIError(false)
    setBPError(false)
    setCategoryError(false)
    setunitError(false)
    setQError(false)
    setBPError(false)
    setSPError(false)
  }
  const validateFields=(e)=>{
    e.preventDefault()
    if(itemname && quantity && buyingprice && sellingprice && !categoryValue.trim().length==0 &&
    categoryValue!=="Select Category"&& !unitValue.trim().length==0 &&
    unitValue!=="Select Unit"){
      setValidated(true)}
    
    else{
      if(!itemname){
        setIError(true)
       
        }
        if(categoryValue.trim().length==0 | categoryValue=="Select Category"){
          setCategoryError(true)
          }
        if(unitValue.trim().length==0 | unitValue=="Select Unit"){
          setunitError(true)
         
          }
          if(!buyingprice){
            setBPError(true)
           
            }
            if(!sellingprice){
              setSPError(true)
             
              }
              if(!quantity){
                setQError(true)
               
                }
                
    }
  }
  function hidesuccess() {
    return new Promise(resolve => {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    });
  }
  const clearFields=()=>{
    setItem('')
    setQunatity('')
    setBPrice('')
    setSPrice('')
   
  }
  const handleCategoryChange = (event) => {
    setCategoryValue(event.target.value) 
  } 
  const handleUnitChange = (event) => {
    setUnitValue(event.target.value) 
  } 
  useEffect(()=>{
//check if edit is true
const itemnumber = /[^/]*$/.exec(window.location.href)[0];
//alert(match.params.id1)
let pid= match.params.id
 if(pid)
 {
setResult1(true)
 setSavebtn(false)
 //alert('ok')
 }
     //fetch edit records when editing
     if(result1){
     
     //set edit
     setEdit(true);
     setEditbtn(true)
    
       //set local invoice
      // setInvoiceNumber(invoicenumber)
      
     }else{
      
      
     }

/*  const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }*/
   
  },[])
  
  useEffect(()=>{
    
if(result1){
 
  axios({
    method:'post',
    url:'/searchquote/',
    data:{useremail:useremail,quote:match.params.id,isadmin,store},
  })
  .then(res => {
    
console.log(res.data)
setData(res.data)
setAll(res.data[0].customer)
  })
  .catch(err => {})
}
axios({
  method:'post',
  url:'/totalcompany/',
  data:{useremail:useremail},
})
.then(res => {
    
    setData1(data1);
   
     //set fields
setNames2(res.data[0].names)
setEmail(res.data[0].email)
setMobile(res.data[0].mobile)
setTown(res.data[0].town)
setPaybill(res.data[0].till)

})
.catch(err => {})
  },[result1])
  useEffect(()=>{
    
   fetchCustomer()
      },[all])
      useEffect(()=>{
        getTotal1()
              },[data])  
  const fetchCustomer=()=>{
    if(all){
     // alert("*")
      axios({
     method:'post',
     url:'/searchcustomer/',
     data:{useremail:useremail,name:all},
   })
   .then(res => {
     
       console.log(res.data)
       setName(res.data[0].name)
       setIdno(res.data[0].town)
      SetKra(res.data[0].mobile)
      setDob(res.data[0].email)
       
       
   })
   .catch(err => {})
}
  }
      

   if(payment=="false"){
    return <Redirect to='/payment'/>}
  return (
    <div className='' >
    <ToastContainer
     hideProgressBar={true}
    />
<div className='path'><i className='fa fa-home'></i>/ Sales / Quotedetail</div>
<div className='  bodysection pb-1 col-lg-7'id=''>
    
      {saveSuccess || updateSuccess && showToastMessage()}
     
{edit?<div className=' col-lg-12 theader'>< h5>Quote Details</h5> </div> : <div className=' col-lg-12 theader'>< h5>.</h5> </div>}
<div className='buttonstop justify-content-center d-flex'><Button className='m-1' onClick={onClick} > Print</Button></div>

<div ref={wrapper_ref}className='col-lg-12 '>
<span className='justify-content-center d-flex bg-primary mb-4 mkwhite'><h4>Quotation</h4></span>
<span className='justify-content-left d-flex  mb-4 '>
<b>Quote No.:</b> {data[0].quoteid}<span className='ml-4'><b>Date:</b> {data[0].dateentered}</span>
</span>
 
  <div className='allcont mb-2 mb-5 '>
  <div className='row m-1 '>
<div className='col-lg-8'><h5>To:</h5>
{names}<br/>
{idno}<br/>
0{kra}<br/>
{dob}<br/>
</div>

<div className='col'><h5>From:</h5>
{names2}<br/>
{town}<br/>
0{mobile}<br/>
{email}<br/>
</div>
    </div>
  
    </div>
    <div className='row m-1  mb-2 dontwrap bg-primary mkwhite'>
<div className='col-lg-5'>Product</div>
<div className='col'>Qty</div>
<div className='col'>Price</div>
<div className='col'>Total</div>
    </div>
    {data.length>0 && data.map((x, i) => {
      return (
        <div className="box row  mr-2 ml-1 mb-1  dontwrap">
        <div className='col-lg-5'>
        
        <Label>{x.itemname}
        </Label>
        </div>
        <div className='col'>
        <Label>{x.quantity}
        </Label>   
      
</div>
         <div className='col'>
         <Label>KSH. {x.price}.00
        </Label>
       </div>
         <div className='col'>
         <Label>KSH. { x.price* x.quantity}.00
        </Label>
        </div>
       
      </div>
      );
    })}
     <div className='row m-1  mb-2 dontwrap '>
<div className='col-lg-5'></div>
<div className='col'></div>
<div className='col bg-primary mkwhite bradius'>Sub-Total</div>
<div className='col bg-primary mkwhite'>KSH. {grandtotal}.00</div>
    </div>
    <div className='row m-1  mb-2 dontwrap'>
<div className='col-lg-5'></div>
<div className='col'></div>
<div className='col bg-primary mkwhite'>Discount</div>
<div className='col bg-primary mkwhite'>KSH. {data[0].discount}.00</div>
    </div>
    <div className='row m-1  mb-2 dontwrap '>
<div className='col-lg-5'></div>
<div className='col'></div>
<div className='col bg-primary mkwhite'>Grand Total</div>
<div className='col bg-primary mkwhite'>KSH. {grandtotal-data[0].discount}.00</div>
    </div>
    <span className='justify-content-center d-flex  mb-4 mt-5'>{data[0].notes}</span>
    <span className='justify-content-center d-flex  mb-4'><i>System generated Quotation</i></span>
</div>

</div>


<div className='col-lg-1 centermodal'>
         <Modal className='modal1 modal-dialog-centered'>
         
         <Modal.Body className='modalbody pb-1 mb-1'>
          <label style={{color:'red','font-size':'18px'}}>Delete Payment?</label><br/>
         <label style={{'font-size':'15px'}}>Deleting a Payment Record is Irreversible. Would you like to Proceed?</label>
         <div className='deletebtns'> 
          <Button color="danger ml-2 "className="  pb-1 " type="">
Yes
</Button>
          <Button color="primary ml-2  "className="  pb-1 " type="">
No
</Button>
</div>
</Modal.Body>

     </Modal>
     </div>

  </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  isadmin:state.auth.isadmin,
  store:state.auth.store,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Newstaff))