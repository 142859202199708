import { Redirect } from 'react-router-dom';
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    LOGOUT,
    IS_ACTIVE,
    NOT_ACTIVE,
    INVOICENUMBER_SET,
    INVOICENUMBER_REMOVE,
    USER_SUCCESS,
    USER_FETCHED,
    PAYMENT_FAIL,
    PAYMENT_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    HOMERELOAD,
    HOMERELOADFALSE
} from '../actions/types'

const initialState={
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: localStorage.getItem("authenticated"),
    user:null,
    isActive: null,
    homereload:localStorage.getItem('homereload'),
    id:localStorage.getItem('id'),
    useremail:localStorage.getItem("useremail"),
    sales:localStorage.getItem("sales"),
    products:localStorage.getItem("products"),
    store:localStorage.getItem("store"),
    reports:localStorage.getItem("reports"),
    system:localStorage.getItem("system"),
    payroll:localStorage.getItem("payroll"),
    contacts1:localStorage.getItem("contacts"),
    users:localStorage.getItem("users"),
    purchases:localStorage.getItem("purchases"),
    expenses:localStorage.getItem("expenses"),
    user1:localStorage.getItem("user1"),
    username1:localStorage.getItem("username"),
    email1:localStorage.getItem("email"),
    isadmin:localStorage.getItem("isadmin"),
    isstaff:localStorage.getItem("isstaff"),
    isparent:localStorage.getItem("isparent"),
    isprimary:localStorage.getItem("isprimary"),
    name:localStorage.getItem('name'), 
    sitems:localStorage.getItem('sitems'), 
    pitems:localStorage.getItem('pitems'), 
    vitems:localStorage.getItem('vitems'),
    asreturn:localStorage.getItem('asreturn'), 
    apurchase:localStorage.getItem('apurchase'),
    vpurchase:localStorage.getItem('vpurchase'), 
    apreturn:localStorage.getItem('apreturn'), 
    home:localStorage.getItem('home'), 
    aitem:localStorage.getItem('aitem'), 
    vitem:localStorage.getItem('vitem'), 
    ditem:localStorage.getItem('ditem'),
    eitem:localStorage.getItem('eitem'),
    stocktake:localStorage.getItem('stocktake'),
    acategory:localStorage.getItem('acategory'),
    vcategory:localStorage.getItem('vcategory'),
    ecategory:localStorage.getItem('ecategory'),
    dcategory:localStorage.getItem('dcategory'),
    reconcile:localStorage.getItem('reconcile'),
    auom:localStorage.getItem('auom'),
    euom:localStorage.getItem('euom'),
    duom:localStorage.getItem('duom'),
    vuom:localStorage.getItem('vuom'),
    vsreport:localStorage.getItem('vsreport'),
    vpreport:localStorage.getItem('vpreport'),
    vprreport:localStorage.getItem('vprreport'),
    vmonitor:localStorage.getItem('vmonitor'),
    astore:localStorage.getItem('astore'),
    vstore:localStorage.getItem('vstore'),
    dstore:localStorage.getItem('dstore'),
    arole:localStorage.getItem('arole'),
    vrole:localStorage.getItem('vrole'),
    drole:localStorage.getItem('drole'),
    auser:localStorage.getItem('auser'),
    vuser:localStorage.getItem('vuser'),
    duser:localStorage.getItem('duser'),
    acustomer:localStorage.getItem('acustomer'),
    vcustomer:localStorage.getItem('vcustomer'),
    ecustomer:localStorage.getItem('ecustomer'),
    dcustomer:localStorage.getItem('dcustomer'),
    asupplier:localStorage.getItem('asupplier'),
    vsupplier:localStorage.getItem('vsupplier'),
    esupplier:localStorage.getItem('esupplier'),
    dsupplier:localStorage.getItem('dsupplier'),
    astaff:localStorage.getItem('astaff'),
    vstaff:localStorage.getItem('vstaff'),
    estaff:localStorage.getItem('estaff'),
    dstaff:localStorage.getItem('dstaff'),
    asallowance:localStorage.getItem('asallowance'),
    asdeduction:localStorage.getItem('asdeduction'),
    aallowance: localStorage.getItem('aallowance'),
    vallowance:localStorage.getItem('vallowance'),
    eallowance:localStorage.getItem('eallowance'),
    dallowance:localStorage.getItem('dallowance'),
    adeduction:localStorage.getItem('adeduction'),
    vdeduction:localStorage.getItem('vdeduction'),
    ededuction:localStorage.getItem('ededuction'),
    ddeduction:localStorage.getItem('ddeduction'),
    aoadvance:localStorage.getItem('aoadvance'),
    vquote:localStorage.getItem('vquote'),
    aquote:localStorage.getItem('aquote'),
    glabel:localStorage.getItem('glabel'),
    aaadvance:localStorage.getItem('aaadvance'),
    voadvance:localStorage.getItem('voadvance'),
    vaadvance:localStorage.getItem('vaadvance'),
    vop9:localStorage.getItem('vop9'),
    vap9:localStorage.getItem('vap9'),
    vsreturns:localStorage.getItem('vsreturns'),
    vapayroll:localStorage.getItem('vapayroll'),
    gpayroll:localStorage.getItem('gpayroll'),
    vopayroll:localStorage.getItem('vopayroll'),
    vapayroll:localStorage.getItem('vapayroll'),
    loginCheck:null,
    fetchedUser:localStorage.getItem("adminname"),
    payment:localStorage.getItem("payment"),
    resetpassword:null,
    clicked:null,
    preset:localStorage.getItem("preset"),
};
export default function(state= initialState,action){
    
    const {type,payload}= action;
    switch(type) {
        case AUTHENTICATED_SUCCESS:
            localStorage.removeItem('authenticated');
            localStorage.setItem('authenticated', 'true');  
            return{
                ...state,
                
            }
        case  LOGIN_SUCCESS:
            console.log("hello")
            localStorage.removeItem('authenticated');
            localStorage.setItem('authenticated', 'true');  
            
        return{
            ...state,
            isAuthenticated:"true",
            access:payload.access,
            refresh: payload.refresh,
            useremail:localStorage.getItem("useremail"),
            user1:localStorage.getItem("user1"),
            email1:localStorage.getItem("email"),
            username1:localStorage.getItem("username"),
            isadmin:localStorage.getItem("isadmin"),
            isprimary:localStorage.getItem("isprimary"),
            isstaff:localStorage.getItem("isstaff"),
            loginCheck:true
        }
       
    
        case  LOGIN_FAIL:
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        localStorage.removeItem('useremail');
        localStorage.removeItem('authenticated');
        localStorage.removeItem('adminname');
        localStorage.setItem('authenticated', "false"); 
        return{
          ...state,
          access:null,
          refresh:null,
          isAuthenticated:"false",
          user:null,
          useremail:null,
          loginCheck:false

        }
        case  LOGOUT:
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        localStorage.removeItem('useremail');
        localStorage.removeItem('authenticated');
        localStorage.removeItem('adminname');
        localStorage.setItem('authenticated', "false"); 
        return{
          ...state,
          access:null,
          refresh:null,
          isAuthenticated:"false",
          user:null,
          useremail:null,
          loginCheck:null

        }
        case AUTHENTICATED_FAIL:
            localStorage.removeItem('useremail');
            
            return{
                ...state,
                isAuthenticated:"false"
            }
            case HOMERELOAD:
              localStorage.setItem('homereload', 'true');
            
            return{
                ...state,
                homereload:'true'
            }
            case HOMERELOADFALSE:
              localStorage.setItem('homereload', 'false');
            
            return{
                ...state,
                homereload:'false'
            }
            case PASSWORD_RESET_SUCCESS:
              return{
                ...state,
                clicked:true,
                preset:true
            }
            case PASSWORD_RESET_FAIL:
              return{
                ...state,
                clicked:false
            }
            case PAYMENT_SUCCESS:
              localStorage.removeItem('payment');
              localStorage.setItem('payment', JSON.stringify(true));
            return{
                ...state,
                payment:"true"
            }
            case PAYMENT_FAIL:
              localStorage.removeItem('payment');
              localStorage.setItem('payment', JSON.stringify(false));
              return{
                  ...state,
                  payment:"false"
              }
        case  USER_SUCCESS:
        return{
          ...state,
         user:payload
        }
        case  USER_LOADED_FAIL:
        return{
          ...state,
         user:null
        }
        case  PASSWORD_RESET_CONFIRM_FAIL:
        return{
          ...state,
         resetpassword:false
        }
        case  PASSWORD_RESET_CONFIRM_SUCCESS:
          localStorage.removeItem('preset');
        return{
          ...state,
         resetpassword:true,
         
        }
        case  USER_FETCHED:
        return{
          ...state,
         fetchedUser:localStorage.getItem("adminname")
        }
        case  IS_ACTIVE:
            return{
              ...state,
             isActive:"active1"
            }
            case  INVOICENUMBER_SET:
                return{
                  ...state,
                 id:localStorage.getItem('id')
                
                }
                case  INVOICENUMBER_REMOVE:
                return{
                  ...state,
                 invoicenumber:null
                 
                }
        case  NOT_ACTIVE:
            return{
              ...state,
             isActive:null
            }
    
        default:
            return state;
    }
    
}