import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import { ToastContainer, toast } from 'react-toastify';
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import html2canvas from "html2canvas";
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";
 import Select1 from 'react-select'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import axios from "axios";
import useKeyboardShortcut from 'use-keyboard-shortcut'
import './Adminnav.css';
import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation,useParams,Link } from 'react-router-dom';
import { Alert, Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,faTrash} from '@fortawesome/free-solid-svg-icons'
const Payslip=({props,isAuthenticated,checkAuthenticated,load_user,removeInvoice,id,useremail,payment,username1,user1})=> {
 
  const [showhide,setShowhide]=useState(false);
  const [customerFetch,setCustomerFetch]=useState(false);
  const [amountvisible,setAmountVisible]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [showhide1,setShowhide1]=useState(false);
  const [showhide2,setShowhide2]=useState(false);
  const [names, setName] = useState("");
  const [filteredinvoice, setFilteredInvoice] = useState("");
  const [name, setNames] = useState("");
  const [names2, setNames2] = useState("");
  const [stname, setStname] = useState("");
  const [stadm, setStadm] = useState("");
  const [sttown, setSttown] = useState("");
  const [email,setEmail]=useState("");
  const [email1,setEmail1]=useState("");
  const [mobile,setMobile]=useState("");
  const [town,setTown]=useState("");
  const [id1,setId]=useState("");
  const [idno,setIdno]=useState("");
  const [idno1,setId1]=useState("");
  const [id2,setId2]=useState("");
  const [edit,setEdit]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [savebtn,setSavebtn]=useState(false);
  const [editbtn1,setEditbtn1]=useState(false);
  const [savebtn1,setSavebtn1]=useState(false);
  const [wait,setWait]=useState(false);
  const [wait1,setWait1]=useState(false);
  const [hide,setHide]=useState(true);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [dropopen,setDropOpen]=useState(false);
  const [dropdown,setDropDown]=useState("Unpaid");
  const [issuedate,setIssueDate]=useState("");
  const [duedate,setDueDate]=useState("");
  const [kra,SetKra]=useState("");
  const [price,setPrice]=useState("");
  const [customerid,setcustomerid]=useState("");
  const [dob,setDob]=useState("");
  const [search,setSearch]=useState("");
  const [searchcustomer,setSearchCustomer]=useState("");
  const [quantity,setQuantity]=useState("");
  const [index,setIndex]=useState("");
  const [basic,setBasic]=useState("");
  const [details,setDetails]=useState(['']);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [details2bkp,setDetails2bkp]=useState(['']);
  const [all,setAll]=useState('');
  const [ded,setDed]=useState('');
  const [gross,setGross]=useState('');
  const [net,SetNet]=useState('');
  const [month,setMonth]=useState('');
  const [payee,setPayee]=useState('');
  const [year,setYear]=useState('');
  const [details2,setDetails2]=useState(['']);
  const [details3,setDetails3]=useState(['']);
  const [products,setProducts]=useState(['']);
  const [irecords,setIRecords]=useState([]);
  const [grandtotal,setGrandTotal]=useState('');
  const [grandtotal1,setGrandTotal1]=useState('');
  const [totalPartialAmount,setTotalPartialAmount]=useState('');
  const [invoiceNumber,setInvoiceNumber]=useState('');
  const [musyoki,setMusyoki]=useState('musyoki');
  const [inputList1,setInputList1]=useState([]);
  const [heading,setHeading1]=useState(['Adm','Names', 'Action']);
  const [heading1,setHeading]=useState(['Fee item','Amount', 'Action']);
  const [inputList, setInputList] = useState([]);
  const [inputList2, setInputList2] = useState([]);
  const [payments, setPayments] = useState(['']);
  const [data2,setData2]=useState([""]);
  const inputRef = useRef(null);
  const [notFound,setNotFound]=useState(false);
  const wrapper_ref = React.useRef();
  const [feetypeError,setFTError]=useState(false);
  const [amountError,setAmountError]=useState(false);
  const [amount,setAmount]=useState("");
  const [Advance,setAdvance]=useState("");
  const [notInternet,setNoInternet]=useState(false);
  const [valueState, setValueState] = useState([]);
  const [date,setDate]=useState("");
  const [data,setData]=useState([""]);
  const [staff,setStaff]=useState([""]);
  const [deductions,setDeductions]=useState([""]);
  const [allowance,setAllowance]=useState([""]);
  const [data1,setData1]=useState([""]);
  const [loading,setLoading]=useState(false);
  const [amountrecord,setArecord]=useState([""]);
  const [deleteid,setDeleteId]=useState("");
  const [paybill,setPaybill]=useState("");
  const [dateError,setDateError]=useState(false);

  useHotkeys('ctrl+m', () => handleModalShowHide1());
  useHotkeys('ctrl+B', () => handleModalShowHide());
  useHotkeys('ctrl+i', () => handleSubmit());
  useHotkeys('ctrl+k', () => createSendEmail());

  const [responseData,setResponseData]=useState([""]);
  const tableRef = useRef(null); 
  const [options3, setOptions3] = useState( [
    { id: 1, country: "Cash" },
    { id: 2, country: "Mpesa" }, 
    { id: 3, country: "Bank" }, 
  ] )
  const toggle=()=>{
    setDropOpen(!dropopen);
  }
  const handleSelectChange = (value) => {
    setValueState(value);
    console.log(valueState)
    if(valueState.length>0){
      
    }
   
  }
  const renderList=()=>{
    return (options3.map(data =>({label:data.country})))
  }
  const showToastMessage = () => {

    toast.success('Record saved succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };
  const showToastMessage1 = () => {

    toast.success('Record deleted succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };

  const showToastMessage2 = () => {
  
    toast.error('select student,fee items and fill all invoice details', {
        position: toast.POSITION.TOP_CENTER
    });
  };    
  const getTotal=()=>{
  
    const res=inputList.reduce((prev,item)=>{
      
        return prev +(item.price1*item.quantity1);
    },0)
    setGrandTotal(res)
    console.log(res);
    }

    const getTotal1=()=>{
  
      const res=inputList1.reduce((prev,item)=>{
        
          return prev +(item.price*item.quantity);
      },0)
      setGrandTotal1(res)
      console.log(res);
      }
  const handledate = () => {
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return date;
  }
   // handle input change
   const handleInputChange = (e, index) => {
    if(edit){
    const { name, value } = e.target;
    const list = [...inputList1];
  
    list[index][name] = value;
    setInputList1(list);
    }else{
      const { name, value } = e.target;
    const list = [...inputList];
 
    list[index][name] = value;
    setInputList(list);
    }
  };
 
  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    const list1 = [...inputList1];
    list1.splice(index, 1);
    setInputList(list);
    setInputList1(list1);
    getTotal();
  };
 
  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { firstName: "", lastName: "" }]);
  };

  useEffect(()=>{

    //check authentication
    checkAuthenticated();
    fetchAllPayment()
    fetchPayment(useremail)

    //fetch school info
    let data ;

    axios({
      method:'post',
      url:'/totalcompany/',
      data:{useremail:useremail},
    })
    .then(res => {
        data = res.data;
        setData1(data1);
        console.log(res.data)
         //set fields
    setNames2(data[0].names)
    setEmail(data[0].email)
    setMobile(data[0].mobile)
    setTown(data[0].town)
   setPaybill(data[0].till)
   
    })
    .catch(err => {})

    // fetch allowances
    let data1 ;
    const id = /[^/]*$/.exec(window.location.href)[0];
   
    axios({
      method:'post',
      url:'/searchstaffs/',
      data:{useremail:useremail,id:id.split("-")[0]},
    })
    .then(res => {
        data1 = res.data;
        console.log(res.data)
        setName(data1[0].firstname)
        setNames(data1[0].lastname)
        setIdno(data1[0].idnumber)
        SetKra(data1[0].kra)
        setDob(data1[0].dob)
        setId1(data1[0].idnumber)
        setBasic(data1[0].salary)
        
    })
    .catch(err => {})
    
    axios({
      method:'post',
      url:'/searchpayroll/',
      data:{useremail:useremail,staff:id.split("-")[0],month:id.split("-")[1],year:id.split("-")[2]},
    })
    .then(res => {
        data1 = res.data;
        setAll(data1[0].allowance)
        setDed(data1[0].deductions)
        setGross(data1[0].gross)
        SetNet(data1[0].net)
        setMonth(data1[0].month)
        setYear(data1[0].year)
        setPayee(data1[0].payee)

    })
    .catch(err => {})
     
//check if edit is true
const invoicenumber = /[^/]*$/.exec(window.location.href)[0];

const result = invoicenumber.includes("-") && invoicenumber.length<40 ? true : false;

     //fetch edit records when editing
     if(result){
    
     //set edit
     setEdit(true);
     setEditbtn(true)
     setEditbtn1(true)
       //set local invoice
       setInvoiceNumber(invoicenumber)
       //fetch invoice records
    
    axios({
      method:'post',
      url:'/ordersearch/',
      data:{invoicenumber},
    })
    .then((Response)=>{
       //filter record
     const datareceived=Response.data;
     setIRecords(datareceived)
    console.log(datareceived)
    //set fields
    //setcustomerid(datareceived[0].customerid)
    //setId2(datareceived[0].id)
    //setDropDown(datareceived[0].status)
   // setIssueDate(datareceived[0].issuedate)
    //setDueDate(datareceived[0].duedate)
      })
      //fetch sales records
      
      axios({
        method:'post',
        url:'/salesearch/',
        data:{invoicenumber},
      })
      .then((Response)=>{
         //filter record
       const datareceived=Response.data
       setInputList1(datareceived)
      
       console.log(datareceived)
      
        })
      
      fetchPartial()
     }else{
      setSavebtn(true)
      setSavebtn1(true)
     }
      },[])
       //fetch partial payment records
       const fetchPartial=()=>{
        const invoicenumber = /[^/]*$/.exec(window.location.href)[0];
        axios({
         method:'post',
         url:'/paymentsearch/',
         data:{invoicenumber},
       })
       .then((Response)=>{
          //filter record
        const datareceived=Response.data
        setPayments(datareceived)
       console.log(datareceived)
         })  
       }
      //calculate total partial payments
      useEffect(()=>{

        if(payments.length!=0){
        //calculate total sales
       
        const res=payments.reduce((prev,item)=>{
               
         return prev +(item.amount);
       },0)
       setTotalPartialAmount(res)
        }
             },[payments]) 

             useEffect(()=>{
  // fetch allowances
  const id = /[^/]*$/.exec(window.location.href)[0];
  let data2 ;
 
       axios({
        method:'post',
        url:'/allowancesearch/',
        data:{useremail:useremail,staff:idno1},
      })
       .then(res => {
           data2 = res.data;
           setAllowance(data2);
           setDetails2bkp(data2);
       
       })
       .catch(err => {})
       axios({
        method:'post',
        url:'/deductionsearch/',
        data:{useremail:useremail,staff:idno1},
      })
       .then(res => {
           data2 = res.data;
           setDeductions(data2);
           setDetails2bkp(data2);
       
       })
       .catch(err => {})  
    
  axios({
   method:'post',
   url:'/advancesearch/',
   data:{owner:useremail,id:id.split("-")[0],month:id.split("-")[1],year:id.split("-")[2]},
 })
  .then(res => {
      data2 = res.data;
      setAdvance(res.data);
     
  })
  .catch(err => {}) 
                   },[idno1])         
      useEffect(()=>{
      getTotal1()
    },[inputList1]) 

    useEffect(()=>{
      for(let i=1;i<inputList2.length;i++){
       
       
      }
     
    },[inputList2]) 


    useEffect(()=>{
       //fetch customer records
      if(customerid){
      
     axios({
      method:'post',
      url:'/studentsearch1/',
      data:{id:customerid,owner:useremail},
    })
    .then((Response)=>{
     const datareceived=Response.data;
     console.log(datareceived)
    //set fields
    setStadm(datareceived[0].studentadm)
    setStname(datareceived[0].first_name +" "+datareceived[0].last_name )
    setMobile(datareceived[0].phone)
    setSttown(datareceived[0].town)
      })}
    },[customerid]) 
    useEffect(()=>{
      
       //fetch account details
       let data1 ;

       axios({
         method:'post',
         url:'/totalcompany/',
         data:{useremail:useremail},
       })
       .then(res => {
           data1 = res.data;
           setData2(data1);
           setNames2(data1[0].names)
         }
       )
    },[]) 

    useEffect(()=>{
      checkSelected()
    },[dropdown]) 
      useEffect(()=>{
        console.log(inputList1)
        inputList1.forEach(inputList1 => {
          // push each  info into a row
          setInputList([...inputList, { name1: inputList1.product, quantity1: inputList1.quantity,price1:inputList1.price,total:inputList1.price*inputList1.quantity }]);
        });
       
        console.log(inputList)
      },[inputList1]) 

      useEffect(()=>{
getTotal()
      },[inputList])  
      useEffect(()=>{
console.log(inputList)
      },[inputList]) 
  const handleSubmit = (e) => {
    
     axios({
        method:'post',
        url:'/api/feepayment/',
        data:{studentadm:stadm,invoice:invoiceNumber,feetype:valueState.label,amount,dateentered:date,owner:useremail,postedby:username1},
        })
        .then((Response)=>{
        if (Response.data){
          fetchAllPayment()
         setSaveSuccess(true)
         setWait(false)
         setSavebtn(true)
         closeModal1()
         setFTError(false)
         setAmountError(false)
         setDateError(false)
       //  removeErrors()
         showToastMessage()
        // setSuccess(true)
        // setValidated(false)
        // hidesuccess();
        clearFields()
               }
        })
        .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
         setShowhide2(true)
         setWait(false)
         setNoInternet(true)
               }
        
        })

          //update the invoice status
   
    axios({
      method:'put',
      url:`/api/order/${id2}/`,
      data:{status:'Paid'},
    }).then((Response)=>{
      
      //clear fields
     
    })
        
    }
  const clearFields=()=>{
    setDate("")
    setAmount("")
    setValueState([])
    console.log(inputList)
  }
  //delete method
  const handleDelete = (item) => {
    console.log(item);
    axios({
      method:'delete',
      url:`/api/product/${item.id}/`,
    }).then((Response)=>{
      
alert('Record deleted Succesfully')
      
    })
    
  };
  //add productn
  const addProduct=(data)=>{

   setProducts([...products,data]);
   //set fields
   if(!edit){
   setInputList([...inputList, { name1: data.feetitle, quantity1: 1,price1:data.amount,total:data.amount }]);
   }else{
  
   setInputList1([...inputList1, { product: data.feetitle, quantity: 1,price:data.amount,total:data.amount }]);
   
  }
     closeModal();
     getTotal();
}
const createSendEmail=async(e)=>{
  e.preventDefault()

  
   //generate invoice id
   const unique_id = uuid();
   const small_id ="INV-"+ unique_id.slice(0,8).toUpperCase();
 //save records
 if(!names|!email| inputList.length==0| !duedate|!issuedate){
  //alert("select customer,products and invoice details")
  showToastMessage2()
     }else
  if(edit){
    //update the invoice status
    setEditbtn1(false)
    setSavebtn1(false)
    setWait1(true)
    axios({
      method:'put',
      url:`/api/order/${id2}/`,
      data:{status:dropdown},
    }).then((Response)=>{
      
      //clear fields
     
    })
    
   
     //send the doc to backend


     axios({
      method:'post',
      url:'/upload/',
      data:{email:email1,phone:mobile,invoicenumber:invoiceNumber,issuedate:issuedate,duedate:duedate,status:dropdown,items:inputList},
    }).then((Response)=>{
      if (Response.data="success"){
        setUpdateSuccess(true)
        setWait1(false)
        setEditbtn1(true)
        
              }
    })
    .catch((error) => {
      if (error.code="ERR_BAD_RESPONSE"){
        setWait1(false)
        setEditbtn1(true)
        setNotFound(true)
        setShowhide2(true)
              }
      
    })
    
   // window.location.reload();
  
    fetchPartial()
  }
  else{
    setEditbtn1(false)
    setSavebtn1(false)
    setWait1(true)
     
    //insert partial pament


   //save the sales details
  
   let cartLen = inputList.length;

   for (let i = 0; i < cartLen; i++) {
      const invoicenumber1=small_id;
      const name1=inputList[i].name1;
      const quantity1=inputList[i].quantity1;
      const total=inputList[i].price1;
      const dateOrdered=handledate();
      
      const res= await axios.post('/api/sales/',{
    invoicenumber:invoicenumber1,product:name1,price:total,quantity:quantity1,owner:useremail,dateentered:dateOrdered
  }).then((Response)=>{
   //clear fields
   
  })}
   //save invoice
   
 axios({
   method:'post',
   url:'/api/order/',
   data:{invoicenumber:small_id,type:"invoice",issuedate,duedate,customerid:mobile,status:dropdown,owner:useremail},
 }).then((Response)=>{
  //clear fields

 })
 
 

 //send the doc to backend

 
 axios({
  method:'post',
  url:'/upload/',
  data:{email:useremail,email1:email1,town:town,names:email,phone:mobile,invoicenumber:small_id,issuedate:issuedate,duedate:duedate,status:dropdown,items:inputList},
}).then((Response)=>{
  if (Response.data="success"){
    setWait1(false)
    setSavebtn1(true)
   
          }
})
.catch((error) => {
  if (error.code="ERR_BAD_RESPONSE"){
    setWait1(false)
    setSavebtn1(true)
    setNotFound(true)
    setShowhide2(true)
    setNoInternet(true)
          }
  
})
if(!notInternet){
  clearFields()
}

}
//setEdit(false);
setId('');

   
      //window.location.reload();
}
//add product btn
const addProductBtn=()=>{
  if(!name|!price){
    alert("fill all the fields") 
  }else{
  setProducts([...products,{name,quantity,price}]);
  console.log(products)
  //clear fileds
  
   }
}
 
//add customer
const addCustomer=(data)=>{
 
  setEmail(data.first_name+" "+data.last_name)
  setEmail1(data.email)
  setName(data.studentadm);
    setMobile(data.phone);
    setCustomerFetch(true)
    setTown(data.town)
      closeModal1();
}

  const editItem = (detail) => {
    setId(detail.id);
    setEdit(true);
    setName(detail.names);
    setEmail(detail.email);
    setMobile( detail.mobile);
    setTown(detail.town);

  };
  const cleaFields=()=>{
    setName('');
    setEmail('');
    setMobile('');
    setTown('');
   
  }
  const handleModalShowHide=(id)=> {
    setDeleteId(id)
    setShowhide(!showhide);
    
}
const closeModal=()=> {
  setShowhide(false);
}
const handleModalShowHide1=()=> {
  setShowhide1(!showhide1);
  console.log("clicked");
}
const handleModalShowHide2=()=> {
  setShowhide2(!showhide2);
  console.log("clicked");
}
const closeModal1=()=> {
setShowhide1(false);
}
const closeModal2=()=> {
  setShowhide2(false);
  setNoInternet(false)
  }
const filterList = (event) => {
  var updatedList = detailsbkp;
  updatedList = updatedList.filter(function(list) {
    return (
      list.name.toLowerCase().search(search.toLowerCase()) !==
      -1
    );
  });
  setDetails(updatedList)
  
  };
  const filterListCustomer = (event) => {
    var updatedList = details2bkp;
    updatedList = updatedList.filter(function(list) {
      return (
        list.first_name.toLowerCase().search(searchcustomer.toLowerCase()) !==
        -1 |list.last_name.toLowerCase().search(searchcustomer.toLowerCase()) !==
        -1 | list.studentadm.toLowerCase().search(searchcustomer.toLowerCase()) !==
        -1
      );
    });
    setDetails2(updatedList)
    
    };
    if(isAuthenticated=="false"){
    }
    
    console.log(inputList)
    useEffect(()=>{
      checkAuthenticated();
      
        },[]) 
  if(isAuthenticated=="false"){
   return <Redirect to='/login'/>}
  
   const checkSelected=()=>{
     if (dropdown=="Partial Payment"){
setAmountVisible(!amountvisible)
console.log(dropdown)
     }
   }
   const reLoadPage=()=>{
       
    window.location.reload();
}
   const validateFields=(e)=>{
    
    e.preventDefault()
    if( amount.trim().length>0 && date.trim().length>0 && valueState.length!==0){
       
handleSubmit()
    }else{
        if(valueState.length==0){
            setFTError(true)
          
               
            }
        if(amount.trim().length==0){
          setAmountError(true)
         
          }
          if(date.trim().length==0){
            setDateError(true)
           
            }
    }}
    const getTotalPaid=()=>{
    
      const res=data.reduce((prev,item)=>{
             
        return prev +(item.amount);
      },0)
      return res;
    }
    const deleteData = async () => {
      if(useremail=="16")
      {
showToastMessage2();
      }
      else{
      handleModalShowHide()
     
         const result = await axios({
          method:'put',
          url:`/api/feepayment/${deleteid}/`,
          data:{isdeleted:"deleted"}
         })
         .then((Response)=>{
          
            //setSuccess(true)
            showToastMessage1()
            fetchAllPayment()
                  
         })
         .catch((error) => {
          if (error.code="ERR_BAD_RESPONSE"){
           
                  }
          
         })
        }
         }
         const Print = () =>{  
          html2canvas(inputRef.current).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF();
            pdf.addImage(imgData, "JPEG", 0, 0);
            pdf.save(stname+".pdf");
            var blob = pdf.output("blob");
            window.open(URL.createObjectURL(blob));
          });
        }
    const fetchAllPayment=async()=>{
      const invoicenum = /[^/]*$/.exec(window.location.href)[0];
   
        let data4 ;
  
       const fee= await axios({
         method:'post',
         url:'/totalfeepayment2/',
         data:{useremail:useremail,inv:invoicenum},
       })
       .then(res => {
           data4 = res.data;
           setData(data4);
           console.log(data4) 
           setDetailsbkp(data4);
           setLoading(false)
       })
       .catch(err => {})
     
  }
 const convertMonth=(value)=>{
if(value==1){
return 'Jan'
}else if(value==2){
  return 'Feb'
  }else if(value==3){
    return 'March'
    }else if(value==4){
      return 'April'
      }else if(value==5){
        return 'May'
        }else if(value==6){
          return 'June'
          }else if(value==7){
            return 'July'
            }else if(value==8){
              return 'Aug'
              }else if(value==9){
                return 'Sept'
                }else if(value==10){
                  return 'Oct'
                  }else if(value==11){
                    return 'Nov'
                    }else if(value==12){
                      return 'Dec'
                      }
  }
   if(payment=="false"){
    return <Redirect to='/payment'/>}
    const onClick=(e)=>{
      const opt = {
         scale: 4
     }
     const elem = wrapper_ref.current;
     html2canvas(elem, opt).then(canvas => {
         const iframe = document.createElement('iframe')
         iframe.name = 'printf'
         iframe.id = 'printf'
         iframe.height = 0;
         iframe.width = 0;
         document.body.appendChild(iframe)
 
         const imgUrl = canvas.toDataURL({
             format: 'pdf',
             quality: '5.0'
         })
 
         const style=`
            
             width:60vw;
             position:relative;
             left:0:
             top:0;
             
         `;
 
         const url = `<img style="${style}" src="${imgUrl}"/>`;
         var newWin = window.frames["printf"];
      
          newWin.document.write(`<body onload="window.print()">${url}</body>`);
        
         newWin.document.close();
 
     });
   }
  return (
    <div className='' >
      <ToastContainer
       hideProgressBar={true}
      />
<div className='path'><i className='fa fa-home'></i>/ Payroll / View Payslip</div>
<div className='  bodysection pb-1 col-lg-4'id='divToPrint2'>
      
        {saveSuccess || updateSuccess && showToastMessage()}
       
{edit?<div className=' col-lg-12 theader'>< h5>Payslip Details</h5> </div> : <div className=' col-lg-12 theader'>< h5>.</h5> </div>}
<div className='buttonstop justify-content-center d-flex'><Button className='m-1' onClick={onClick} > Print</Button></div>

<div ref={wrapper_ref}className='col-lg-12 '>
<span className='justify-content-center d-flex'>{names2}</span>
<div className='justify-content-center d-flex'style={{color:'blue'}}><i>{'Payslip ('+ convertMonth(month)+"-"+year+")"}</i></div>
<div className='justify-content-center d-flex'>{names +' '+ name}</div>
<div className='justify-content-flex-start d-flex'>ID No.:{idno} Tax Pin:{kra} {dob}</div>

   
    <div className=' col-lg-12 bg-dark mb-3'></div>
    <div className='allcont mb-2'>
    <div className='row m-1  '>
<div className='col-lg-5'>Basic salary</div>


<div className='col'></div>
<div className='col'></div>
<div className='col'>{basic}</div>
      </div>
      { allowance.map((x, i) => {
        return (
          <div className="box row  mr-2 ml-2 rowmgn dontwrap">
          <div className='col-lg-5'>
          
          <Label>{x.name}
          </Label>
          </div>
          <div className='col'>
          <Label>{}
          </Label>   
        
  </div>
           <div className='col'>
           <Label>{}
          </Label>
         </div>
           <div className='col'>
           <Label>{x.amount  && x.amount}
          </Label>
          </div>
         
        </div>
        );
      })}
 <div className='row m-1  dontwrap'>
<div className='col-lg-5'>Total Earnings</div>


<div className='col'></div>
<div className='col'></div>
<div className='col'>{gross}</div>
      </div>
      { deductions.map((x, i) => {
        return (
          <div className="box row  mr-2 ml-2 rowmgn dontwrap">
          <div className='col-lg-5'>
          
          <Label>{x.name}
          </Label>
          </div>
          <div className='col'>
          <Label>{}
          </Label>   
        
  </div>
           <div className='col'>
           <Label>{}
          </Label>
         </div>
           <div className='col'>
           <Label>{ x.amount  && '-'+x.amount}
          </Label>
          </div>
         
        </div>
        );
      })}
      {payee ?<div className='row m-1  mb-2 dontwrap'>
<div className='col-lg-5'>Payee</div>
<div className='col'></div>
<div className='col'></div>
<div className='col'>{'-'+payee}</div>
      </div>:"" }
      {!Advance=="0"&& <div className='row m-1 dontwrap mb-2'>
<div className='col-lg-5'>Advance</div>
<div className='col'></div>
<div className='col'></div>
<div className='col'>{!Advance=="0" &&'-'+ Advance}</div>
      </div> }
      <div className='row m-1 dontwrap mb-2'>
<div className='col-lg-5'>Total Deductions </div>
<div className='col'></div>
<div className='col'></div>
<div className='col'>{ded + payee}</div>
      </div>   
      <div className='row m-1 dontwrap mb-2'>
<div className='col-lg-5'>Net Pay </div>
<div className='col'></div>
<div className='col'></div>
<div className='col'>{net}</div>
      </div>  
      </div>

</div>

       <Modal show={showhide1}>
           <Modal.Header closeButton onClick={closeModal1}>
           <Modal.Title><h5>Add Payment</h5></Modal.Title>
           </Modal.Header>
           <Modal.Body>
           <Form >
 
 {amountvisible && <FormGroup className='mr-5 ml-3'>
 <Label>Amount</Label>
 <Input  type="text"style={{width:"200px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
 
 </FormGroup>}
 
 <FormGroup className='mr-2 ml-2 label2'>

 <Select1
        value={valueState}
        defaultValue=""
        placeholder="Payment method"
        options={renderList()}
        onChange={ (value) => handleSelectChange(value) }
        name="ColumnSelect"
        label=""
        styles={{
         control: (provided, state) => ({
           ...provided,
           
           borderColor: feetypeError? "red":"#D8D8D8"
         }),
         menu: (provided, state) => ({
           ...provided,
           
           
         }),
         option: (provided, state) => ({
            ...provided,
           
         })
       }}
 />
 
 
 <div className={feetypeError?'errmessage':'errmessagehide2'}>Payment method is Reguired</div>
 </FormGroup>
 <FormGroup className='mr-2 ml-2 label2 mt-1'>
 
 <Input className={amountError &&'errorborder'} type="number" placeholder="Amount"value={amount} onChange={e => setAmount(e.target.value)} name="email"  required/>
 <div className={amountError ?'errmessage':'errmessagehide2'}>Amount is Reguired</div>
 </FormGroup>
 <FormGroup className='mr-2 ml-2 label2 mt-1'>

 <Input className={amountError &&'errorborder'} type="date" placeholder="Amount"value={date} onChange={e => setDate(e.target.value)} name="email"  required/>
 <div className={dateError ?'errmessage':'errmessagehide2'}>Date is Reguired</div>
 </FormGroup>
  
       <div className='row ml-1'>
   <Button color="primary"className=" mb-3 ml-2" type="submit"onClick={(e)=> validateFields(e)}>
      
       {"Submit"}
       {wait && " Please wait.."}
     </Button>  
     <Button color="primary"className=" mb-3 ml-2" type="submit"onClick={(e)=> handleModalShowHide1(e)}>
      
      {"Close"}
      {wait && " Please wait.."}
    </Button>  
     </div>
     </Form>
</Modal.Body>

       </Modal>

      
</div>


<div className='col-lg-1 centermodal'>
           <Modal show={showhide} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Payment?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting a Payment Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={deleteData}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>

    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  user1:state.auth.user1,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Payslip))