import React,{useEffect,useState} from 'react'
import axios from "axios";
import { Table } from 'react-bootstrap';
import { Card,Modal} from 'react-bootstrap';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import {Redirect,Link} from 'react-router-dom';
import {connect} from 'react-redux';
import ToolkitProvider, { CSVExport,Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import TableHeaderColumn from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { ToastContainer, toast } from 'react-toastify';
import Select1 from 'react-select'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
function Issuedbook({isAuthenticated,checkAuthenticated,asreturn,useremail,username1,payment,fetchPayment,isadmin,store}) {
  const [data,setData]=useState([""]);
  const [data1,setData1]=useState([""]);
  const [names,setName]=useState("");
  const [email,setEmail]=useState("");
  const [mobile,setMobile]=useState("");
  const [town,setTown]=useState("");
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [id,setId]=useState("");
  const [edit,setEdit]=useState(false);
  const [showhide,setShowhide]=useState(false);
  const [heading,setHeading]=useState(['Names', 'Email', 'Mobile','Town','Action']);
  const [deleteid,setDeleteId]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const [showhide2,setShowhide2]=useState(false);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [detailsbkp1,setDetailsbkp1]=useState(['']);
  const [valueState, setValueState] = useState('');
  const [valueState1, setValueState1] = useState('');
  const [dateposted,setDateposted]=useState(""); 
  const [databkp,setDatabkp]=useState([""]);
  const [branch2,setBranch2]=useState([]);
  const [branch,setBranch]=useState([]);
  const [branchError,setBranchError]=useState(false);
  const { ExportCSVButton } = CSVExport;
   const { SearchBar } = Search;
   const options = {
     page: 1,
     sizePerPage: 10,
     nextPageText: '>',
     prePageText: '<',
     showTotal: true
   };
   const closeModal1=()=> {
    setShowhide1(false);
    
    }
    const closeModal2=()=> {
      setShowhide2(false);
    }
    const showToastMessage5 = () => {

      toast.error('Operation not allowed on admin account', {
          position: toast.POSITION.BOTTOM_CENTER
      });
    }; 
    const renderList=()=>{
      return (detailsbkp.map(data =>({label:data.id+',' + data.itemname })))
    }
    const renderList1=()=>{
      return (detailsbkp1.map(data =>({label:data.purchaseid })))
    }
    const handleSelectChange = (value) => {
    
      setValueState(value);
     
      if(value.length>0){
        console.log(value[0].label.split(',').shift())
      }
     
    }
    const handleSelectChange2 = (value) => {
    
      setValueState(value);
      
     if(value.length>0){
      var updatedList = databkp;
      updatedList = updatedList.filter(detail =>(detail.store && detail.store.includes(value[0].label.split(' ')[0]) ))
      setData(updatedList)}
    }
    
    const renderList2=()=>{
      return (data1.map(data =>({label:data.id+' ' + data.name })))
    }
    const handleSelectChange1 = (value) => {
    
      setValueState1(value);
      
      if(value.length>0){
        console.log(value[0].label.split(',').shift())
      }
     
    }
   const columns = [{
     dataField: 'item',
     text: 'Item',
     headerStyle: () => {
       return { width: "150px" };
     }
   }, {
     dataField: 'quantity',
     text: 'Quantity',
     headerStyle: () => {
       return { width: "100px" };
     }
   } 
   , {
    dataField: 'date',
    text: 'Date',
    headerStyle: () => {
      return { width: "100px" };
    }
  }
 ,
 {
  dataField: 'reason',
  text: 'Reason',
  headerStyle: () => {
    return { width: "180px" };
  }
}
];
  useEffect(()=>{
    let data1 ;

    axios({
      method:'post',
      url:'/totalreturns1/',
      data:{useremail:useremail,isadmin,store},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
        setDatabkp(data1);
      console.log(data1)
    })
    .catch(err => {})
    checkAuthenticated();
    fetchPayment(useremail)
    
      if(!payment){
        return <Redirect to='/payment'/>}
        fetchProducts()
        fetchAllSales()
        let data7
        axios({
          method:'post',
          url:'/totalstore/',
          data:{useremail:useremail},
         })
         .then(res => {
            data7 = res.data;
            //setCategory(data5)
            setData1(data7)
         })
         .catch(err => {})
         setTimeout(() => {
      
          if(isadmin=="true"){
            handleModalShowHide2()
          }
        
      }, 3000)
      },[]) 
      const handleModalShowHide2=()=> {
        setSaveSuccess(false)
          setShowhide2(true);
      }  
      const handleModalShowHide=()=> {
        setShowhide(!showhide);
    }
    const closeModal=()=> {
      setShowhide(false);
  }
  const fetchAllSales=()=>{
      
    let data4 ;
    axios({
     method:'post',
     url:'/totalpurchases/',
     data:{useremail:useremail,isadmin,store},
   })
   .then(res => {
       data4 = res.data;
       setDetailsbkp1(res.data);
      console.log(res.data)
   })
   .catch(err => {})}

  const fetchProducts=()=>{
    let data6 ;
    axios({
     method:'post',
     url:'/totalitem/',
     data:{useremail:useremail,isadmin,store},
   })
   .then(res => {
       data6 = res.data;
       console.log(res.data)
     setDetailsbkp(data6)
   })
   .catch(err => {})
   }
  const refreshData=()=>{
    let data1 ;

    
    axios({
      method:'post',
      url:'/totalreturns1/',
      data:{useremail:useremail,isadmin,store},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
       
    })
    .catch(err => {})
    
  }
  const handledate = () => {
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return date;
  }
  const handleSubmit = (e) => {
   
    if(!email|!mobile|!town){
     
       }
      else
    if(id){
      e.preventDefault();
    
      axios({
        method:'put',
        url:`/api/customer/${id}/`,
        data:{itemid:valueState[0].label.split(',').shift(),serial:valueState1[0].label,quantity:email,dateentered:mobile,reason:town,useremail,rtype:"sales",store},
      }).then((Response)=>{
        setEdit(false);
        setId('');
        //clear fields
    cleaFields();
      })
      setUpdateSuccess(true)
    }
    else{
      e.preventDefault();
      if(branch2.length==0 && isadmin=="true"){
        closeModal()
        handleModalShowHide2()
          }else{
   axios({
     method:'post',
     url:'/api/returns/',
     data:{itemid:valueState && valueState.label.split(',').shift(),serial:valueState1.label,quantity:email,dateentered:mobile,reason:town,owner:useremail,rtype:"purchases",store:isadmin=="true"?branch2.split(' ')[0]:store},
    }).then((Response)=>{
    //clear fields
    cleaFields();
   })
   
   setSaveSuccess(true)
  }
  axios({
    method:'post',
    url:'/api/audit/',
    data:{username:username1,operation:'Purchasesreturns inserted',table:'Returns',owner:useremail},
  })
  .then(res => {
     
  
  })
  .catch(err => {})
}
  };
  const editItem = (detail) => {
    setId(detail.id);
    setEdit(true);
    setShowhide(true);
    setName(detail.name);
    setEmail(detail.email);
    setMobile(detail.mobile);
    setTown(detail.town);

  };
  const showToastMessage = () => {
    toast.success('Record deleted succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };  
  const handleDelete = (item) => {

    console.log(item);
    axios({
      method:'delete',
      url:`/api/customer/${deleteid}/`,
    }).then((Response)=>{
      handleModalShowHide1()
      showToastMessage()
      refreshData();
    })
  
  };
  const cleaFields=()=>{
    setName('');
    setEmail('');
    setMobile('');
    setTown('');
    refreshData();
  }
  useEffect(()=>{
    checkAuthenticated();
    
      },[]) 
      useEffect(()=>{
     
      },[isAuthenticated]) 
      const handleCategoryChange1 = (event) => {
        setBranch2(event.target.value) 
        closeModal2()
       // filterItems()
      } 
      const handleModalShowHide1=(id)=> {
        setDeleteId(id)
        setShowhide1(!showhide1);
        console.log("clicked");
      }
        if(payment=="false"){
          return <Redirect to='/payment'/>}
  return (
    <div>
      <div className='path'><i className='fa fa-home'></i>{edit? '/ Products / Edit Item':'/ Sales / P. returns'}</div>
        <ToastContainer
       hideProgressBar={true}
      />
        <div className='bodysection col-lg-6'>
        <div className=' col-lg-12 theader'>< h5>Purchases returns List</h5> </div>
        {asreturn=="true" | isadmin=="true"&&<Button  onClick={handleModalShowHide} className="m-2 btn-primary">
           + Return
       </Button>}
       {isadmin=="truemm" && <div style={{width:'220px'}}className='my-2 ml-2' >
<Select1
       value={valueState}
       defaultValue=""
       placeholder="Filter by Branch"
       options={renderList2()}
       onChange={ (value) => handleSelectChange2(value) }
       name="ColumnSelect"
       label=""
       isClearable
/>
</div>}
       <Modal show={showhide}>
           <Modal.Header closeButton onClick={closeModal}>
           <Modal.Title>New Purchases Return details</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
<FormGroup>
<Label>Item Name</Label>
<Select1
       value={valueState}
       defaultValue=""
       placeholder="Select Item"
       options={renderList()}
       onChange={ (value) => handleSelectChange(value) }
       name="ColumnSelect"
       label=""
       isClearable
/></FormGroup>
<FormGroup>
<Label>Purchase Serial</Label>
<Select1
       value={valueState1}
       defaultValue=""
       placeholder="Select Serial"
       options={renderList1()}
       onChange={ (value) => handleSelectChange1(value) }
       name="ColumnSelect"
       label=""
       isClearable
/></FormGroup>
<FormGroup>
<Label>Quantity</Label>
<Input type="number" placeholder="Enter qty"value={email} onChange={e => setEmail(e.target.value)} name="email"  required/>

</FormGroup>
<FormGroup>
<Label>Date</Label>
<Input type="date" placeholder="Enter mobile"name="author" value={mobile} onChange={e => setMobile(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Reason</Label>
<Input type="textarea" placeholder="Reason for returning"name="town" value={town} onChange={e => setTown(e.target.value)} required />
</FormGroup>
<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       
       required
     
     />
</FormGroup>
   
           <Button color="primary"className="mt-2 " type="submit" onClick={(e)=> handleSubmit(e)}>
Submit
</Button>  
<Button color="primary ml-2"className=" m-2 mt-3" type=""onClick={closeModal}>
Close
</Button>
</Form>

</Modal.Body>

       </Modal>
       <ToolkitProvider
keyField="id"
data={[...data] }
columns={ columns }
exportCSV={{ onlyExportFiltered: true, exportAll: false }}
search
>
{
props => (
<div>
<ExportCSVButton { ...props.csvProps } className='btn btn-primary m-1'>Export </ExportCSVButton> 

<SearchBar { ...props.searchProps } />

<BootstrapTable keyField='id'
{...props.baseProps}
data={ data } 
columns={ columns } 
pagination={ paginationFactory(options) }className="pgnav"
wrapperClasses="table-responsive">  

</BootstrapTable>
</div> 
)
}
</ToolkitProvider>

           </div>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Customer?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting a Customer Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={handleDelete}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
       <div className='col-lg-2'>
       <Modal show={showhide2}className='col-lg-4 d-flex ml-auto mr-auto'>
           <Modal.Header closeButton onClick={closeModal2}>
           <Modal.Title>Select Store</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
        
  { <FormGroup className='mr-2 ml-2 '>

<div style={{width:'220px'}}className={branchError &&'errorborder'} >
              
          <select onChange={handleCategoryChange1} className={branchError ? 'errorborder newselect1':'newselect'}>
 
 <option>{'Select Branch Name'}</option>
 { data1.map((option, index) => {
     return <option key={index} >
         {option.id + ' '+ option.name}
     </option>
 })}
</select>    
          
            </div>
<div className={branchError ?'errmessage1':'errmessagehide1'}>Branch is Reguired</div>
</FormGroup>}

<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       value={dateposted}
       required
     
     />
</FormGroup>
   
</Form>

</Modal.Body>

       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  isadmin:state.auth.isadmin,
  store:state.auth.store,
  asreturn:state.auth.asreturn,
  username1:state.auth.username1,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,fetchPayment})(Issuedbook)