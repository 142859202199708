import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import { ToastContainer, toast } from 'react-toastify';
 import './Newstaff.css'
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import  { encrypt , decrypt } from 'react-crypt-gsm';
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";
 import Select1 from 'react-select'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
//import bcrypt from "bcrypt";
import axios from "axios";
import Select from 'react-dropdown-select'
import useKeyboardShortcut from 'use-keyboard-shortcut'
import bcrypt from 'bcryptjs'
import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation} from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import './Addstudent.css'

const Addpurchase=({props,isAuthenticated,checkAuthenticated,load_user,isadmin,store,removeInvoice,id,useremail,payment,match,username1})=> {
 
  const [itemname, setItem]=useState();
  const [itemError,setIError]=useState(false);
  const [quantity, setQunatity]= useState();
  const [categoryValue, setCategoryValue]= useState('');
  const [unitValue, setUnitValue]= useState('');
  const [supplier,SetSupplier]=useState('')
  const [supplierError, setSupplierError]=useState(false)
  const [datebuyed,setDatebuyed]=useState('')
  const [dateEntered, setDateEntered]=useState('')
  const [dateError, setDateError]=useState(false)
  const [quantityError,setQError]=useState(false);
  const [sellingprice, setSPrice]=useState();
  const [sellingPError,setSPError]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [edit,setEdit]=useState(false);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [notInternet,setNoInternet]=useState(false);
  const [buyingprice,setBPrice]=useState();
  const [buyingPError,setBPError]=useState(false);
  const [savebtn,setSavebtn]=useState(true)
  const [wait,setWait]=useState(false)
  const [itemNError,setINError]=useState(false)
  const [serial,setSerial]=useState();
  const [validated,setValidated]=useState(false);
  const [valueState2, setValueState2] = useState('');
  const [tendered,setTendered]=useState(0)
  const [grandtotal,setGrandTotal]=useState('');
  const [classstream,setCsream]=useState("");
  const [inputList1,setInputList1]=useState([]);
  const [branch2,setBranch2]=useState([]);
  const [branch,setBranch]=useState([]);
  const [branchError,setBranchError]=useState(false);
  const [dateposted,setDateposted]=useState("");
  const [data,setData]=useState([""]);
  const [grandtotal1,setGrandTotal1]=useState('');
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [success,setSuccess]=useState(false);
  const [result,setResult]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [amountvisible,setAmountVisible]=useState(false);
  const [showhide2,setShowhide2]=useState(false);
  const [category, setCategory] = useState( [] );
const [categoryError,setCategoryError]=useState(false);
const [isFirstLoad, setIsFirstLoad] = useState(true)
const [isVisible, setVisible] = useState(false)
  const [serialError,setSerialError]=useState(false)
  const [valueState, setValueState] = useState('');
  const [unit,setUnit]=useState([
    { id: 1, country: "Male" },
    { id: 2, country: "Female" }, 
  ])
  //label size 4x1 20 labels in 8.5 x 11
  const [unitError,setunitError]=useState(false)
  //const bcrypt = require('bcrypt');
  const renderList=()=>{
    return (data.map(data =>({label:data.mobile+',' + data.name })))
  }
  const handleSelectChange = (value) => {
    
    setValueState(value);
    
    if(value.length>0){
      console.log(value[0].label.split(',').shift())
    }
   
  }
  const showToastMessage = () => {
    toast.success('Record saved succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };  
  const showToastMessage5 = () => {

    toast.error('Operation not allowed on admin account', {
        position: toast.POSITION.BOTTOM_CENTER
    });
  }; 
  const showToastMessage1 = () => {
    toast.success('Record updated succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };   
  const showToastMessage2 = () => {

    toast.error('You dont have permission to perform this action', {
        position: toast.POSITION.TOP_CENTER
    });
  }; 
  const removeErrors=()=>{
    setIError(false)
    setBPError(false)
    setCategoryError(false)
    setunitError(false)
    
  }
  const validateFields=(e)=>{
    e.preventDefault()
    console.log(inputList1)
    if(valueState && datebuyed){
      setValidated(true)
      saveData()
    }
    
    else{
      if(!valueState){
        setSupplierError(true)
       
        }
        if(!datebuyed){
          setDateError(true)
         
          }   
                
    }
  }
  function hidesuccess() {
    return new Promise(resolve => {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    });
  }
  const getTotalPaid=()=>{
    
    const res=inputList1.reduce((prev,item)=>{
           
      return prev +(item.amount);
    },0)
    return res;
  }
  const clearFields=()=>{
    setItem('')
    setQunatity('')
    setBPrice('')
    setSPrice('')
   
  }
  const handleCategoryChange = (event) => {
    setCategoryValue(event.target.value) 
  } 
  const handleUnitChange = (event) => {
    setUnitValue(event.target.value) 
  } 
  useEffect(()=>{
//check if edit is true
const itemnumber = /[^/]*$/.exec(window.location.href)[0];
 if(match.params.id>=0)
 {
  setResult(true)
  setSavebtn(false)
 }
     //fetch edit records when editing
     if(result){
     //fetch Item records
     axios({
      method:'post',
      url:'/itemsearch/',
      data:{item:id},
    })
    .then((Response)=>{
     const datareceived=Response.data;
     console.log(datareceived)
    //set fields
    setItem(datareceived[0].itemname);
    setQunatity(datareceived[0].quantity);
    setUnit(datareceived[0].unit);
    setCategory(datareceived[0].category);
    
      })
     //set edit
     setEdit(true);
     setEditbtn(true)
    
       //set local invoice
      // setInvoiceNumber(invoicenumber)
      
     }else{
      
      
     }
//fetch student
let data5 ;
axios({
 method:'post',
 url:'/totalitem/',
 data:{useremail:useremail,isadmin,store},
})
.then(res => {
   data5 = res.data;
   setCategory(data5)
   setUnit(data5)
})
.catch(err => {})

if (isFirstLoad) {
  //window.location.reload();
  setIsFirstLoad(false)
} else {
  
}
/*  const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }*/
    axios({
      method:'post',
      url:'/totalsupplier/',
      data:{useremail:useremail},
    })
    .then(res => {
         
        setData(res.data)
      
    })
    .catch(err => {}) 
    setTimeout(() => {
      
      if(isadmin=="true"){
        handleModalShowHide2()
      }
    
  }, 3000)
  axios({
    method:'post',
    url:'/totalstore/',
    data:{useremail:useremail},
   })
   .then(res => {
     
      setBranch(res.data)
      //setUnit(data5)
   })
   .catch(err => {})
  },[])
  useEffect(() => {
    
}, [ isVisible ]);
  useEffect(()=>{
if(result){
 //fetch student records
 axios({
  method:'post',
  url:'/itemsearch/',
  data:{id:match.params.id},
})
.then((Response)=>{
 const datareceived=Response.data;
 console.log(datareceived)
//set fields
setItem(datareceived[0].itemname);
    setQunatity(datareceived[0].quantity);
    setUnit(datareceived[0].unit);
    setCategory(datareceived[0].category);
   
  })
 //set edit
 setEdit(true);
 setEditbtn(true)


}

  },[result])
  useEffect(()=>{
    //save 
if(validated){

//save staff
if(useremail=="")
{
showToastMessage2();
}
else
if(edit)
{

const editData = async () => {

const result = await axios({
method:'put',
url:`/api/item/${match.params.id}/`,
data:{itemname:itemname.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),category,unit,quantity,owner:useremail,editedby:username1},
})
.then((Response)=>{
if (Response.data){
//setSaveSuccess(true)
setWait(false)
removeErrors()
showToastMessage1()
//setSuccess(true)
setValidated(false)
//hidesuccess();

     }
})
.catch((error) => {
if (error.code="ERR_BAD_RESPONSE"){

setWait(false)
setNoInternet(true)
     }

})

}
editData()
}else
{


}

}
    
      },[validated]) 
      const handleModalShowHide2=()=> {
        //setSaveSuccess(false)
          setShowhide2(true);
      }  
      const saveData = async () => {
        if(branch2.length==0 && isadmin=="true"){
          handleModalShowHide2()
            }else{
        let cartLen = inputList1.length;
      //generate purchase id
      const unique_id = uuid();
      const small_id ="PID-"+ unique_id.slice(0,6).toUpperCase();
        for (let i = 0; i < cartLen; i++) {
           const invoicenumber1=small_id;
           const name1=inputList1[i].name1;
           const quantity1=inputList1[i].quantity1;
      
           const total=inputList1[i].price1;
      
      const id = name1.split(',')[0]
      
      const result = await axios({
      method:'post',
      url:'/api/purchases/',
      data:{itemname:name1.split(',')[1],purchaseid:small_id,supplier:valueState.label.split(",")[1],quantity:quantity1,price:total,owner:useremail,editedby:username1,dateentered:datebuyed,store:isadmin=="true"?branch2.split(' ')[0]:store,paid:tendered>= getTotal()?getTotal():tendered},
      })
      .then((Response)=>{
      if (Response.data){
       setSaveSuccess(true)
       setWait(false)
       setSavebtn(true)
       removeErrors()
       if(i==cartLen-1){
        showToastMessage()
        //clear fields
        setDatebuyed('')
        SetSupplier('')
        setDateError(false)
        setSupplierError(false)
        setInputList1([])
       }
       
       //setSuccess(true)
       setValidated(false)
       //hidesuccess();
      clearFields()
             }
      })
      .catch((error) => {
      if (error.code="ERR_BAD_RESPONSE"){
      
       setWait(false)
       setNoInternet(true)
             }
      
      })
      axios({
        method:'post',
        url:'/api/audit/',
        data:{username:username1,operation:'Purchase inserted',table:'Purchases',owner:useremail},
      })
      .then(res => {
         
      
      })
      .catch(err => {})
      //add the quantity to existing value
      const result1 = await axios({
        method:'post',
        url:'/editquantity/',
        data:{quantity:quantity1,id,owner:useremail},
        })
        .then((Response)=>{
        if (Response.data){
        
               }
        })
        .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
        
               }
        
        })}
      
      }}
const renderCategory=()=>{
return (category.map(data =>({label:data.bookid})))
  }
   if(payment=="false"){
    return <Redirect to='/payment'/>}
    const handleInputChange = (e, index) => {
      if(edit){
      const { name, value } = e.target;
      const list = [...inputList1];
    
      list[index][name] = value;
      setInputList1(list);
      }else{
        const { name, value } = e.target;
      const list = [...inputList1];
   
      list[index][name] = value;
      setInputList1(list);
      }
    };
    const getTotal=()=>{
  
      const res=inputList1.reduce((prev,item)=>{
        
          return prev +(item.price1*item.quantity1);
      },0)
      
      console.log(res);
      return res
      }
  const addRow=()=>{
    setInputList1([...inputList1,{ name1: "", quantity1: 1,price1:'',total:'' }])
  }
  const handleRemoveClick = (index,id )=> {
    const list = [...inputList1];
    list.splice(index, 1);
    setInputList1(list);
   
    getTotal();}
    const closeModal2=()=> {
      setShowhide2(false);
    }
    const handleCategoryChange1 = (event) => {
      setBranch2(event.target.value) 
      closeModal2()
     // filterItems()
    } 
    const renderList2=()=>{
      return (category.map(data =>({label:data.itemcode+',' + data.itemname })))
    }
    const handleInputChange1 = (e, index) => {
 
      setValueState2(e.label.split(',')[1])
       if(edit){
      // const { name, value } = e.target;
       const name ="name1"
       const value=50
       const list = [...inputList1];
     
       list[index][name] = value;
       setInputList1(list);
       }else{
         //const { name, value } = e.target;
         const name ="name1"
         const value=50
       const list = [...inputList1];
       list[index][name] = e.label;
       setInputList1(list);
       console.log(inputList1)
       }
     };
  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record {edit?"Updated":"Saved"} succesffully</div></div>
       <div className='path'><i className='fa fa-home'></i>{edit? '/ Products / Edit Purchase':'/ Purchases / Add Purchase'}</div>
        <div className='bodysection col-lg-5  '>
        {saveSuccess || updateSuccess &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>operation succesfull...</span>}
       
<div className=' col-lg-12 theader'>< h5>Item Details</h5> </div>
<Form >
  <div className='row pl-4'>
 
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
</FormGroup>}
<FormGroup className='mr-2 ml-2'>
<Select1
       value={valueState}
       defaultValue=""
       placeholder="Select Supplier"
       options={renderList()}
       onChange={ (value) => handleSelectChange(value) }
       name="ColumnSelect"
       label=""
       isClearable
/><div className={supplierError ?'errmessage1':'errmessagehide1'}>Supplier Name is Reguired</div>
</FormGroup>
<FormGroup className=''>
<Label></Label>


</FormGroup>

 {amountvisible && <FormGroup className='mr-5 ml-3'>
 <Label>Amount</Label>
 <Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
 </FormGroup>}
 
 <FormGroup className='mr-2 ml-2 newwidth'>
 <Input className={dateError &&'errorborder '} type="date" placeholder="Class"value={datebuyed} onChange={e => setDatebuyed(e.target.value)} name="email"  required/>
 <div className={dateError ?'errmessage':'errmessagehide'}>Date is Reguired</div>
 </FormGroup>

   </div>
  <div className='col-lg-6 ml-2 studenthealth' >
 
</div>
<Button onClick={(e)=> addRow(e)} className='ml-2 mb-1' >+ Product</Button>
<Table responsive>
<div responsive className='allcont'>
    <div className='row m-1 rowbd pl-2'>
<div className='col'>Item</div>
<div className='col'>Qty</div>
<div className='col'>B.Price</div>
<div className='col'>Sub-Total</div>
<div className=''>{inputList1.length>0 &&<i 
                className="fa fa-minus  mr10 mt-1 btn1  mt-1 "
                ></i>}</div>
      </div>
      {!edit && inputList1.map((x, i) => {
        return (
          <div className="box row  mr-2 ml-2 rowmgn dontwrap">
            <div className='col-lg-3'>
            
            <FormGroup className='mr-2  '>
<div style={{width:'120px'}}className={categoryError &&'errorborder'}>
        
<Select1
       value={inputList1.item1}
       defaultValue=""
       placeholder="Select"
       options={renderList2()}
       onChange={e =>{ handleInputChange1(e, i)}}   
       name="name1"
       label=""
     
/>  
          
            </div>

</FormGroup>
            </div>
            <div className='col minqty'>
            <Input
            type="number"
              className="ml10 "
              name="quantity1"
   placeholder="Enter Quantity"
              value={x.quantity1}
              onChange={e =>{ handleInputChange(e, i);getTotal()}}
              
            /> 
          
    </div>

    <div className='col minqty'>
            <Input
            type="number"
              className="ml10 "
              name="price1"
   placeholder="B. price"
              value={x.price1}
              onChange={e =>{ handleInputChange(e, i);getTotal()}}
              
            /> 
          
    </div>
            
             <div className='col'>
             <Label>{x.price1 && x.quantity1 && x.price1*x.quantity1}
            </Label>
            </div>
            <div className=' '>
           
              { inputList1.length > 0 && <i 
                className="fa fa-trash  mr10 mt-1 btn1  mt-1 "
                onClick={() => handleRemoveClick(i,x.id)}></i>}
              
            </div>
          </div>
        );
      })}
 <div className='row m-1 rowbd1 mb-2'>
<div className='col-lg-3'>Total </div>
<div className='col'></div>
<div className='col'></div>
<div className='col'>{getTotal()}</div>

<div className=' '>
           
           { inputList1.length > 0 && <i 
             className="  mr10 mt-1 btn1  mt-1 "
             ></i>}
           
         </div>
      </div><div className='col-lg-5'>
      <FormGroup className='mr-2 ml-2 label2 mt-2'>
<Label>Amount Paying</Label>
<Input className='' type="number" placeholder="Tendered amount"value={tendered} onChange={e => setTendered(e.target.value)} name="email"  required/>

</FormGroup>
<FormGroup className='mt-1 mr-2 ml-2 label2 mt-2'>
<Label>Change</Label>
<Input className='' type="text" placeholder="Change"value={tendered>getTotal()?tendered-getTotal():'0'} onChange={e => setCsream(e.target.value)} name="email"  required/>

</FormGroup>
<FormGroup className='mt-1 mr-2 ml-2 label2 mt-2'>
<Label>Balance</Label>
<Input className='' type="text" placeholder="Balance"value={tendered=="" | tendered>getTotal()?'0':getTotal()-tendered} onChange={e => setCsream(e.target.value)} name="email"  required/>

</FormGroup>
</div>
      </div>
      </Table>
      <div className='row pl-4'>
  <Button color="primary"className=" ml-2 mb-3" type="submit"onClick={(e)=> validateFields(e)}>
      {editbtn && "Update"}
      {savebtn && "Submit"}
      {wait && " Please wait.."}
    </Button>  
    
    </div>
    </Form>
       
</div>
<div className='col-lg-2'>
       <Modal show={showhide2}className='col-lg-4 d-flex ml-auto mr-auto'>
           <Modal.Header closeButton onClick={closeModal2}>
           <Modal.Title>Select Store</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
        
  { <FormGroup className='mr-2 ml-2 '>

<div style={{width:'220px'}}className={branchError &&'errorborder'} >
              
          <select onChange={handleCategoryChange1} className={branchError ? 'errorborder newselect1':'newselect'}>
 
 <option>{'Select Branch Name'}</option>
 { branch.map((option, index) => {
     return <option key={index} >
         {option.id + ' '+ option.name}
     </option>
 })}
</select>    
          
            </div>
<div className={branchError ?'errmessage1':'errmessagehide1'}>Branch is Reguired</div>
</FormGroup>}

<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       value={dateposted}
       required
     
     />
</FormGroup>
   
</Form>

</Modal.Body>

       </Modal>
       </div>
    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
 store:state.auth.store,
  username1:state.auth.username1,
  isadmin:state.auth.isadmin,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Addpurchase))