import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import { ToastContainer, toast } from 'react-toastify';
 import './Newstaff.css'
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import BarcodeReader from 'react-barcode-reader'
import  { encrypt , decrypt } from 'react-crypt-gsm';
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";
 import Select1 from 'react-select'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
//import bcrypt from "bcrypt";
import axios from "axios";
import Select from 'react-dropdown-select'
import useKeyboardShortcut from 'use-keyboard-shortcut'
import bcrypt from 'bcryptjs'
import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment,audit1 } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation} from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import './Addstudent.css'

const Newstaff=({props,isAuthenticated,checkAuthenticated,load_user,store,removeInvoice,id,isadmin,useremail,payment,match,username1})=> {
 
  const [itemname, setItem]=useState();
  const [itemError,setIError]=useState(false);
  const [quantity, setQunatity]= useState();
  const [categoryValue, setCategoryValue]= useState('');
  const [unitValue, setUnitValue]= useState('');
  const [quantityError,setQError]=useState(false);
  const [sellingprice, setSPrice]=useState();
  const [sellingPError,setSPError]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [edit,setEdit]=useState(false);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [notInternet,setNoInternet]=useState(false);
  const [buyingprice,setBPrice]=useState();
  const [showhide,setShowhide]=useState(false);
  const [buyingPError,setBPError]=useState(false);
  const [savebtn,setSavebtn]=useState(true)
  const [wait,setWait]=useState(false)
  const [itemNError,setINError]=useState(false)
  const [serial,setSerial]=useState();
  const [validated,setValidated]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [success,setSuccess]=useState(false);
  const [result,setResult]=useState(false);
  const [result1,setResult1]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [branch2,setBranch2]=useState([]);
  const [branch,setBranch]=useState([]);
  const [dateposted,setDateposted]=useState("");
  const [code,setCode]=useState('');
  const [amountvisible,setAmountVisible]=useState(false);
  const [category, setCategory] = useState( [] );
const [categoryError,setCategoryError]=useState(false);
  const [serialError,setSerialError]=useState(false)
  const [branchError,setBranchError]=useState(false);
  const [unit,setUnit]=useState([
    { id: 1, country: "Male" },
    { id: 2, country: "Female" }, 
  ])
  const [unitError,setunitError]=useState(false)
  //const bcrypt = require('bcrypt');
  
  const showToastMessage = () => {
    toast.success('Record saved succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };   
  const showToastMessage5 = () => {

    toast.error('Operation not allowed on admin account', {
        position: toast.POSITION.BOTTOM_CENTER
    });
  };  
  const showToastMessage1 = () => {
    toast.success('Record updated succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };   
  const showToastMessage2 = () => {

    toast.error('You dont have permission to perform this action', {
        position: toast.POSITION.TOP_CENTER
    });
  }; 
  const removeErrors=()=>{
    setIError(false)
    setBPError(false)
    setCategoryError(false)
    setunitError(false)
    setQError(false)
    setBPError(false)
    setSPError(false)
  }
  const validateFields=(e)=>{
    e.preventDefault()
    if(itemname && quantity && buyingprice && sellingprice && !categoryValue.trim().length==0 &&
    categoryValue!=="Select Category"&& !unitValue.trim().length==0 &&
    unitValue!=="Select Unit"){
      //setValidated(true)
    setValidated(true)
    }

    
    else{
      if(!itemname){
        setIError(true)
       
        }
        if(categoryValue.trim().length==0 | categoryValue=="Select Category"){
          setCategoryError(true)
          }
        if(unitValue.trim().length==0 | unitValue=="Select Unit"){
          setunitError(true)
         
          }
          if(!buyingprice){
            setBPError(true)
           
            }
            if(!sellingprice){
              setSPError(true)
             
              }
              if(!quantity){
                setQError(true)
               
                }
                
    }
  }
  function hidesuccess() {
    return new Promise(resolve => {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    });
  }
  const clearFields=()=>{
    setItem('')
    setQunatity('')
    setBPrice('')
    setSPrice('')
   
  }
  const handleCategoryChange = (event) => {
    setCategoryValue(event.target.value) 
  } 
  const handleUnitChange = (event) => {
    setUnitValue(event.target.value) 
  } 
  useEffect(()=>{
//check if edit is true
const itemnumber = /[^/]*$/.exec(window.location.href)[0];
//alert(match.params.id1)
let pid= match.params.pid
 if(pid>=0)
 {
setResult1(true)
 setSavebtn(false)
 //alert('ok')
 }
     //fetch edit records when editing
     if(result1){
      
     //fetch Item records
     axios({
      method:'post',
      url:`/productsearch/`,
      data:{id:match.params.pid},
    })
    .then((Response)=>{
     const datareceived=Response.data;
     console.log(datareceived)
    //set fields
    setItem(datareceived[0].itemname);
    setQunatity(datareceived[0].quantity);
    setUnit(datareceived[0].unit);
    setCategory(datareceived[0].category);
    
      })
     //set edit
     setEdit(true);
     setEditbtn(true)
    
       //set local invoice
      // setInvoiceNumber(invoicenumber)
      
     }else{
      
      
     }
//fetch category
let data5 ;
axios({
 method:'post',
 url:'/totalcategory/',
 data:{useremail:useremail},
})
.then(res => {
   data5 = res.data;
   setCategory(data5)
   //setUnit(data5)
})
.catch(err => {})


//fetch unit
let data7 ;
axios({
 method:'post',
 url:'/totalunit/',
 data:{useremail:useremail},
})
.then(res => {
   data7 = res.data;
   //setCategory(data5)
   setUnit(data7)
})
.catch(err => {})
/*  const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }*/
    fetchCode()
    setTimeout(() => {
      
        if(isadmin=="true"){
          handleModalShowHide()
        }
      
    }, 3000)
    axios({
      method:'post',
      url:'/totalstore/',
      data:{useremail:useremail},
     })
     .then(res => {
       
        setBranch(res.data)
        //setUnit(data5)
     })
     .catch(err => {})
  },[])

  useEffect(()=>{
    
if(result1){
  //alert(result1)
 //fetch student records
 axios({
  method:'post',
      url:`/productsearch/`,
      data:{id:match.params.pid},
 
})
.then(res => {
   
    //setData(res.data);
    console.log(res.data)  
    setItem(res.data[0].itemname);
    setSerial(res.data[0].serial)
    setBPrice(res.data[0].bprice)
    setSPrice(res.data[0].sprice)
    setQunatity(res.data[0].quantity)
    //alert(res.data[0].id)
    setEditbtn(true)
})
.catch(err => {})
}

  },[result1])
  useEffect(()=>{
    //save 
if(validated){

//save staff
if(useremail=="4")
{
showToastMessage2();
}

else{
if(editbtn)
{

const editData = async () => {

const result = await axios({
method:'put',
url:`/api/item/${match.params.pid}/`,
data:{itemname,category:categoryValue,unit:unitValue,quantity,serial,bprice:buyingprice,sprice:sellingprice,owner:useremail,editedby:username1},
})
.then((Response)=>{
if (Response.data){
//setSaveSuccess(true)
setWait(false)
removeErrors()
showToastMessage1()
//setSuccess(true)
setValidated(false)
//hidesuccess();

     }
})
.catch((error) => {
if (error.code="ERR_BAD_RESPONSE"){

setWait(false)
setNoInternet(true)
     }

})

}
editData()
axios({
  method:'post',
  url:'/api/audit/',
  data:{username:username1,operation:'Product edited',table:'Products',owner:useremail},
})
.then(res => {
   

})
.catch(err => {})
}else
{

  

saveData()


 //edîtitem code
 axios({
  method:'post',
      url:`/edititemcode/`,
      data:{useremail:useremail},
 
})
.then(res => {
   fetchCode()
})
.catch(err => {})
audit1(username1,'Data inserted','Products',useremail)

axios({
  method:'post',
  url:'/api/audit/',
  data:{username:username1,operation:'Product inserted',table:'Products',owner:useremail},
})
.then(res => {
   

})
.catch(err => {})

}
}
}

    
      },[validated]) 
      const saveData = async () => {
        if(branch2.length==0 && isadmin=="true"){
      handleModalShowHide()
        }else{
       // alert(unitValue)
      const result = await axios({
      method:'post',
      url:'/api/item/',
      data:{itemname,category:categoryValue,unit:unitValue,quantity,serial,bprice:buyingprice,sprice:sellingprice,owner:useremail,editedby:username1,store:isadmin=="true"?branch2.split(' ')[0]:store,itemcode:code},
      })
      .then((Response)=>{
      if (Response.data){
       setSaveSuccess(true)
       setWait(false)
       setSavebtn(true)
       removeErrors()
       showToastMessage()
       //setSuccess(true)
       setValidated(false)
       //hidesuccess();
      clearFields()
             }
      })
      .catch((error) => {
      if (error.code="ERR_BAD_RESPONSE"){
      
       setWait(false)
       setNoInternet(true)
             }
      
      })
      
        }
      
      }
      const fetchCode=()=>{
        let data4 ;
        let data5
        axios({
         method:'post',
         url:'/totalitemcodes/',
         data:{useremail:useremail},
       })
       .then(res => {
           data4 = res.data;
           if(data4.length>0){
      data5=parseInt(data4[0].code)+1
            setCode('ITEM-'+data5 );
            
           }else{
            setCode('ITEM-1')
            
           }
           
       })
       .catch(err => {})
      
       } 
       const handleModalShowHide=()=> {
        setSaveSuccess(false)
          setShowhide(true);
      }   
      const closeModal=()=> {
        setShowhide(false);
    }
    const handleCategoryChange1 = (event) => {
      setBranch2(event.target.value) 
      closeModal()
     // filterItems()
    } 
const renderCategory=()=>{
return (category.map(data =>({label:data.bookid})))
  }
   if(payment=="false"){
    return <Redirect to='/payment'/>}
    const handleScan=(data)=>{
      console.log(data)
     setSerial(data)
    }
  return (
    <div>
      <BarcodeReader
   onScan={handleScan}
   />    
       <ToastContainer
       hideProgressBar={true}
      />
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record {edit?"Updated":"Saved"} succesffully</div></div>
       <div className='path'><i className='fa fa-home'></i>{edit? '/ Products / Edit Item':'/ Products / Add Item'}</div>
        <div className='bodysection col-lg-7  '>
        {saveSuccess || updateSuccess &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>operation succesfull...</span>}
       
<div className=' col-lg-12 theader'>< h5>Item Details</h5> </div>
<Form >
  <div className='row pl-4'>
 
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
</FormGroup>}
<FormGroup className='mr-2 ml-2'>

<Input className={itemError &&'errorborder'} type="text" placeholder="Item name"value={itemname} onChange={e => setItem(e.target.value)} name="email"  required/>
<div className={itemError ?'errmessage1':'errmessagehide1'}>Item Name is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 '>
<div style={{width:'220px'}}className={categoryError &&'errorborder'} >
              
          <select onChange={handleCategoryChange} className={categoryError ? 'errorborder newselect1':'newselect'}>
 
 <option>{edit?  category :'Select Category'}</option>
 {category.map((option, index) => {
     return <option key={index} >
         {option.name}
     </option>
 })}
</select>    
          
            </div>
<div className={categoryError ?'errmessage1':'errmessagehide1'}>Category is Reguired</div>
</FormGroup>

<FormGroup className='mr-2 ml-2 '>
<div style={{width:'220px'}}className={unitError &&'errorborder'} >
              
          <select onChange={handleUnitChange} className={categoryError ? 'errorborder newselect1':'newselect'}>
 
 <option>{edit && unit ?  unit :'Select Unit'}</option>
 {unit.map((option, index) => {
     return <option key={index} >
         {option.name}
     </option>
 })}
</select>    
          
            </div>
<div className={unitError ?'errmessage1':'errmessagehide1'}>Unit is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input  className={serialError &&'errorborder'} type="text" placeholder="ScanCode"name="author" value={serial} onChange={e => setSerial(e.target.value)} required />
<div className={serialError ?'errmessage1':'errmessagehide1'}>Serial is Reguired</div>
</FormGroup>

  
 
 {amountvisible && <FormGroup className='mr-5 ml-3'>
 <Label>Amount</Label>
 <Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
 </FormGroup>}
 <FormGroup className='mr-2 ml-2'>
 
 <Input className={buyingPError &&'errorborder'} type="number" placeholder="Unit Buying Price"value={buyingprice} onChange={e => setBPrice(e.target.value)} name="email"  required/>
 <div className={buyingPError ?'errmessage':'errmessagehide'}>Unit Buying Price is Reguired</div>
 </FormGroup>
 <FormGroup className='mr-2 ml-2'>
 <Input className={sellingPError &&'errorborder'} type="number" placeholder=" Unit Selling Price"name="author" value={sellingprice} onChange={e => setSPrice(e.target.value)} required />
 <div className={sellingPError ?'errmessage':'errmessagehide'}>Unit Selling Price is Reguired</div>
 </FormGroup>
 <FormGroup className='mr-2 ml-2'>
 <Input className={quantityError &&'errorborder'} type="number" placeholder="Quantity"name="author" value={quantity} onChange={e => setQunatity(e.target.value)} required />
 <div className={quantityError ?'errmessage':'errmessagehide'}>Quantity is Reguired</div>
 </FormGroup>
 
   </div>
  <div className='col-lg-6 ml-2 studenthealth' >
 
</div>
 
      <div className='row pl-4'>
  <Button color="primary"className=" ml-2 mb-3" type="submit"onClick={(e)=> validateFields(e)}>
      {editbtn && "Update"}
      {savebtn && !editbtn && "Submit"}
      {wait && " Please wait.."}
    </Button>  
    
    </div>
    </Form>
       
</div>
<div className='col-lg-2'>
       <Modal show={showhide}className='col-lg-4 d-flex ml-auto mr-auto'>
           <Modal.Header closeButton onClick={closeModal}>
           <Modal.Title>Select Store</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
        
  { <FormGroup className='mr-2 ml-2 '>

<div style={{width:'220px'}}className={branchError &&'errorborder'} >
              
          <select onChange={handleCategoryChange1} className={branchError ? 'errorborder newselect1':'newselect'}>
 
 <option>{'Select Branch Name'}</option>
 { branch.map((option, index) => {
     return <option key={index} >
         {option.id + ' '+ option.name}
     </option>
 })}
</select>    
          
            </div>
<div className={branchError ?'errmessage1':'errmessagehide1'}>Branch is Reguired</div>
</FormGroup>}

<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       value={dateposted}
       required
     
     />
</FormGroup>
   
</Form>

</Modal.Body>

       </Modal>
       </div>
    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  isadmin:state.auth.isadmin,
  store:state.auth.store,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment,audit1})(Newstaff))