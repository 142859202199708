import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import { ToastContainer, toast } from 'react-toastify'; 
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";

// Then import the virtualized Select HOC
import VirtualizedSelect from 'react-virtualized-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,faTrash} from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Alert,
} from "reactstrap";
import axios from "axios";
import  { encrypt , decrypt } from 'react-crypt-gsm';
import useKeyboardShortcut from 'use-keyboard-shortcut'
import { useSelect } from 'react-select-search';
import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation,useParams,NavLink,Link } from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import './Addstudent.css'
import SelectSearch from 'react-select-search';
import Select from 'react-dropdown-select'
const Addstudent=({props,isAuthenticated,checkAuthenticated,load_user,removeInvoice,id,useremail,payment,match,username1})=> {
 
  const [showhide,setShowhide]=useState(false);
  const [customerFetch,setCustomerFetch]=useState(false);
  const [amountvisible,setAmountVisible]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [showhide1,setShowhide1]=useState(false);
  const [showhide2,setShowhide2]=useState(false);
  const [names, setName] = useState("");
  const [filteredinvoice, setFilteredInvoice] = useState("");
  const [name, setNames] = useState("");
  const [studentadm, setStudentadm] = useState("");
  const [studentadmError, setStudentadmError] = useState(false);
  const [first_name, setFname] = useState("");
  const [first_nameError, setFnameError] = useState(false);
  const [last_name, setLname] = useState("");
  const [last_nameError, setLnameError] = useState(false);
  const [data,setData]=useState([""]);
  const [dob, setDob] = useState("");
  const [dobError, setDobError] = useState(false);
  const [class_name, setCname] = useState("");
  const [class_nameError, setCnameError] = useState(false);
  const [dormitory, setDormitory] = useState("");
  const [dormitoryError, setDormitoryError] = useState(false);
  const [religion, setReligion] = useState("");
  const [religionError, setReligionError] = useState(false);
  const [adm_date, setAdmdate] = useState("");
  const [adm_dateError, setAdmdateError] = useState(false);
  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState(false);
  const [gender, setGender] = useState("");
  const [gender1, setGender1] = useState("Select Gender");
  const [genderError, setGenderError] = useState(false);
  const [county, setCounty] = useState("");
  const [countyError, setCountyError] = useState(false);
  const [health, setHealth] = useState("");
  const [healthError, setHealthError] = useState(false);
  const [parent_first_name, setPfname] = useState("");
  const [parent_first_nameError, setPfnameError] = useState(false);
  const [parent_last_name, setPlname] = useState("");
  const [parent_last_nameError, setPlnameError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [email,setEmail]=useState("");
  const [tendered,setTendered]=useState(0)
  const [emailError,setEmailError]=useState(false);
  const [mobile,setMobile]=useState("");
  const [mobileError,setMobileError]=useState(false);
  const [town,setTown]=useState("");
  const [townError,setTownError]=useState(false);
  const [id1,setId]=useState("");
  const [inumber,setInumber]=useState("hello");
  const [id2,setId2]=useState("");
  const [edit,setEdit]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [savebtn,setSavebtn]=useState(false);
  const [wait,setWait]=useState(false);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [dropopen,setDropOpen]=useState(false);
  const [dropdown,setDropDown]=useState("Unpaid");
  const [issuedate,setIssueDate]=useState("");
  const [duedate,setDueDate]=useState("");
  const [invoiceNumber,setInvoiceNumber]=useState('');
  const [studentNumber,setSNumber]=useState('');
  const [studentnid,setSNumberid]=useState('');
  const [relation,setRelation]=useState('');
  const [relationError,setRelationError]=useState('');
  const [dorm,setDom]=useState('');
  const [dorm1,setDom1]=useState('');
  const [musyoki,setMusyoki]=useState('musyoki');
  const [inputList1,setInputList1]=useState([]);
  const [heading,setHeading]=useState(['Item', 'Qty','Vat','Price','Amount',''])
  const [InputList, setInputList] = useState([]);
  const [inputList, setinputList] = useState([]);
  const [notInternet,setNoInternet]=useState(false);
  const [success,setSuccess]=useState(false);
  const [quantity1,setQuantity1]= useState('');
  const [quantity,setQuantity]= useState('');
  const [month,setMonth]= useState('');
  const [year,setYear]= useState('');
  const [validated,setValidated]=useState(false);
  const [result,setResult]=useState(false);
  const [search,setSearch]=useState("");
  const [classstream,setCsream]=useState("");
  const [counter,setcounter]=useState(0);
  const [inputList2, setInputList2] = useState([]);
  const [deleteid,setDeleteId]=useState("");
  const [details,setDetails]=useState([""]);
  const [items,setItems]=useState([""]);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [grandtotal,setGrandTotal]=useState('');
  const [options, setOptions] = useState( [
    { id: 1, country: "1 Blue" },
    { id: 2, country: "2 Blue" }, 
    { id: 3, country: "3 Blue" },
    { id: 4, country: "4 Blue" },
] )
const [inputStyle, setInputStyle] = useState( { })
const [inputStyle1, setInputStyle1] = useState( { })
const [inputStyle2, setInputStyle2] = useState( { })
const [inputStyle3, setInputStyle3] = useState( { })
const [inputStyle4, setInputStyle4] = useState( { })

const [options1, setOptions1] = useState( [
  { id: 1, country: "Twiga" },
  { id: 2, country: "Ndovu" }, 
  { id: 3, country: "Kiboko" },
  { id: 4, country: "Fisi" },
] )

const [options2, setOptions2] = useState( [
  { id: 1, country: "Christian" },
  { id: 2, country: "Muslim" }, 
  { id: 3, country: "Hindu" },
  { id: 4, country: "Pagan" },
] )

const [options3, setOptions3] = useState( [
  { id: 1, country: "Boarding" },
  { id: 2, country: "Day" }, 
  
] )

const [options4, setOptions4] = useState( [
  { id: 1, country: "Male" },
  { id: 2, country: "Female" }, 
  
] )
const [options5, setOptions5] = useState( [
  { id: 1, country: "Father" },
  { id: 2, country: "Mother" }, 
  { id: 3, country: "Relative" }, 
  { id: 4, country: "Sponsor" }, 
  
] )
const [selectedOptions, setSelectedOptions] = useState([])
const [selectedOptions1, setSelectedOptions1] = useState([])
const [selectedOptions2, setSelectedOptions2] = useState([])
const [selectedOptions3, setSelectedOptions3] = useState([])
const [selectedOptions4, setSelectedOptions4] = useState([])
const [adminMenu, setadminMenu] = useState(false);
const options7 = ['One', 'Two', 'Three', 'Four', 'Five'];

const ref = useRef();

  toast.configure()
  const showToast = () => toast("Operation succesful",{position: toast.POSITION.TOP_CENTER});
  const adminMenuClick = () => {
    setadminMenu(!adminMenu);
   
  }
  const showToastMessage = () => {

    toast.error('Select Products to sell', {
        position: toast.POSITION.TOP_CENTER
    });
  };
  const showToastMessage1 = () => {

    toast.success('Record updated succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };
  const showToastMessage2 = () => {

    toast.error('No Items to sell', {
        position: toast.POSITION.TOP_CENTER
    });
  };   
  const showToastMessage3 = () => {

    toast.success('Items sold succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };     
 const fetchStudentNumber=()=>{
  let data4 ;
  let data5
  axios({
   method:'post',
   url:'/totalstudentnumber/',
   data:{useremail:useremail},
 })
 .then(res => {
     data4 = res.data;
     if(data4.length>0){
data5=parseInt(data4[0].stdnumber)+1
      setSNumber(data5)
      setStudentadm('ADM-'+data5 );
      setSNumberid(data4[0].id)
     }else{
      setStudentadm('ADM-1')
      setSNumber(1)
     }
     
 })
 .catch(err => {})

 }
 const fetchProducts=()=>{
  let data6 ;
  axios({
   method:'post',
   url:'/totalitem/',
   data:{useremail:useremail},
 })
 .then(res => {
     data6 = res.data;
     setItems(data6);
   setDetailsbkp(data6)
 })
 .catch(err => {})
 }
  useEffect(()=>{
 
    //check authentication
    fetchStudentNumber()
   // checkAuthenticated();
    fetchPayment(useremail)
    //fetch classes
    let data4 ;
      axios({
       method:'post',
       url:'/totalclass/',
       data:{useremail:useremail},
     })
     .then(res => {
         data4 = res.data;
         setOptions(data4);
       
     })
     .catch(err => {})
     //fetch dorms

   let data5 ;
      axios({
       method:'post',
       url:'/totaldorms/',
       data:{useremail:useremail},
     })
     .then(res => {
         data5 = res.data;
         setOptions1(data5);
       
     })
     .catch(err => {}) 
//check if edit is true

const invoicenumber = /[^/]*$/.exec(window.location.href)[0];
//const result = invoicenumber.includes("-") && invoicenumber.length<40 ? true : false;
if(match.params.id>=0)

 {
  setResult(true)
  setSavebtn(false)
 }
   //fecth products
   
   fetchProducts()
   //checkAuthenticated();
   /*  const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }*/
      },[])
      
      useEffect(()=>{
 //fetch edit records when editing
 if(result){
  setSavebtn(false)
  
 //fetch student records
 axios({
  method:'post',
  url:'/studentsearch/',
  data:{id:match.params.id},
})
.then((Response)=>{
 const datareceived=Response.data;

//set fields
setStudentadm(datareceived[0].studentadm)
setEmail(datareceived[0].email)
setFname(datareceived[0].first_name)
setLname(datareceived[0].last_name)
setDob(datareceived[0].dob)
setSelectedOptions([{'label':datareceived[0].class_name}])
setSelectedOptions1([{'label':datareceived[0].dormitory}])
setSelectedOptions2([{'label':datareceived[0].religion}])
setSelectedOptions3([{'label':datareceived[0].category}])
setSelectedOptions4([{'label':datareceived[0].gender}])
setAdmdate(datareceived[0].adm_date)
setCounty(datareceived[0].county)
setHealth(datareceived[0].health)
setPfname(datareceived[0].parent_first_name)
setPlname(datareceived[0].parent_last_name)
setPhone(datareceived[0].phone)
setTown(datareceived[0].town)
setId2(datareceived[0].id)
setDom(datareceived[0].dormitory)
setDom1(datareceived[0].dormitory)
setCname(datareceived[0].class_name)
setReligion(datareceived[0].religion)
setCategory(datareceived[0].category)
setGender(datareceived[0].gender)
setRelation(datareceived[0].relation)
  })
 //set edit
 setEdit(true);
 setEditbtn(true)

   //set local invoice
  // setInvoiceNumber(invoicenumber)
  
 }else{
  setSavebtn(true)
  
 }

      },[result])
      useEffect(()=>{
      fetchProducts() 
      },[InputList])
      function hidesuccess() {
        return new Promise(resolve => {
          setTimeout(() => {
            setSuccess(false);
          }, 5000);
        });
      }
      
  
  const clearFields=()=>{
    setStudentadm('')
    setFname('')
    setLname('')
    setDob('')
    setSelectedOptions([])
    setSelectedOptions1([])
    setSelectedOptions2([])
    setSelectedOptions3([])
    setSelectedOptions4([])
    setCounty('')
    setTown('')
    setAdmdate('')
    setHealth('')
    setPfname('')
    setPlname('')
    //setEmail('')
    setPhone('')
   
    

  }
 

   
        useEffect(()=>{
          getTotal();
          
            },[InputList]) 
        const handleModalShowHide1=(id)=> {
          setDeleteId(id)
          setShowhide1(!showhide1);
          console.log("clicked");
        }  
  if(isAuthenticated=="false"){
   return <Redirect to='/login'/>}
  
   if(payment=="false"){
   return <Redirect to='/payment'/>}
    const removeErrors=()=>{
      setFnameError(false) 
      setLnameError(false)
      setDobError(false)
      setCnameError(false)
      setInputStyle({
        'border-color':'#D3D3D3'
      })
      setReligionError(false)
      setInputStyle2({
        'border-color':'#D3D3D3'
      })
      setCategoryError(false)
                  
      setInputStyle3({
        'border-color':'#D3D3D3'
      })
      setGenderError(false)
                    
      setInputStyle4({
        'border-color':'#D3D3D3'
      })
      setAdmdateError(false)
      setCountyError(false)
      setTownError(false)
      setPfnameError(false)
      setPlnameError(false)
      setPhoneError(false)
      setEmailError(false)
      setRelationError(false)
    }

    const validateFields1=(e)=>{

    }
    const handleSubmit = (e) => {
      e.preventDefault()
      
      if(InputList.length==0){
      //alert("select customer,products and invoice details")
      showToastMessage2()
         }else
      if(edit){
    
        //showToastMessage4()
      }
      else{
        setEditbtn(false)
      setSavebtn(false)
      setWait(true)
       //generate invoice id
       const unique_id = uuid();
       const small_id ="RCT-"+ unique_id.slice(0,8).toUpperCase();
       setInumber(small_id)  
      
    
       //save the sales details
       
       let cartLen = InputList.length;
    
       for (let i = 0; i < cartLen; i++) {
          const invoicenumber1=small_id;
          const name1=InputList[i].name1;
          const quantity1=InputList[i].quantity1;
          const newQuantity=InputList[i].quantity-quantity1
          const total=InputList[i].price1;
          const id= InputList[i].id;
        
       axios({
        method:'post',
        url:'/api/sales1/',
        data:{serial:small_id,product:name1,amount:total,quantity:quantity1,owner:useremail},
      })
      .then((Response)=>{
        if (Response.data){
          
                }
      })
      .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
         
                }
        
      })
    
      //edit quantity
      axios({
        method:'put',
        url:`/api/item/${id}/`,
        data:{quantity:newQuantity,owner:useremail},
      })
      .then((Response)=>{
        if (Response.data){
          
                }
      })
      .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
         
                }
        
      })  }
      fetchProducts()
    //clear produts
    setInputList([])
    fetchProducts()
    showToastMessage3()
  
  if(!notInternet)
  {
    clearFields();
  }
   
  
  
  //setEdit(false);
  setId('');
  //window.location.reload();
    }}
    const onOptionChangeHandler = (event) => {
      setDom(event.target.value) 
  }   
  const onOptionChangeHandler1 = (event) => {
    setCname(event.target.value) 
}  

const onOptionChangeHandler2 = (event) => {
  setCategory(event.target.value) 
}  
const onOptionChangeHandler3 = (event) => {
  setGender(event.target.value) 
} 
const onOptionChangeHandler4 = (event) => {
  setRelation(event.target.value) 
} 
const onOptionChangeHandler5 = (event) => {
  setReligion(event.target.value) 
} 

const onOptionChangeHandler6 = (event) => {
  setCname(event.target.value) 
} 
const handleInputChange = (e, index) => {
  if(edit){
  const { name, value } = e.target;
  const list = [...inputList1];

  list[index][name] = value;
  setInputList1(list);
  }else{
    const { name, value } = e.target;
  const list = [...InputList];

  list[index][name] = value;
  setInputList(list);
  }
};
    const validateFields=(e)=>{
    
   e.preventDefault()
  
      if(inputList.length>0){
                 
        setValidated(true)}
        else{
      
                showToastMessage()
          }
    }
    const reLoadPage=()=>{
       
      window.location.reload();
  }
    const handleRemoveClick = (index,id )=> {
      const list = [...InputList];
      list.splice(index, 1);
      setInputList(list);
     
      getTotal();}
    const getTotal=()=>{
  
      const res=InputList.reduce((prev,item)=>{
        
          return prev +(item.price1*item.quantity1);
      },0)
      setGrandTotal(res)
      console.log(res);
      }
    const addProduct=(data)=>{

      //set fields
      if(!edit){
      setInputList([...InputList, {id:data.id, no:InputList.length+1,name1: data.itemname, quantity1: 1,quantity:data.quantity,vat:'16%',price1:data.price}]);
      }
        getTotal();
   }
    const filterList = (event) => {
      //search staff
    
      var updatedList = detailsbkp;
      updatedList = updatedList.filter(detail => detail.itemname.toLowerCase().includes(search.toLowerCase()) )
      setItems(updatedList)
      
      };
  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
      <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record {edit?"Updated":"Saved"} succesffully</div></div>
 <div className='path'><i className='fa fa-home'></i>/ Students / {edit?'Edit Student':'Add Student'}</div>
        <div className='bodysection8 col-lg-12 '>
        {saveSuccess || updateSuccess &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>operation succesfull...</span>}
      

<div className=' col-lg-12 theader d-flex justify-content-center'>< h5 className=''>JOSEPOS SOFTWARE LICENSED TO UMOJA SUPERMARKET</h5> </div>
 <div className='row'>
  <div className='col-md-6'>

<div class="mb-1 bg-light ">

        
          <Table bordered className=" pl-0">
  <thead>
    <tr >
    {heading.map(head => <th>{head}</th>)}
    </tr>
  </thead>
 
                <tbody>
                     
                {InputList.length==0? '':InputList.map((x, i) => {
                   return (
                  <tr key={x.id} >
                
                     <td className="col-2" ><Label className='mt-1'>{x.name1}</Label></td>
                     <td className="col-2 minqty" >    <Input
            type="number"
              className="ml10 cborder"
              name="quantity1"
   placeholder="Enter Quantity"
              value={x.quantity1}
              onChange={e =>{ handleInputChange(e, i);getTotal()}}
              
            /></td>
<td className="col-2" >
<Label className='mt-1'>{x.vat}</Label></td>
            <td className="col-2" >
            <Input
                className="ml10 cborder"
                name="total"
     placeholder=""
                value={ x.price1}
                onChange={e => handleInputChange(e, i)}
              /></td>
               <td className="col-2" >
               <Input
              className="ml10 cborder"
              name="total"
   placeholder=""
              value={x.price1 && x.quantity1 && x.price1*x.quantity1}
              onChange={e => handleInputChange(e, i)}
            /></td>
                 <td className="col-1" >
                 <div className='rmleft '>
           
              { InputList.length > 0 && <i 
                className="fa fa-trash  mr10 mt-1 btn1  mt-1 "
                onClick={() => handleRemoveClick(i,x.id)}></i>}
              
            </div></td>     
                   </tr>
                   )
                })}
    </tbody>
    
     
           </Table> 
        
           </div>
           <div className='row ml-2 mt-2'>
           <FormGroup className='mr-2 ml-2 label2 mt-2'>
<Label>Total</Label>
<Input className='' type="text" placeholder="Total"value={grandtotal} onChange={e => setCsream(e.target.value)} name="email"  required/>

</FormGroup>
<FormGroup className='mr-2 ml-2 label2 mt-2'>
<Label>Tendered amount</Label>
<Input className='' type="text" placeholder="Tendered amount"value={tendered} onChange={e => setTendered(e.target.value)} name="email"  required/>

</FormGroup>
<FormGroup className='mt-1 mr-2 ml-2 label2 mt-2'>
<Label>Change</Label>
<Input className='' type="text" placeholder="Change"value={tendered?tendered-grandtotal:''} onChange={e => setCsream(e.target.value)} name="email"  required/>

</FormGroup>
           </div>
          <span className='mb-1 ml-3 floatright'><Button className='p-3' onClick={handleSubmit}><i class="fa fa-diamond product1" aria-hidden="true"></i>Sell</Button></span>
  </div>
  <div className='col-md-6'>
    <div><FormGroup className='mr-5 ml-3 mt-1'>
<Input type="text" placeholder="Search Product..."name="town" value={search} onChange={e =>{ setSearch(e.target.value);filterList()}}onKeyDown={filterList}onKeyUp={filterList} required />
        
</FormGroup></div>

    <div className='productparent ml-3 row' >
    {items.length==0? <div>No Records found</div>:items.map(detail => (
      <div className='productsquare col' onClick={(e)=> addProduct(detail)}>
      <i class="fa fa-image product"></i>
    <span className='productname'>{detail.itemname}</span>
    <span className='price'>Kshs {detail.price}</span>
    <span className='price'>Stock: {detail.quantity}</span>
      </div>
      ))}
     </div>

  </div>
 </div>
</div>

    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Addstudent))