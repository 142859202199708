import React,{useEffect,useState} from 'react'
import axios from "axios";
import { Table } from 'react-bootstrap';
import { Card,Modal} from 'react-bootstrap';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import {Redirect,Link} from 'react-router-dom';
import {connect} from 'react-redux';
import ToolkitProvider, { CSVExport,Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import TableHeaderColumn from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { ToastContainer, toast } from 'react-toastify';
import Select1 from 'react-select'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
function Viewstudents({isAuthenticated,username1,checkAuthenticated,useremail,payment,fetchPayment,isadmin,store}) {
  const [data,setData]=useState([""]);
  const [names,setName]=useState("");
  const [email,setEmail]=useState("");
  const [mobile,setMobile]=useState("");
  const [town,setTown]=useState("");
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [id,setId]=useState("");
  const [edit,setEdit]=useState(false);
  const [showhide,setShowhide]=useState(false);
  const [heading,setHeading]=useState(['Names', 'Email', 'Mobile','Town','Action']);
  const [deleteid,setDeleteId]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const [detailsbkp,setDetailsbkp]=useState([{'expense':"Rent"},{'expense':"Repair"},{'expense':"Damage"},{'expense':"Bill"}]);
  const [detailsbkp1,setDetailsbkp1]=useState(['']);
  const [valueState, setValueState] = useState('');
  const [valueState1, setValueState1] = useState('');
  const { ExportCSVButton } = CSVExport;
   const { SearchBar } = Search;
   const options = {
     page: 1,
     sizePerPage: 10,
     nextPageText: '>',
     prePageText: '<',
     showTotal: true
   };
   const closeModal1=()=> {
    setShowhide1(false);
    
    }
    const renderList=()=>{
      return (detailsbkp.map(data =>({label: data.expense })))
    }
    const renderList1=()=>{
      return (detailsbkp1.map(data =>({label:data.name })))
    }
    const handleSelectChange = (value) => {
    
      setValueState(value);
     
      if(value){
        console.log(value)
      }
     
    }
    const handleSelectChange1 = (value) => {
    
      setValueState1(value);
      
      if(value.length>0){
        console.log(value[0].label.split(',').shift())
      }
     
    }
   const columns = [{
     dataField: 'name',
     text: 'Name',
     headerStyle: () => {
       return { width: "150px" };
     }
   }, {
     dataField: 'amount',
     text: 'Amount',
     headerStyle: () => {
       return { width: "100px" };
     }
   } 
   , {
    dataField: 'dateentered',
    text: 'Date',
    headerStyle: () => {
      return { width: "100px" };
    }
  }
 ,
 {
  dataField: 'description',
  text: 'Description',
  headerStyle: () => {
    return { width: "180px" };
  }
}
];
  useEffect(()=>{
    let data1 ;

    axios({
      method:'post',
      url:'/totalexpenses/',
      data:{useremail:useremail,isadmin,store},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
      console.log(data1)
    })
    .catch(err => {})
    checkAuthenticated();
    fetchPayment(useremail)
    
      if(!payment){
        return <Redirect to='/payment'/>}
        fetchProducts()
        fetchAllSales()
      },[]) 
      const handleModalShowHide=()=> {
        setShowhide(!showhide);
    }
    const closeModal=()=> {
      setShowhide(false);
  }
  const fetchAllSales=()=>{
      
    let data4 ;
    axios({
     method:'post',
     url:'/totalstore/',
     data:{useremail:useremail,isadmin,store},
   })
   .then(res => {
       data4 = res.data;
       setDetailsbkp1(res.data);
      console.log(res.data)
   })
   .catch(err => {})}

  const fetchProducts=()=>{
    let data6 ;
    axios({
     method:'post',
     url:'/totalitem/',
     data:{useremail:useremail,isadmin,store},
   })
   .then(res => {
       data6 = res.data;
       console.log(res.data)
     //setDetailsbkp(data6)
   })
   .catch(err => {})
   }
  const refreshData=()=>{
    let data1 ;

    
    axios({
      method:'post',
      url:'/totalexpenses/',
      data:{useremail:useremail,isadmin,store},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
       
    })
    .catch(err => {})
    
  }
  const handledate = () => {
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return date;
  }
  const handleSubmit = (e) => {

    if(!email|!mobile|!town){
     
       }else
    if(id){
      e.preventDefault();
    
      axios({
        method:'put',
        url:`/api/customer/${id}/`,
        data:{itemid:valueState[0].label.split(',').shift(),serial:valueState1[0].label,quantity:email,dateentered:mobile,reason:town,useremail,rtype:"sales",store},
      }).then((Response)=>{
        setEdit(false);
        setId('');
        //clear fields
    cleaFields();
      })
      setUpdateSuccess(true)
    }
    else{
     
   axios({
     method:'post',
     url:'/api/expense/',
     data:{name:valueState && valueState.label,store:valueState1.label,amount:email,dateentered:mobile,description:town,owner:useremail},
    }).then((Response)=>{
    //clear fields
    cleaFields();
   })
   axios({
    method:'post',
    url:'/api/audit/',
    data:{username:username1,operation:'Expense inserted',table:'Expenses',owner:useremail},
  })
  .then(res => {
     
  
  })
  .catch(err => {})
   setSaveSuccess(true)
  }
  };
  const editItem = (detail) => {
    setId(detail.id);
    setEdit(true);
    setShowhide(true);
    setName(detail.name);
    setEmail(detail.email);
    setMobile(detail.mobile);
    setTown(detail.town);

  };
  const showToastMessage = () => {
    toast.success('Record deleted succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };  
  const handleDelete = (item) => {

    console.log(item);
    axios({
      method:'delete',
      url:`/api/customer/${deleteid}/`,
    }).then((Response)=>{
      handleModalShowHide1()
      showToastMessage()
      refreshData();
    })
  
  };
  const cleaFields=()=>{
    setName('');
    setEmail('');
    setMobile('');
    setTown('');
    refreshData();
  }
  useEffect(()=>{
    checkAuthenticated();
    
      },[]) 
      useEffect(()=>{
     
      },[isAuthenticated]) 
      const handleModalShowHide1=(id)=> {
        setDeleteId(id)
        setShowhide1(!showhide1);
        console.log("clicked");
      }
        if(payment=="false"){
          return <Redirect to='/payment'/>}
  return (
    <div>
      <div className='path'><i className='fa fa-home'></i>{edit? '/ Products / Edit Item':'/ Expenses / Expense'}</div>
        <ToastContainer
       hideProgressBar={true}
      />
        <div className='bodysection col-lg-6'>
        <div className=' col-lg-12 theader'>< h5>Expenses List</h5> </div>
        <Button  onClick={handleModalShowHide} className="m-2 btn-primary">
           + New
       </Button>
       <Modal show={showhide}>
           <Modal.Header closeButton onClick={closeModal}>
           <Modal.Title>New Expense details</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
<FormGroup>
<Label>Expense Name</Label>
<Select1
       value={valueState}
       defaultValue=""
       placeholder="Select Item"
       options={renderList()}
       onChange={ (value) => handleSelectChange(value) }
       name="ColumnSelect"
       label=""
       isClearable
/></FormGroup>
<FormGroup>
<Label>Store</Label>
<Select1
       value={valueState1}
       defaultValue=""
       placeholder="Select Store"
       options={renderList1()}
       onChange={ (value) => handleSelectChange1(value) }
       name="ColumnSelect"
       label=""
       isClearable
/></FormGroup>
<FormGroup>
<Label>Amount</Label>
<Input type="number" placeholder="Enter Amount"value={email} onChange={e => setEmail(e.target.value)} name="email"  required/>

</FormGroup>
<FormGroup>
<Label>Date</Label>
<Input type="date" placeholder="Select Date"name="author" value={mobile} onChange={e => setMobile(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Description</Label>
<Input type="textarea" placeholder="Expense details"name="town" value={town} onChange={e => setTown(e.target.value)} required />
</FormGroup>
<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       
       required
     
     />
</FormGroup>
   
           <Button color="primary"className="mt-2 " type="submit" onClick={(e)=> handleSubmit(e)}>
Submit
</Button>  
<Button color="primary ml-2"className=" m-2 mt-3" type=""onClick={closeModal}>
Close
</Button>
</Form>

</Modal.Body>

       </Modal>
       <ToolkitProvider
keyField="id"
data={[...data] }
columns={ columns }
exportCSV={{ onlyExportFiltered: true, exportAll: false }}
search
>
{
props => (
<div>
<ExportCSVButton { ...props.csvProps } className='btn btn-primary m-1'>Export </ExportCSVButton> 

<SearchBar { ...props.searchProps } />

<BootstrapTable keyField='id'
{...props.baseProps}
data={ data } 
columns={ columns } 
pagination={ paginationFactory(options) }className="pgnav"
wrapperClasses="table-responsive">  

</BootstrapTable>
</div> 
)
}
</ToolkitProvider>

           </div>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Customer?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting a Customer Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={handleDelete}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  isadmin:state.auth.isadmin,
  store:state.auth.store,
  username1:state.auth.username1,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,fetchPayment})(Viewstudents)