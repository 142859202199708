import React,{useEffect,useState} from 'react'
import axios from "axios";
import { Table } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Select1 from 'react-select'
import { Card,Modal} from 'react-bootstrap';
import * as excel from 'xlsx';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import ToolkitProvider, { CSVExport,Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import TableHeaderColumn from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { NavLink,BrowserRouter as Router,Route,Switch,Link } from 'react-router-dom';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import {Redirect,useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,faTrash} from '@fortawesome/free-solid-svg-icons'
import Stocktake from './Stocktake';


function Products({isAuthenticated,checkAuthenticated,reconcile,username1,stocktake,eitem,ditem,useremail,payment,fetchPayment,store,username,props,isadmin,aitem}) {
  const [data,setData]=useState([""]);
  const [databkp,setDatabkp]=useState([""]);
  const [data1,setData1]=useState([""]);
  const [data2,setData2]=useState([""]);
  const [name,setName]=useState("");
  const [names,setNames]=useState([""]);
  const [category1,setCategory1]=useState([""]);
  const [uom,setUom]=useState([""]);
  const [bprice1,setBprice1]=useState([""]);
  const [sprice1,setSprice1]=useState([""]);
  const [quantity1,setQuantity1]=useState([""]);
  const [price,setPrice]=useState("");
  const [dateposted,setDateposted]=useState("");
  const [quantity,setQuantity]=useState("");
  const [category,setCategory]=useState("");
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [deleteid,setDeleteId]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const [saving,setSaving]=useState(false);
  const [branchError,setBranchError]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [id,setId]=useState("");
  const [edit,setEdit]=useState(false);
  const [error1,setError]=useState(false);
  const [branch,setBranch]=useState([]);
  const [branch1,setBranch1]=useState([]);
  const [branch2,setBranch2]=useState([]);
  const [valueState, setValueState] = useState('');
  const [valueState1, setValueState1] = useState('');
  const fileInput= React.useRef();
  const [showhide,setShowhide]=useState(false);
  const [data3,setData3]=useState([{'item':'Stock at 0'},{'item':'Stock below 5'},{'item':'Stock below 10'},{'item':'Stock below 20'}]);
  const [showhide3,setShowhide3]=useState(false);
  const [refreshed,setRefreshed]=useState(false);
  const [showhide2,setShowhide2]=useState(false);
  const [heading,setHeading]=useState(['Name', 'Category', 'B. Price','S. Price','Quantity','Action']);
  const { ExportCSVButton } = CSVExport;
  const [code,setCode]=useState('');
   const { SearchBar } = Search;
   const options = {
     page: 1,
     sizePerPage: 10,
     nextPageText: '>',
     prePageText: '<',
     showTotal: true
   };
   const showToastMessage1 = () => {

    toast.error('Upload the right Excel file', {
        position: toast.POSITION.BOTTOM_CENTER
    });
  }; 
   const showToastMessage5 = () => {

    toast.error('Operation not allowed on admin account', {
        position: toast.POSITION.BOTTOM_CENTER
    });
  }; 
   const handleSelectChange = (value) => {
    
    setValueState(value);
    
   if(value){
    var updatedList = databkp;
    updatedList = updatedList.filter(detail =>(detail.store && detail.store==value.label.split(' ')[0] ))
    setData(updatedList)}
  }
   const renderList=()=>{
    return (data1.map(data =>({label:data.id+' ' + data.name })))
  }
   const { pathname } = useLocation();
   const columns = [{
     dataField: 'itemname',
     text: 'Name',
     headerStyle: () => {
       return { width: "220px" };
     }
   }, {
     dataField: 'category',
     text: 'Category',
     headerStyle: () => {
       return { width: "100px" };
     }
   } 
   , {
    dataField: 'unit',
    text: 'U.O.M',
    headerStyle: () => {
      return { width: "100px" };
    }
  } 
  
   ,  {
    dataField: 'bprice',
    text: 'Buying Price',
    headerStyle: () => {
      return { width: "100px" };
    }
  },
   {
    dataField: 'sprice',
    text: 'Selling Price',
    headerStyle: () => {
      return { width: "100px" };
    }
  }
  , {
    dataField: 'quantity',
    text: 'Quantity',
    headerStyle: () => {
      return { width: "100px" };
    }
  }, {
     dataField: "id",
     text: "Action",
     headerStyle: () => {
       return { width: "100px" };
     },
     csvExport: false,
     editable: false,
     formatter: (cellContent, row) => {
       return (
         <div>
       <div className='row '>{isadmin=="true"| eitem=="true" ?<div onClick={e =>{ setId(row.customerid);reLoadPage()}} className="border"><Link exact to={`/editproduct/${row.id}`}onClick={reLoadPage} >
                      <span className=''><i  class="fa fa-edit btn1"></i></span></Link></div>:""}{isadmin=="true"| ditem=="true" ?<span className='border'><i class="fa fa-trash btn1 border1" onClick={e =>{ handleModalShowHide1(row.id)}}></i></span>:""}</div>
       </div>
       );
     },
 },];
 
  useEffect(()=>{
   
    checkAuthenticated();
    
    fetchPayment(useremail);
  
    let data1 ;

    axios({
      method:'post',
      url:'/totalproducts/',
      data:{useremail:useremail,isadmin,store},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
        setDatabkp(data1);
        console.log(data1)  
    })
    .catch(err => {})
    //fetch stt
let data5 ;
axios({
 method:'post',
 url:'/totalstocktake1/',
 data:{useremail:useremail,store,isadmin},
})
.then(res => {
   data5 = res.data;
   setCategory(data5)
   //setUnit(data5)
})
.catch(err => {})
axios({
  method:'post',
  url:'/totalcategory/',
  data:{useremail:useremail},
 })
 .then(res => {
    data5 = res.data;
    setBranch(data5)
    //setUnit(data5)
 })
 .catch(err => {})
   /*  const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }*/
    let data7
    axios({
      method:'post',
      url:'/totalstore/',
      data:{useremail:useremail},
     })
     .then(res => {
        data7 = res.data;
        //setCategory(data5)
        setData1(data7)
     })
     .catch(err => {})
     fetchCode()
     setTimeout(() => {
      
      if(isadmin=="true"){
        handleModalShowHide3()
      }
    
  }, 3000)
      },[]) 
      const handleModalShowHide3=()=> {
        setSaveSuccess(false)
          setShowhide3(true);
      }  
     const handleModalShowHide=()=> {
      setSaveSuccess(false)
        setShowhide(!showhide);
    }
    const reLoadPage=()=>{
       
      //window.location.reload();
  }
    const closeModal=()=> {
      setShowhide(false);
  }
  const refreshData=()=>{
    let data1 ;

    axios({
      method:'post',
      url:'/totalproducts/',
      data:{useremail:useremail,isadmin,store},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
        console.log(data1)  
        setRefreshed(true)
    })
    .catch(err => {})
    
  }
  const handledate = () => {
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return date;
  }
  const handleSubmit = (e) => {
    e.preventDefault()
 if(!branch1|!branch2 |branch1=="Select Stocktake Name"|branch2=="Select Branch"){
setError(true)
return
 }

 else
    if(id){
      e.preventDefault();
      axios({
        method:'put',
        url:`/api/product/${id}/`,
        data:{name:name.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),category:category.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),price,quantity,handledate},
      }).then((Response)=>{
        //clear fields
        setEdit(false);
        setId('');
    cleaFields();
      })
      setUpdateSuccess(true)
    }
    else{
      if(branch2.length==0 && isadmin=="true"){
        closeModal()
        handleModalShowHide3()
          }else{
           // alert(branch2.split(' ')[0])
     if(branch1.length>0){
   axios({
     method:'post',
     url:'/editstocktake/',
     data:{idfield:branch1.length>0 && branch1.split(' ')[0],branch:branch2.split(' ')[0],owner:useremail,store: isadmin=="true"?branch2.split(' ')[0]:store},
   }).then((Response)=>{
    //clear fields
    cleaFields();
    refreshData()
   })
  
   setSaveSuccess(true);
     }
     axios({
      method:'post',
      url:'/api/audit/',
      data:{username:username1,operation:'Reconcile inserted',table:'Reconcile',owner:useremail},
    })
    .then(res => {
       
    
    })
    .catch(err => {})
    } 
  }
  };

  const handleSubmit1 = async(e) =>{
   
    e.preventDefault()
    console.log(names);

    if(id){
     
    }else if(names.length==1|category1==1|uom==1|quantity1==1){
showToastMessage1()
    }
    else{
     if(names.length=category1.length=uom.length=quantity1.length){
      if(branch2.length==0 && isadmin=="true"){
       closeModal()
        handleModalShowHide3()
          }else{
      for(let i=1; i< names.length; i++){
        let data4 ;
    let data5
    const result2 = await axios({
     method:'post',
     url:'/totalitemcodes/',
     data:{useremail:useremail},
   })
   .then(res => {
       data4 = res.data;
       if(data4.length>0){
  data5=parseInt(data4[0].code)+1
        setCode('ITEM-'+data5 );
       
       }else{
        setCode('ITEM-1')
        
       }
      
   })
   .catch(err => {})
        const itemname=names[i]
        const category=category1[i]
        const unit=uom[i]
        const bprice=bprice1[i]
        const sprice=sprice1[i]
        const quantity=quantity1[i]
        const res= await axios
        .post('/api/item/',{
          itemname,category,unit,quantity,bprice,sprice,owner:useremail,editedby:username1,store:isadmin=="true"?branch2.split(' ')[0]:store,itemcode:'ITEM-'+data5}
        )
   .then((Response)=>{
    //clear fields
    cleaFields();
    refreshData()
    setSaving(true)
    setSaveSuccess(true);
   
   })
  

    //edîtitem code
    const result = await axios({
      method:'post',
      url:`/edititemcode/`,
      data:{useremail:useremail},
      })
.then(res => {
   //fetchCode()
})
.catch(err => {})


     
      }
    }
      setSaving(false)
      axios({
        method:'post',
        url:'/api/audit/',
        data:{username:username1,operation:'Products imported',table:'Products',owner:useremail},
      })
      .then(res => {
         
      
      })
      .catch(err => {})
    }else{
      showToastMessage1()
    }
  }
  };
  const fetchCode=async()=>{
    let data4 ;
    let data5
    const result = await axios({
     method:'post',
     url:'/totalitemcodes/',
     data:{useremail:useremail},
   })
   .then(res => {
       data4 = res.data;
       if(data4.length>0){
  data5=parseInt(data4[0].code)+1
        setCode('ITEM-'+data5 );
       
       }else{
        setCode('ITEM-1')
        
       }
      
   })
   .catch(err => {})
  
   }    
  const editItem = (detail) => {
    setId(detail.id);
    setEdit(true);
    setShowhide(true);
    setName(detail.name);
    setCategory(detail.category);
    setPrice(detail.price);
    setQuantity(detail.quantity);
  };
  const handleCategoryChange = (event) => {
    setBranch1(event.target.value) 
  } 
  const handleCategoryChange1 = (event) => {
    setBranch2(event.target.value) 
  } 
  const handleDelete = (item) => {
    if(window.confirm('Are You Sure You want delete this record?')){
    console.log(item);
    axios({
      method:'delete',
      url:`/api/item/${item.id}/`,
    }).then((Response)=>{
      
      
      alert("record deleted succesfully");
      refreshData();
    })
  }
  };
  const closeModal1=()=> {
    setShowhide1(false);
   
    }
  const cleaFields=()=>{
   
   
    setPrice('');
    setQuantity('');
  
  }
  useEffect(()=>{
    
    
      },[]) 
      useEffect(()=>{
        if(branch2){
          var updatedList = data;
          updatedList = updatedList.filter(detail =>(detail.store && detail.store==(branch2.split(' ')[0]) ))
          setData(updatedList)
        }
      
       },[branch2])
       useEffect(()=>{
        if(refreshed && branch2.length>0){
          var updatedList = data;
          updatedList = updatedList.filter(detail =>(detail.store && detail.store==(branch2.split(' ')[0]) ))
          setData(updatedList)
        }
      
       },[refreshed])
      const deleteData = async () => {
        if(useremail=="4")
        {
  showToastMessage2();
        }
        else{
        handleModalShowHide1()
           const result = await axios({
             method:'put',
             url:`/api/item/${deleteid}/`,
             data:{isdeleted:"deleted",deletedby:username}
           })
           .then((Response)=>{
            showToastMessage()
             // setSuccess(true)
             // hidesuccess();
              refreshData()
                    
           })
           .catch((error) => {
            if (error.code="ERR_BAD_RESPONSE"){
             
                    }
            
           })
          }
          axios({
            method:'post',
            url:'/api/audit/',
            data:{username:username1,operation:'Product deleted',table:'Products',owner:useremail},
          })
          .then(res => {
             
          
          })
          .catch(err => {})
           }
           const showToastMessage2 = () => {
     
            toast.error('You dont have permission to perform this action', {
                position: toast.POSITION.TOP_CENTER
            });
          }; 
           const handleModalShowHide1=(id)=> {
            setDeleteId(id)
            setShowhide1(!showhide1);
            console.log("clicked");
          }
          const handleModalShowHide2=(id)=> {
            setDeleteId(id)
            setShowhide2(!showhide2);
            console.log("clicked");
          }
          const showToastMessage = () => {
            toast.success('Record deleted succesfully', {
                position: toast.POSITION.TOP_CENTER
            });
          }; 
          const readExcel = (file) => {
           
            try{
             return new Promise((resolve,reject) => {
               const fileReader = new FileReader();
               fileReader.readAsArrayBuffer(file);
               fileReader.onload = (e) => {
                   const bufferReader = e.target.result;
                   const wb = excel.read(bufferReader, { type: "binary" });
                   const wbSheetName = wb.SheetNames[0];
                   const ws = wb.Sheets[wbSheetName];
                   const data1 = excel.utils.sheet_to_json(ws);
                  setData2(data1);
                   resolve(data1);
                   const names = [];
                   const category = [];
                   const uom = [];
                   const bprice = [];
                   const sprice = [];
                   const quantity = [];
                   var address_of_cell = 'Name';
                   var address_of_cell1 = 'Category';
                   var address_of_cell2 = 'U.O.M';
                   var address_of_cell3 = 'Buying Price';
                   var address_of_cell4 = 'Selling Price';
                   var address_of_cell5 = 'Quantity';
                   const columnName = Object.keys(ws).find(key=> ws[key].v === address_of_cell);
                   const columnName1 = Object.keys(ws).find(key=> ws[key].v === address_of_cell1);
                   const columnName2 = Object.keys(ws).find(key=> ws[key].v === address_of_cell2);
                   const columnName3 = Object.keys(ws).find(key=> ws[key].v === address_of_cell3);
                   const columnName4 = Object.keys(ws).find(key=> ws[key].v === address_of_cell4);
                   const columnName5 = Object.keys(ws).find(key=> ws[key].v === address_of_cell5);

for (let key in ws) {
  if (key.toString()[0] === columnName[0]) {
   
    names.push(ws[key].v);
   
  }
  if (key.toString()[0] === columnName1[0]) {
   
    category.push(ws[key].v);
   
  }
  if (key.toString()[0] === columnName2[0]) {
   
    uom.push(ws[key].v);
   
  }
  if (key.toString()[0] === columnName3[0]) {
   
    bprice.push(ws[key].v);
   
  }
  if (key.toString()[0] === columnName4[0]) {
   
    sprice.push(ws[key].v);
   
  }
  if (key.toString()[0] === columnName5[0]) {
   
    quantity.push(ws[key].v);
   
  }
} setNames(names)
setCategory1(category)
setUom(uom)
setBprice1(bprice)
setSprice1(sprice)
setQuantity1(quantity)
                   
               };
               FileReader.onError = (error) => {
                 reject(error);
               };
             })
            }catch(error) {
               console.log(error)
            }  
         }
          const handleChange=(e)=>{
           // alert(fileInput.current.files[0].name)
            let fileName = fileInput.current.files[0].name;
let workbook = excel.readFile(fileName);
console.log(workbook) //s
          }
if(isAuthenticated=="false"){
 return <Redirect to='/login'/>}
 const closeModal2=()=> {
  setShowhide3(false);
}
const handleCategoryChange2 = (event) => {
  setBranch2(event.target.value) 
  closeModal2()
 // filterItems()
} 
 if(payment=="false"){
  return <Redirect to='/payment'/>}
  const renderList1=()=>{
    return (data3.map(data =>({label:data.item})))
  }
  const handleSelectChange1 = (value) => {
    
    setValueState1(value);
    if(value.label=="Stock at 0"){
      var updatedList = databkp;
      updatedList = updatedList.filter(detail =>(detail.quantity == 0))
      setData(updatedList)}
      if(value.label=="Stock below 5"){
        var updatedList = databkp;
        updatedList = updatedList.filter(detail =>(detail.quantity < 5))
        setData(updatedList)}
        if(value.label=="Stock below 10"){
          var updatedList = databkp;
          updatedList = updatedList.filter(detail =>(detail.quantity < 10))
          setData(updatedList)}
          if(value.label=="Stock below 20"){
            var updatedList = databkp;
            updatedList = updatedList.filter(detail =>(detail.quantity < 20))
            setData(updatedList)} 
    
  }
  return (
    <div>

        <ToastContainer
       hideProgressBar={true}
      />
       <div className='path'><i className='fa fa-home'></i>{edit? '/ Products / Edit Item':'/ Products / Products List'}</div>
        <div className='bodysection col-lg-7'>
        <div className=' col-lg-12 theader'>< h5>Products List</h5> </div>
        <div className='col-lg-2'>
       <Modal show={showhide2}className='col-lg-4 d-flex ml-auto mr-auto'>
           <Modal.Header closeButton onClick={handleModalShowHide2}>
           <Modal.Title>Import Products</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  {!saving &&'Records saved succesfully'}
{saving && 'Saving Records...'}
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
           <div>Upload Products Excel File Exported from the other Store
            <input type='file'  onChange={(e) => {
          const file = e.target.files[0];
          readExcel(file);
        }}
            ref={fileInput}
            />
        </div>
  {edit && <FormGroup className='mr-2 ml-2 '>


<div className={branchError ?'errmessage1':'errmessagehide1'}>Branch is Reguired</div>
</FormGroup>}

<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       value={dateposted}
       required
     
     />
</FormGroup>
   
           <Button color="primary"className="mt-2 " type="submit" onClick={(e)=> handleSubmit1(e)}>
Submit
</Button>  
<Button color="primary ml-2"className=" m-2 mt-3" type=""onClick={handleModalShowHide2}>
Close
</Button>
</Form>

</Modal.Body>

       </Modal>
       </div>
        <div className='col-lg-2'>
       <Modal show={showhide}className='col-lg-4 d-flex ml-auto mr-auto'>
           <Modal.Header closeButton onClick={closeModal}>
           <Modal.Title>Stock Reconciliation</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
           <FormGroup className='mr-2 ml-2 '>

<div style={{width:'220px'}}className={branchError &&'errorborder'} >
              
          <select onChange={handleCategoryChange} className={branchError ? 'errorborder newselect1':'newselect'}>
 
 <option>{edit?  category :'Select Stocktake Name'}</option>
 {category && category.map((option, index) => {
     return <option key={index} >
         {option.idfield + " "+option.name}
     </option>
 })}
</select>    
          
            </div>
<div className={branchError ?'errmessage1':'errmessagehide1'}>Branch is Reguired</div>
</FormGroup>
  {edit && <FormGroup className='mr-2 ml-2 '>

<div style={{width:'220px'}}className={branchError &&'errorborder'} >
              
          <select onChange={handleCategoryChange1} className={branchError ? 'errorborder newselect1':'newselect'}>
 
 <option>{edit?  category :'Select Branch'}</option>
 {category && branch.map((option, index) => {
     return <option key={index} >
         {option.name}
     </option>
 })}
</select>    
          
            </div>
<div className={branchError ?'errmessage1':'errmessagehide1'}>Branch is Reguired</div>
</FormGroup>}

<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       value={dateposted}
       required
     
     />
</FormGroup>
   
           <Button color="primary"className="mt-2 " type="submit" onClick={(e)=> handleSubmit(e)}>
Submit
</Button>  
<Button color="primary ml-2"className=" m-2 mt-3" type=""onClick={closeModal}>
Close
</Button>
</Form>

</Modal.Body>

       </Modal>
       </div>

 
           <div class=" bg-light p-1">
            <div ><span onClick={reLoadPage}><Link exact to={`/stocktake`} >{isadmin=="true"| stocktake=="true" ?<Button className='mt-1 ml-1'>Stock Take</Button>:""}</Link>
            </span>  {isadmin=="true"| reconcile=="true" ?<Button className='ml-1 mt-1'onClick={handleModalShowHide}>Reconcile Stock </Button>:""}
            {isadmin=="true"| aitem=="true" ?<Button className='ml-2 mt-1'onClick={handleModalShowHide2}>Import Products </Button>:""}
            </div>
           <div className='row ml-1'>
           {isadmin=="true" &&<div style={{width:'220px'}}className='my-2' >
<Select1
       value={valueState}
       defaultValue=""
       placeholder="Filter by Branch"
       options={renderList()}
       onChange={ (value) => handleSelectChange(value) }
       name="ColumnSelect"
       label=""
       isClearable
/>
</div>}
{<div style={{width:'230px'}}className='my-2' >
<FormGroup className='ml-1'>
<Select1
       value={valueState1}
       defaultValue=""
       placeholder="Filter by Low stock"
       options={renderList1()}
       onChange={ (value) => handleSelectChange1(value) }
       name="ColumnSelect"
       label=""
       isClearable
/>
</FormGroup>
</div>}</div>
<pre>{JSON.stringify(data2[0].itemname, null, 2)}</pre>
<ToolkitProvider
keyField="id"
data={[...data] }
columns={ columns }
exportCSV={{ onlyExportFiltered: true, exportAll: false }}
search
>
{
props => (
<div>
<ExportCSVButton { ...props.csvProps } className='btn btn-primary m-1'>Export </ExportCSVButton> 

<SearchBar { ...props.searchProps } />

<BootstrapTable keyField='id'
{...props.baseProps}
data={ data } 
columns={ columns } 
pagination={ paginationFactory(options) }className="pgnav"
wrapperClasses="table-responsive">  

</BootstrapTable>
</div> 
)
}
</ToolkitProvider>
</div>
           </div>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Product?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting Product Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={deleteData}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
       <div className='col-lg-2'>
       <Modal show={showhide3}className='col-lg-4 d-flex ml-auto mr-auto'>
           <Modal.Header closeButton onClick={closeModal2}>
           <Modal.Title>Select Store</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
        
  { <FormGroup className='mr-2 ml-2 '>

<div style={{width:'220px'}}className={branchError &&'errorborder'} >
              
          <select onChange={handleCategoryChange2} className={branchError ? 'errorborder newselect1':'newselect'}>
 
 <option>{'Select Branch Name'}</option>
 { data1.map((option, index) => {
     return <option key={index} >
         {option.id + ' '+ option.name}
     </option>
 })}
</select>    
          
            </div>
<div className={branchError ?'errmessage1':'errmessagehide1'}>Branch is Reguired</div>
</FormGroup>}

<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       value={dateposted}
       required
     
     />
</FormGroup>
   
</Form>

</Modal.Body>

       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username:state.auth.username1,
  isadmin:state.auth.isadmin,
  store:state.auth.store,
  eitem:state.auth.eitem,
  ditem:state.auth.ditem,
  stocktake:state.auth.stocktake,
  reconcile:state.auth.reconcile,
  username1:state.auth.username1,
  aitem:state.auth.aitem,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,fetchPayment})(Products)