import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import { ToastContainer, toast } from 'react-toastify';
 import './Newstaff.css'
 import Select1 from 'react-select'
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import  { encrypt , decrypt } from 'react-crypt-gsm';
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";
 
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
//import bcrypt from "bcrypt";
import axios from "axios";
import Select from 'react-dropdown-select'
import useKeyboardShortcut from 'use-keyboard-shortcut'
import bcrypt from 'bcryptjs'
import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation} from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import './Addstudent.css'

const Addstaff=({props,isAuthenticated,checkAuthenticated,load_user,removeInvoice,id,useremail,payment,match,username1,store})=> {
 
  const [salary,setSalary]=useState('');
  const [bank,setBank]=useState('');
  const [amountvisible,setAmountVisible]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [accountName,setAccountName]=useState('');
  const [showhide2,setShowhide2]=useState(false);
  const [kra, setKra] = useState("");
  const [nhif, setNhif] = useState("");
  const [nssf ,setNssf] = useState("");
  const [email,setEmail]=useState("");
  const [mobile,setMobile]=useState("");
  const [town,setTown]=useState("");
  const [id1,setId]=useState("");
  const [account,setAccount]=useState("");
  const [id2,setId2]=useState("");
  const [edit,setEdit]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [savebtn,setSavebtn]=useState(true);
  const [editbtn1,setEditbtn1]=useState(false);
  const [savebtn1,setSavebtn1]=useState(true);
  const [wait,setWait]=useState(false);
  const [wait1,setWait1]=useState(false);
  const [hide,setHide]=useState(true);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [dropopen,setDropOpen]=useState(false);
  const [dropdown,setDropDown]=useState("Unpaid");
  const [issuedate,setIssueDate]=useState("");
  const [duedate,setDueDate]=useState("");
  const [product,setProduct]=useState("");
  const [price,setPrice]=useState("");
  const [subtotal,setSubTotal]=useState("");
  const [search,setSearch]=useState("");
  const [searchcustomer,setSearchCustomer]=useState("");
  const [quantity,setQuantity]=useState("");
  const [index,setIndex]=useState("");
  const [dateentered,setDateEntered]=useState("");
  const [details,setDetails]=useState(['']);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [details2bkp,setDetails2bkp]=useState(['']);
  const [details1,setDetails1]=useState(['']);
  const [details2,setDetails2]=useState(['']);
  const [details3,setDetails3]=useState(['']);
  const [products,setProducts]=useState(['']);
  const [irecords,setIRecords]=useState([]);
  const [grandtotal,setGrandTotal]=useState('');
  const [grandtotal1,setGrandTotal1]=useState('');
  const [totalPartialAmount,setTotalPartialAmount]=useState('');
  const [invoiceNumber,setInvoiceNumber]=useState('');
  const [musyoki,setMusyoki]=useState('musyoki');
  const [inputList1,setInputList1]=useState([]);
  const [heading,setHeading]=useState(['Names', 'Mobile','Action']);
  const [inputList, setInputList] = useState([]);
  const [inputList2, setInputList2] = useState([]);
  const [payments, setPayments] = useState(['']);
  const [data2,setData2]=useState([""]);
  const inputRef = useRef(null);
  const [notFound,setNotFound]=useState(false);
  const [notInternet,setNoInternet]=useState(false);
  const [tscnumber,setTscNumber]=useState("");
  const [tscnumberError,setTscNumberError]=useState(false);
  const [idnumber,setIdNumber]=useState("");
  const [idnumberError,setIdNumberError]=useState(false);
  const [phonenumber,setPhoneNumber]=useState("");
  const [phonenumberError,setPhoneNumberError]=useState(false);
  const [firstname,setFirstName]=useState("");
  const [firstnameError,setFNameError]=useState(false);
  const [lastname,setLName]=useState("");
  const [lastnameError,setLnameError]=useState(false);
  const [emailError,setEmailError]=useState(false);
  const [county,setCounty]=useState("");
  const [countyError,setCountyError]=useState(false);
  const [townError,setTownError]=useState(false);
  const [street,setStreet]=useState("");
  const [subjects,setSubjects]=useState("");
  const [classes,setClasses]=useState("");
  const [response,setResponse]=useState("");
  const [streetError,setStreetError]=useState(false);
  const [validated,setValidated]=useState(false);
  const [success,setSuccess]=useState(false);
  const [result,setResult]=useState(false);
  const [genderError, setGenderError] = useState(false);
  const [gender, setGender] = useState("");
  const [selectedOptions4, setSelectedOptions4] = useState([])
  const [last_nameError, setLnameError2] = useState(false);
  const [inputStyle4, setInputStyle4] = useState( { })
  const [data, setData] = useState( { })
  const [valueState, setValueState] = useState('');
  const [options4, setOptions4] = useState( [
    { id: 1, country: "Male" },
    { id: 2, country: "Female" }, 
    
  ] )
  //const bcrypt = require('bcrypt');
  const onOptionChangeHandler3 = (event) => {
    setGender(event.target.value) 
  } 
  const handleSelectChange = (value) => {
    
    setValueState(value);
    
    if(value.length>0){
      console.log(value[0].label.split(',').shift())
    }
   
  }
  const renderList=()=>{
    return (data.map(data =>({label:data.mobile+',' + data.name })))
  }

  const showToastMessage = () => {
    toast.success('Record saved succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };   
  const showToastMessage1 = () => {
    toast.success('Record updated succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };   
  const showToastMessage2 = () => {

    toast.error('You dont have permission to perform this action', {
        position: toast.POSITION.TOP_CENTER
    });
  }; 
  const removeErrors=()=>{
    setTscNumberError(false)
    setPhoneNumberError(false)
    setIdNumberError(false)
    setFNameError(false)
    setLnameError(false)
    setCountyError(false)
    setTownError(false)
    setStreetError(false)
    setEmailError(false)
  }
  const validateFields=(e)=>{
    e.preventDefault()
    if(tscnumber && idnumber && phonenumber && firstname && lastname && email && town && county && street && !gender.trim().length==0 &&
    gender!=="Select Gender"){
      setValidated(true)}
    
    else{
      if(tscnumber.trim().length==0){
        setTscNumberError(true)
       
        }
        if(gender.trim().length==0){
          setGenderError(true)
          }
        if(phonenumber.trim().length==0){
          setPhoneNumberError(true)
         
          }
          if(idnumber.trim().length==0){
            setIdNumberError(true)
           
            }
            if(firstname.trim().length==0){
              setFNameError(true)
             
              }
              if(lastname.trim().length==0){
                setLnameError(true)
               
                }
                if(county.trim().length==0){
                  setCountyError(true)
                 
                  }
                  if(town.trim().length==0){
                    setTownError(true)
                   
                    }
                    if(street.trim().length==0){
                      setStreetError(true)
                     
                      }
                      if(email.trim().length==0){
                        setEmailError(true)
                       
                        }
    }
  }
  function hidesuccess() {
    return new Promise(resolve => {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    });
  }
  const clearFields=()=>{
    setTscNumber('')
    setPhoneNumber('')
    setIdNumber('')
    setFirstName('')
    setLName('')
    setCounty('')
    setTown('')
    setStreet('')
    setEmail('')
    setSubjects('')
    setClasses('')
  }
  
  useEffect(()=>{
//check if edit is true
const invoicenumber = /[^/]*$/.exec(window.location.href)[0];
 if(match.params.id>=0)
 {
  setResult(true)
  setSavebtn(false)
 }
     //fetch edit records when editing
     if(result){
     //fetch student records
     axios({
      method:'post',
      url:'/staffsearch/',
      data:{adm:id},
    })
    .then((Response)=>{
     const datareceived=Response.data;
     console.log(datareceived)
    //set fields
    setTscNumber(datareceived[0].tscnumber)
    
      })
     //set edit
     setEdit(true);
     setEditbtn(true)
    
       //set local invoice
       setInvoiceNumber(invoicenumber)
      
     }else{
      
      
     }

  },[])

  useEffect(()=>{
if(result){
 //fetch student records
 axios({
  method:'post',
  url:'/staffsearch/',
  data:{id:match.params.id},
})
.then((Response)=>{
 const datareceived=Response.data;
 console.log(datareceived)
//set fields
setTscNumber(datareceived[0].tscnumber)
setIdNumber(datareceived[0].idnumber)
setPhoneNumber(datareceived[0].phonenumber)
setFirstName(datareceived[0].firstname)
setLName(datareceived[0].lastname)
setCounty(datareceived[0].county)
setTown(datareceived[0].town)
setStreet(datareceived[0].street)
setEmail(datareceived[0].email)
setSubjects(datareceived[0].subjects)
setClasses(datareceived[0].classes)
setGender(datareceived[0].gender)


  })
 //set edit
 setEdit(true);
 setEditbtn(true)


}

  },[result])
  useEffect(()=>{
    //save 
if(validated){

//save staff
if(useremail=="")
{
showToastMessage2();
}
else{
if(edit)
{

const editData = async () => {

const result = await axios({
method:'put',
url:`/api/staff/${match.params.id}/`,
data:{tscnumber,idnumber,phonenumber,firstname:firstname.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),lastname:lastname.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),email:email.toLowerCase(),county:county.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),town:town.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),
  street:street.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),gender:gender,subjects,classes,salary,account,accountname:accountName,bank,kra,nssf,nhif,owner:useremail,editedby:username1},
})
.then((Response)=>{
if (Response.data){
//setSaveSuccess(true)
setWait(false)
removeErrors()
showToastMessage1()
//setSuccess(true)
setValidated(false)
//hidesuccess();

     }
})
.catch((error) => {
if (error.code="ERR_BAD_RESPONSE"){
setShowhide2(true)
setWait(false)
setNoInternet(true)
     }

})

}
editData()
axios({
  method:'post',
  url:'/api/audit/',
  data:{username:username1,operation:'Staff edited',table:'Employees',owner:useremail},
})
.then(res => {
   

})
.catch(err => {})
}else
{

  
const saveData = async () => {

const result = await axios({
method:'post',
url:'/api/staff/',
data:{tscnumber,idnumber,phonenumber,firstname:firstname.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),lastname:lastname.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),email:email.toLowerCase(),county:county.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),town:town.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),
  street:street.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),gender:gender,subjects,classes,salary,account,accountname:accountName,bank,kra,nssf,nhif,owner:useremail,postedby:username1},
})
.then((Response)=>{
if (Response.data){
 setSaveSuccess(true)
 setWait(false)
 setSavebtn(true)
 removeErrors()
 showToastMessage()
 //setSuccess(true)
 setValidated(false)
 //hidesuccess();
clearFields()
       }
})
.catch((error) => {
if (error.code="ERR_BAD_RESPONSE"){
 setShowhide2(true)
 setWait(false)
 setNoInternet(true)
       }

})

const unique_id = uuid();
const small_id = unique_id.slice(0,8);
const encryptedStr = encrypt(small_id);
 //check if user exists
 const result2 = await axios({
  method:'post',
  url:`/searchstaff/`,
  data:{username:idnumber,smallpas:small_id,password:encryptedStr.content,email:email,lastname:lastname,phone:phonenumber,schoolid:useremail,isstaff:"True",sales:"False",products:"False",purchases:"False",reports:"False",system:"False",users:"False",payroll:"False",contacts:"False",owner:useremail,store},

  })
  .then((Response)=>{
   
   })


}
saveData()
axios({
  method:'post',
  url:'/api/audit/',
  data:{username:username1,operation:'Staff inserted',table:'Employees',owner:useremail},
})
.then(res => {
   

})
.catch(err => {})
}
}
}
    
      },[validated]) 
      useEffect(()=>{
       
        if(Response.data=="false"){
       //save user details
       //generate password
       
       const unique_id = uuid();
       const small_id = unique_id.slice(0,8);
       const encryptedStr = encrypt(small_id);
       const dencryptedStr = decrypt(encryptedStr);
       const result1 = axios({
         method:'post',
         url:'/api/systemuser/',
         data:{username:idnumber,smallpas:small_id,password:encryptedStr.content,email:email,lastname:lastname,phone:phonenumber,schoolid:useremail,isstaff:"True",owner:useremail},
         })
         .then((Response)=>{
        
         })
         .catch((error) => {
        
         })
         //send the password through email
         const result3= axios({
           method:'post',
           url:'/sendpassword1/',
           data:{lastname:lastname,username:idnumber,password:small_id,email:email},
           })
           .then((Response)=>{
          
           })
           .catch((error) => {
          
           })
       
        }   
    
    
      },[response])
   if(payment=="false"){
    return <Redirect to='/payment'/>}
  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record {edit?"Updated":"Saved"} succesffully</div></div>
       <div className='path'><i className='fa fa-home'></i>{edit? '/ Staff / Edit Staff':'/ Payroll / Add Employee'}</div>
        <div className='bodysection col-lg-7  '>
        {saveSuccess || updateSuccess &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>operation succesfull...</span>}
       
<div className=' col-lg-12 theader'>< h5>Staff Details</h5> </div>
<Form >
  <div className='row pl-4'>
 
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
</FormGroup>}
<FormGroup className='mr-2 ml-2'>

<Input className={tscnumberError &&'errorborder'} type="text" placeholder="Emp. Number"value={tscnumber} onChange={e => setTscNumber(e.target.value)} name="email"  required/>
<div className={tscnumberError ?'errmessage1':'errmessagehide1'}>Emp. Number is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input className={idnumberError &&'errorborder'} type="number" placeholder="ID Number"name="author" value={idnumber} onChange={e => setIdNumber(e.target.value)} required />
<div className={idnumberError ?'errmessage1':'errmessagehide1'}>ID Number is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input  className={phonenumberError &&'errorborder'} type="number" placeholder="Phone Number"name="author" value={phonenumber} onChange={e => setPhoneNumber(e.target.value)} required />
<div className={phonenumberError ?'errmessage1':'errmessagehide1'}>Phone Number is Reguired</div>
</FormGroup>

 
 {amountvisible && <FormGroup className='mr-5 ml-3'>
 <Label>Amount</Label>
 <Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
 </FormGroup>}
 <FormGroup className='mr-2 ml-2'>
 
 <Input className={firstnameError &&'errorborder'} type="text" placeholder="First Name"value={firstname} onChange={e => setFirstName(e.target.value)} name="email"  required/>
 <div className={firstnameError ?'errmessage':'errmessagehide'}>First Name is Reguired</div>
 </FormGroup>
 <FormGroup className='mr-2 ml-2'>
 <Input className={lastnameError &&'errorborder'} type="text" placeholder="Last Name"name="author" value={lastname} onChange={e => setLName(e.target.value)} required />
 <div className={lastnameError ?'errmessage':'errmessagehide'}>Last Name is Reguired</div>
 </FormGroup>
 <FormGroup className='mr-2 ml-2'>
 <Input className={emailError &&'errorborder'} type="text" placeholder="Email"name="author" value={email} onChange={e => setEmail(e.target.value)} required />
 <div className={emailError ?'errmessage':'errmessagehide'}>Email is Reguired</div>
 </FormGroup>
 
   </div>
  <div className='col-lg-6 ml-2 studenthealth' >
 
</div>
 
 
   <div className='row pl-4'>
 
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
</FormGroup>}
<FormGroup className='mr-2 ml-2'>

<Input className={countyError &&'errorborder'} type="text" placeholder="County"value={county} onChange={e => setCounty(e.target.value)} name="email"  required/>
<div className={countyError ?'errmessage1':'errmessagehide1'}>County is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input className={townError &&'errorborder'} type="text" placeholder="Town"name="author" value={town} onChange={e => setTown(e.target.value)} required />
<div className={townError ?'errmessage1':'errmessagehide1'}>Town is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input className={streetError &&'errorborder'} type="text" placeholder="Street"name="author" value={street} onChange={e => setStreet(e.target.value)} required />
<div className={streetError ?'errmessage1':'errmessagehide1'}>Street is Reguired</div>
</FormGroup>

<FormGroup className='mr-2 ml-2 '>
<div style={{width:'205px'}}className={last_nameError &&'errorborder'} >
              
          <select onChange={onOptionChangeHandler3} className={genderError ? 'errorborder newselect1':'newselect'}>
 
 <option>{edit && gender.trim().length !== 0?  gender :'Select Gender'}</option>
 {options4.map((option, index) => {
     return <option key={index} >
         {option.country}
     </option>
 })}
</select>    
          
            </div>
<div className={genderError ?'errmessage1':'errmessagehide1'}>Gender is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>

<Input className={countyError &&''} type="text" placeholder="Basic Salary"value={salary} onChange={e => setSalary(e.target.value)} name="email"  required/>
<div className={townError ?'errmessage1':'errmessagehide1'}>.</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input className={townError &&''} type="text" placeholder="Bank name"name="author" value={bank} onChange={e => setBank(e.target.value)} required />
<div className={townError ?'errmessage1':'errmessagehide1'}>.</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input className={streetError &&''} type="text" placeholder="Account name"name="author" value={accountName} onChange={e => setAccountName(e.target.value)} required />
<div className={streetError ?'errmessage1':'errmessagehide1'}>.</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>

<Input className={countyError &&''} type="text" placeholder="Account number"value={account} onChange={e => setAccount(e.target.value)} name="email"  required/>
<div className={countyError ?'errmessage1':'errmessagehide1'}>.</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input className={townError &&''} type="text" placeholder="Kra Pin"name="author" value={kra} onChange={e => setKra(e.target.value)} required />
<div className={townError ?'errmessage1':'errmessagehide1'}>.</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input className={streetError &&''} type="text" placeholder="NSSF No."name="author" value={nssf} onChange={e => setNssf(e.target.value)} required />
<div className={streetError ?'errmessage1':'errmessagehide1'}>.</div>
</FormGroup>
<FormGroup className='mr-2 ml-2'>
<Input className={streetError &&''} type="text" placeholder="NHIF No."name="author" value={nhif} onChange={e => setNhif(e.target.value)} required />
<div className={streetError ?'errmessage1':'errmessagehide1'}>.</div>
</FormGroup>
  </div>
  <div className='pl-3 col-md-7 mt-2'>
  
 
  </div>

      <div className='row pl-4'>
  <Button color="primary"className=" ml-2 mb-3" type="submit"onClick={(e)=> validateFields(e)}>
      {editbtn && "Update"}
      {savebtn && "Submit"}
      {wait && " Please wait.."}
    </Button>  
    
    </div>
    </Form>
       
</div>

    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  store:state.auth.store,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Addstaff))