import React,{useEffect,useState} from 'react'
import axios from "axios";
import { Table } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Card,Modal} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import ToolkitProvider, { CSVExport,Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import TableHeaderColumn from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { NavLink,BrowserRouter as Router,Route,Switch,Link } from 'react-router-dom';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,faTrash} from '@fortawesome/free-solid-svg-icons'


function Stocktake({isAuthenticated,checkAuthenticated,useremail,username1,payment,fetchPayment,username,isadmin,store}) {
  const [data,setData]=useState([""]);
  const [name,setName]=useState("");
  const [nameError,setNameError]=useState(false);
  const [categoryValue, setCategoryValue]= useState('');
  const [price,setPrice]=useState("");
  const [dateposted,setDateposted]=useState("");
  const [quantity,setQuantity]=useState("");
  const [date,setDate]=useState("");
  const [branch,setBranch]=useState("");
  const [dateError,setDateError]=useState(false);
  const [branchError,setBranchError]=useState(false);
  const [category,setCategory]=useState("");
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [deleteid,setDeleteId]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [refreshed,setRefreshed]=useState(false);
  const [id,setId]=useState("");
  const [edit,setEdit]=useState(false);
  const [branch2,setBranch2]=useState([]);
  const [wait,setWait]=useState(false)
  const [validated,setValidated]=useState(false);
  const [savebtn,setSavebtn]=useState(true)
  const [editbtn,setEditbtn]=useState(false);
  const [error1,setError]=useState(false);
  const [showhide,setShowhide]=useState(false);
  const [heading,setHeading]=useState(['Name', 'Category', 'B. Price','S. Price','Quantity','Action']);
  const { ExportCSVButton } = CSVExport;
  const [showhide2,setShowhide2]=useState(false);
   const { SearchBar } = Search;
   const options = {
     page: 1,
     sizePerPage: 10,
     nextPageText: '>',
     prePageText: '<',
     showTotal: true
   };
 
   const columns = [
    {
      dataField: 'idfield',
      text: 'ID',
      headerStyle: () => {
        return { width: "150px" };
      }
    },
    {
     dataField: 'name',
     text: 'Name',
     headerStyle: () => {
       return { width: "150px" };
     }
   }, {
     dataField: 'date',
     text: 'Date',
     headerStyle: () => {
       return { width: "100px" };
     }
   } 
   ,  {
     dataField: "id",
     text: "Action",
     headerStyle: () => {
       return { width: "100px" };
     },
     csvExport: false,
     editable: false,
     formatter: (cellContent, row) => {
       return (
         <div>
       <div className='row '><div onClick={e =>{ setId(row.customerid);reLoadPage()}} className="border"><Link exact to={`/stocktake/${row.idfield}`}onClick={reLoadPage} >
                      <span className=''><i  class="fa fa-eye btn1"></i></span></Link></div></div>
       </div>
       );
     },
 },];
 const handleCategoryChange = (event) => {
    setBranch(event.target.value) 
  } 
  useEffect(()=>{
   
    checkAuthenticated();
    
    fetchPayment(useremail);
  //set name
  let datenow=new Date
  setName('Taken date '+datenow.toDateString())
    let data1 ;

    axios({
      method:'post',
      url:'/totalstocktake1/',
      data:{useremail:useremail,store,isadmin},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
        console.log(data1)  
    })
    .catch(err => {})
    /*  const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }*/
//fetch category
let data5 ;
axios({
 method:'post',
 url:'/totalcategory/',
 data:{useremail:useremail},
})
.then(res => {
   data5 = res.data;
   setCategory(data5)
   //setUnit(data5)
})
.catch(err => {})
setTimeout(() => {
      
  if(isadmin=="true"){
    handleModalShowHide2()
  }

}, 3000)

      },[]) 
      const handleModalShowHide2=()=> {
        setSaveSuccess(false)
          setShowhide2(true);
      }  
     const handleModalShowHide=()=> {
        setShowhide(!showhide);
    }
    const reLoadPage=()=>{
       
      window.location.reload();
  }
    const closeModal=()=> {
      setShowhide(false);
  }
  const refreshData=()=>{
    let data1 ;

    axios({
      method:'post',
      url:'/totalstocktake1/',
      data:{useremail:useremail,store,isadmin},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
        console.log(data1)  
        setRefreshed(true)
    })
    .catch(err => {})
    
  }
  const handledate = () => {
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return date;
  }
  const showToastMessage5 = () => {

    toast.error('Operation not allowed on admin account', {
        position: toast.POSITION.BOTTOM_CENTER
    });
  }; 
  const handleSubmit = (e) => {
 if(!name){
setError(true)
 }else
    if(id){
      e.preventDefault();
      axios({
        method:'put',
        url:`/api/product/${id}/`,
        data:{name:name.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),category:category.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),price,quantity,handledate},
      }).then((Response)=>{
        //clear fields
        setEdit(false);
        setId('');
    cleaFields();
      })
      setUpdateSuccess(true)
    }
    else{
      if(branch2.length==0 && isadmin=="true"){
        //closeModal()
         handleModalShowHide2()
           }else{
            
        //Save stock take
         //generate invoice id
       const unique_id = uuid();
       const small_id ="STT-"+ unique_id.slice(0,8).toUpperCase();
   axios({
     method:'post',
     url:'/savestocktake/',
     data:{branch:isadmin=="true"?branch2.split(' ')[0]:store,date,idfield:small_id,owner:useremail,handledate,store:isadmin=="true"?branch2.split(' ')[0]:store},
   }).then((Response)=>{
    //clear fields

   })
   axios({
    method:'post',
    url:'api/stocktake/',
    data:{name:name,date,idfield:small_id,owner:useremail,handledate,branch:isadmin=="true"? branch2.split(" ")[0]:store},
  }).then((Response)=>{
   //clear fields
showToastMessage()
   cleaFields();
  })
   setSaveSuccess(true);
   axios({
    method:'post',
    url:'/api/audit/',
    data:{username:username1,operation:'Stocktake inserted',table:'Stocktake',owner:useremail},
  })
  .then(res => {
     
  
  })
  .catch(err => {})
  }}
  };
  const editItem = (detail) => {
    setId(detail.id);
    setEdit(true);
    setShowhide(true);
    setName(detail.name);
    setCategory(detail.category);
    setPrice(detail.price);
    setQuantity(detail.quantity);
  };
  const handleDelete = (item) => {
    if(window.confirm('Are You Sure You want delete this record?')){
    console.log(item);
    axios({
      method:'delete',
      url:`/api/item/${item.id}/`,
    }).then((Response)=>{
      
      
      alert("record deleted succesfully");
      refreshData();
    })
  }
  };
  const closeModal1=()=> {
    setShowhide1(false);
   
    }
  const cleaFields=()=>{
    setName('');
    setCategory('');
    setPrice('');
    setQuantity('');
    refreshData();
  }
  useEffect(()=>{
    axios({
      method:'post',
      url:'/totalstore/',
      data:{useremail:useremail},
     })
     .then(res => {
       
        setBranch(res.data)
        //setUnit(data5)
     })
     .catch(err => {})
    
      },[]) 
      useEffect(()=>{
        if(branch2){
          var updatedList = data;
          updatedList = updatedList.filter(detail =>(detail.branch && detail.branch==(branch2.split(' ')[0]) ))
          setData(updatedList)
        }
      
       },[branch2])
       useEffect(()=>{
        if(refreshed && branch2.length>0){
          var updatedList = data;
          updatedList = updatedList.filter(detail =>(detail.branch && detail.branch==(branch2.split(' ')[0]) ))
          setData(updatedList)
        }
      
       },[refreshed])
      const deleteData = async () => {
        if(useremail=="4")
        {
  showToastMessage2();
        }
        else{
        handleModalShowHide1()
           const result = await axios({
             method:'put',
             url:`/api/item/${deleteid}/`,
             data:{isdeleted:"deleted",deletedby:username}
           })
           .then((Response)=>{
            showToastMessage()
             // setSuccess(true)
             // hidesuccess();
              refreshData()
                    
           })
           .catch((error) => {
            if (error.code="ERR_BAD_RESPONSE"){
             
                    }
            
           })
          }
           }
           const showToastMessage2 = () => {
     
            toast.error('You dont have permission to perform this action', {
                position: toast.POSITION.TOP_CENTER
            });
          }; 
           const handleModalShowHide1=(id)=> {
            setDeleteId(id)
            setShowhide1(!showhide1);
            console.log("clicked");
          }
          const showToastMessage = () => {
            toast.success('Record Saved succesfully', {
                position: toast.POSITION.TOP_CENTER
            });
          }; 
if(isAuthenticated=="false"){
 return <Redirect to='/login'/>}
 const validateFields=(e)=>{
    e.preventDefault()
    if(name && date){
       
        handleSubmit()
      setValidated(true)}
    
    else{
      if(!name){
        setNameError(true)
       
        }
        if(!date){
            setDateError(true)
           
            }
       /* if(branch.trim().length==0 | branch=="Select Branch"){
          setBranchError(true)
          }*/
}}
 if(payment=="false"){
  return <Redirect to='/payment'/>}
  const closeModal2=()=> {
    setShowhide2(false);
  }
  const handleCategoryChange1 = (event) => {
    setBranch2(event.target.value) 
    closeModal2()
   // filterItems()
  } 
  return (
    <div>
        <ToastContainer
       hideProgressBar={true}
      />
       <div className='path'><i className='fa fa-home'></i>{edit? '/ Products / Edit Item':'/ Products / Stocktake List'}</div>
        <div className='bodysection col-lg-6'>
      
       <Modal show={showhide}>
           <Modal.Header closeButton onClick={closeModal}>
           <Modal.Title> New Product details</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
<FormGroup>

<Label>Name</Label>
<Input type="text" placeholder="Enter name"name="title" onChange={e => setName(e.target.value)} value={name} required/>
</FormGroup>

<FormGroup>
<Label>Branch</Label>
<Input type="text" placeholder="Enter Category"value={branch} onChange={e => setCategory(e.target.value)} name="photo"  required/>

</FormGroup>
<FormGroup>
<Label>d</Label>
<Input type="number" placeholder="Enter price"name="author" value={price} onChange={e => setPrice(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Quantity.</Label>
<Input type="number" placeholder="Enter price"name="author" value={quantity} onChange={e => setQuantity(e.target.value)} required />
</FormGroup>
<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       value={dateposted}
       required
     
     />
</FormGroup>
   
           <Button color="primary"className="mt-2 mb-2 " type="submit" onClick={(e)=> handleSubmit(e)}>
Submit
</Button>  
<Button color="primary ml-2"className=" m-2 mt-3" type=""onClick={closeModal}>
Close
</Button>
</Form>

</Modal.Body>

       </Modal>
<div className=' col-lg-12 theader'>< h5>Stocktake List</h5> </div>
 
           <div class=" bg-light ">
           <Form >
  <div className='row pl-3'>
 

<FormGroup className='mr-2 ml-2'>
<Label>Name</Label>
<Input className={nameError &&'errorborder'} type="text" placeholder="Item name"value={name} onChange={e => setName(e.target.value)} name="email"  required/>
<div className={nameError ?'errmessage1':'errmessagehide1'}>Item Name is Reguired</div>
</FormGroup>
{edit && <FormGroup className='mr-2 ml-2 '>
<Label>Branch</Label>
<div style={{width:'220px'}}className={branchError &&'errorborder'} >
              
          <select onChange={handleCategoryChange} className={branchError ? 'errorborder newselect1':'newselect'}>
 
 <option>{edit?  category :'Select Branch'}</option>
 {category && category.map((option, index) => {
     return <option key={index} >
         {option.name}
     </option>
 })}
</select>    
          
            </div>
<div className={branchError ?'errmessage1':'errmessagehide1'}>Branch is Reguired</div>
</FormGroup>}
<FormGroup className='mr-2 ml-2'>
<Label>Date</Label>
<Input className={nameError &&'errorborder'} type="date" placeholder="Item name"value={date} onChange={e => setDate(e.target.value)} name="email"  required/>
<div className={dateError ?'errmessage1':'errmessagehide1'}>Date is Reguired</div>
</FormGroup>
 
   </div>
  <div className='col-lg-6 ml-2 studenthealth' >
 
</div>
 
      <div className='row pl-3 mb-3'>
  <Button className=" ml-2  mt-2" type="submit"onClick={(e)=> validateFields(e)}>
      {editbtn && "Update"}
      {savebtn && !editbtn && "Submit"}
      {wait && " Please wait.."}
    </Button>  
    
    </div>
    </Form>
<ToolkitProvider
keyField="id"
data={[...data] }
columns={ columns }
exportCSV={{ onlyExportFiltered: true, exportAll: false }}
search
>
{
props => (
<div>
<ExportCSVButton { ...props.csvProps } className='btn btn-primary m-1'>Export </ExportCSVButton> 

<SearchBar { ...props.searchProps } />

<BootstrapTable keyField='id'
{...props.baseProps}
data={ data } 
columns={ columns } 
pagination={ paginationFactory(options) }className="pgnav"
wrapperClasses="table-responsive">  

</BootstrapTable>
</div> 
)
}
</ToolkitProvider>
</div>
           </div>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Product?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting Product Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={deleteData}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
       <div className='col-lg-2'>
       <Modal show={showhide2}className='col-lg-4 d-flex ml-auto mr-auto'>
           <Modal.Header closeButton onClick={closeModal2}>
           <Modal.Title>Select Store</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
        
  { <FormGroup className='mr-2 ml-2 '>

<div style={{width:'220px'}}className={branchError &&'errorborder'} >
              
          <select onChange={handleCategoryChange1} className={branchError ? 'errorborder newselect1':'newselect'}>
 
 <option>{'Select Branch Name'}</option>
 { branch.length>0 && branch.map((option, index) => {
     return <option key={index} >
         {option.id + ' '+ option.name}
     </option>
 })}
</select>    
          
            </div>
<div className={branchError ?'errmessage1':'errmessagehide1'}>Branch is Reguired</div>
</FormGroup>}

<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       value={dateposted}
       required
     
     />
</FormGroup>
   
</Form>

</Modal.Body>

       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username:state.auth.username1,
  store:state.auth.store,
  isadmin:state.auth.isadmin,
  username1:state.auth.username1,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,fetchPayment})(Stocktake)