import React,{useEffect,useState,useRef} from 'react'
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './Vinvoice.css'
import html2canvas from "html2canvas";
import { ToastContainer, toast } from 'react-toastify';
import { TailSpin} from  'react-loader-spinner'
import 'react-toastify/dist/ReactToastify.css';
import ToolkitProvider, { CSVExport,Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import TableHeaderColumn from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import axios from "axios";
import {connect} from 'react-redux';
import { setId} from './../actions/auth';
import { Table } from 'react-bootstrap';
import { NavLink,BrowserRouter as Router,Route,Switch,Link } from 'react-router-dom';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import { Card,Modal} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,faTrash,faEye} from '@fortawesome/free-solid-svg-icons'
import Select1 from 'react-select'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Cinvoice from './Cinvoice';
import jsPDF from "jspdf";
import "jspdf-autotable";

const Salesreport=({isAuthenticated,checkAuthenticated,setId,useremail,payment,fetchPayment,isadmin,store})=> {
  const [dropdown,setDropDown]=useState("");
  const [loading,setLoading]=useState(false);
  const [notFound,setNotFound]=useState(false);
  const [successicon,setSuccessIcon]=useState(false);
  const [nosms,SetNoSms]=useState(false);
  const [emailSuccess,setEmailSuccess]=useState(false);
  const [messageSuccess,setMessageSuccess]=useState(false);
  const [message,setMessage]=useState(false);
  const [loadspinner,setLoadspinner]=useState(false);
  const [total,setTotal]=useState("");
  const [search,setSearch]=useState("");
  const [deleteid,setDeleteId]=useState("");
  const [details,setDetails]=useState([""]);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [data,setData]=useState([""]);
  const [data2,setData2]=useState([""]);
  const [customer,setCustomer]=useState([""]);
  const [data1,setData1]=useState([""]);
  const [invoices,setInvoices]=useState("");
  const [fromDate,setFromDate]=useState("");
  const [toDate,setToDate]=useState("");
  const [fromDateE,setFromDateE]=useState(false);
  const [toDateE,setToDateE]=useState(false);
  const [showhide1,setShowhide1]=useState(false);
  const inputRef = useRef(null);
  const [success,setSuccess]=useState(false);
  const [notreachable,setNotReachable]=useState(false);
  const [grandtotal,setGrandTotal]=useState('');
  const [valueState, setValueState] = useState('');
  const [valueState1, setValueState1] = useState('');
  const [dataReceived,setDataReceived]=useState([]);
  const [heading,setHeading]=useState(['Date','Product','Qty','Amount']);
  const [percentage, setPercentage] = useState(0);
  const { ExportCSVButton } = CSVExport;
   const { SearchBar } = Search;
   const options = {
     page: 1,
     sizePerPage: 10,
     nextPageText: '>',
     prePageText: '<',
     showTotal: true
   };
   const renderList=()=>{
    return (data1.map(data =>({label:data.id+' ' + data.name })))
  }
  const renderList1=()=>{
    return (data2.map(data =>({label:data.id+' ' + data.lastname })))
  }
  const handleSelectChange = (value) => {
    
    setValueState(value);
    if(value){
      var updatedList = detailsbkp;
      updatedList = updatedList.filter(detail =>(detail.store && detail.store.includes(value.label.split(' ')[0]) ))
      setData(updatedList)}
  }
  const handleSelectChange1 = (value) => {
    
    setValueState1(value);
    if(value){
      var updatedList = detailsbkp;
      updatedList = updatedList.filter(detail =>(detail.postedby && detail.postedby.includes(value.label.split(' ')[1]) ))
      setData(updatedList)}
  }
   const columns = [ 
    {
      dataField: "id",
      text: "Date",
      headerStyle: () => {
        return { width: "100px" };
      },
      csvExport: true,
      editable: false,
      formatter: (cellContent, row) => {
        return (
          <div>
          {convertDate(row.created_at) }
          </div>
        );
      },
  }, {
     dataField: 'product',
     text: 'Product',
     headerStyle: () => {
       return { width: "180px" };
     }
   } 
   , {
    dataField: 'quantity',
    text: 'Quantity',
    headerStyle: () => {
      return { width: "100px" };
    }
  }
 , {
     dataField: "id",
     text: "Amount",
     headerStyle: () => {
       return { width: "100px" };
     },
     csvExport: true,
     editable: false,
     formatter: (cellContent, row) => {
       return (
         <div>
          { row.amount*row.quantity}
      </div>
       );
     },
 },];
  const showToastMessage = () => {
    toast.success('Record deleted succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };  
 
  useEffect(()=>{
    
    //fetch total sales
    
   fetchAllSales()
  /*  const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }*/
    let data7
    axios({
      method:'post',
      url:'/totalstore/',
      data:{useremail:useremail},
     })
     .then(res => {
        data7 = res.data;
        //setCategory(data5)
        setData1(data7)
     })
     .catch(err => {})

     axios({
      method:'post',
      url:'/totalusers/',
      data:{owner:useremail,isadmin},
     })
     .then(res => {
        data7 = res.data;
        //setCategory(data5)
        setData2(data7)
     })
     .catch(err => {})
     },[])
     useEffect(()=>{
    getTotal()
     
       },[data])  
     const fetchAllSales=()=>{
      
      let data4 ;
      axios({
       method:'post',
       url:'/totalsales/',
       data:{useremail:useremail,isadmin,store},
     })
     .then(res => {
         data4 = res.data;
         setData(data4);
         console.log(data4) 
         setDetailsbkp(data4);
         setLoading(false)
     })
     .catch(err => {})}

     const reLoadPage=()=>{
       
      window.location.reload();
  }

  function hidesuccess() {
    return new Promise(resolve => {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    });
  }
  const filterList = (event) => {
    //search staff
  
    var updatedList = detailsbkp;
    updatedList = updatedList.filter(detail => detail.name.toLowerCase().includes(search.toLowerCase()) )
    setData(updatedList)
    
    };
  const closeModal1=()=> {
    setShowhide1(false);
    setNotFound(false)
    setMessage(false)
    setMessageSuccess(false)
    setEmailSuccess(false)
    setSuccessIcon(false)
    SetNoSms(false)
    setNotReachable(false)
    }
    const showToastMessage2 = () => {
     
      toast.error('You dont have permission to perform this action', {
          position: toast.POSITION.TOP_CENTER
      });
    }; 
    const deleteData = async () => {
      if(useremail=="4")
      {
showToastMessage2();
      }
      else{
      handleModalShowHide1()
         const result = await axios({
           method:'put',
           url:`/api/sales1/${deleteid}/`,
           data:{isdeleted:"deleted"}
         })
         .then((Response)=>{
          showToastMessage()
           // setSuccess(true)
           // hidesuccess();
            fetchAllSales()
                  
         })
         .catch((error) => {
          if (error.code="ERR_BAD_RESPONSE"){
           
                  }
          
         })
        }
         }

         const handleModalShowHide1=(id)=> {
          setDeleteId(id)
          setShowhide1(!showhide1);
          console.log("clicked");
        }
   
if(isAuthenticated=="false"){
  
  /*return <Redirect to='/login'/>*/}
  if(payment=="false"){
  
   return <Redirect to='/payment'/>}
   
    const value = 0.66;
    const convertDate=(date1)=>{
      if(data.length>1){
        const dateString = date1;
        const indexOfT = dateString.indexOf('T');
        const dateWithoutTime = dateString.substring(0, indexOfT);
        return dateWithoutTime;
      }
     
    }
    const Print = () =>{  
      html2canvas(inputRef.current).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "JPEG", 0, 0);
        pdf.save('Salesreport'+".pdf");
        var blob = pdf.output("blob");
        window.open(URL.createObjectURL(blob));
      });
    }
    const printDocument=()=>{
      //alert(toDate)
      const data = document.getElementById('printtable');
html2canvas(data).then((canvas:any) => {
  const imgWidth = 108;
  const pageHeight = 295;
  const imgHeight = (canvas.height * imgWidth) / canvas.width;
  let heightLeft = imgHeight;
  let position = 0;
  heightLeft -= pageHeight;
  const doc = new jsPDF('p', 'mm');
  doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    doc.addPage();
    doc.addImage(canvas, 'PNG', 0, position, imgWidth, imgHeight, '', 'FAST');
    heightLeft -= pageHeight;
  }
  doc.save('Salesreport.pdf');
        })
      ;
    }
    const filterData=()=>{
      //validate fileds
      if(!fromDate){
setFromDateE(true)
      }else if(!toDate){
setToDateE(true)
setFromDateE(false)
      }else
      //Orders.objects.filter(added_on__range=[startDate, endDate])
      {let data4 ;
      axios({
       method:'post',
       url:'/filtersales/',
       data:{useremail:useremail,fromdate:fromDate,todate:toDate,isadmin,store},
     })
     .then(res => {
         data4 = res.data;
         setData(data4);
         console.log(data4) 
         setDetailsbkp(data4);
         setLoading(false)
         setToDateE(false)
     })
     .catch(err => {}) }
    }
    const getTotal=()=>{
  
      const res=data.reduce((prev,item)=>{
        
          return prev +(item.amount*item.quantity);
      },0)
      setGrandTotal(res)
     
      }
  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record Deleted succesffully</div></div>
       <div className='path'><i className='fa fa-home'></i>/ Sales / View Sales</div>
      <Router>
        <div className='bodysection col-lg-6'>
  <div className=' col-lg-12 theader'>< h5>Sales List</h5> </div><div className='row p-2'>
<FormGroup className='mr-5 ml-3'>
From Date:<Input type="date" placeholder="Search..."name="town" value={fromDate} onChange={e =>{ setFromDate(e.target.value)}}required />
<div className={fromDateE ?'errmessage3':'errmessagehide3'}>From Date is Reguired</div>      
</FormGroup>
<FormGroup className='mr-5 ml-3'>
To Date:<Input type="date" placeholder="Search..."name="town" value={toDate} onChange={e =>{ setToDate(e.target.value)}} required />
<div className={toDateE ?'errmessage3':'errmessagehide3'}>To Date is Reguired</div>       
</FormGroup>
<Button className='ml-3 mb-1 btn-primary fixwidth1'onClick={filterData}>Filter</Button>

{isadmin=="true" &&<div style={{width:'220px'}}className=' mx-3 mb-2 mt-1  solo' >
<Select1
       value={valueState}
       defaultValue=""
       placeholder="Filter by Branch"
       options={renderList()}
       onChange={ (value) => handleSelectChange(value) }
       name="ColumnSelect"
       label=""
       isClearable
/>
</div>}
{isadmin=="true" &&<div style={{width:'220px'}}className=' mx-3 mt-0 solo mb-1' >
<Select1
       value={valueState1}
       defaultValue=""
       placeholder="Filter by Teller"
       options={renderList1()}
       onChange={ (value2) => handleSelectChange1(value2) }
       name="ColumnSelect"
       label=""
       isClearable
/>
</div>}
</div>

           <div class=" bg-light p-1">

<ToolkitProvider
keyField="id"
data={[...data] }
columns={ columns }
exportCSV={{ onlyExportFiltered: true, exportAll: false }}
search
>
{
props => (
<div>
<ExportCSVButton { ...props.csvProps } className='btn btn-primary m-1'>Export </ExportCSVButton> 

<SearchBar { ...props.searchProps } />

<BootstrapTable keyField='id'
{...props.baseProps}
data={ data } 
columns={ columns } 
pagination={ paginationFactory(options) }className="pgnav"
wrapperClasses="table-responsive">  

</BootstrapTable>
</div> 
)
}
</ToolkitProvider>
<b>Total Sales: Kshs {grandtotal}</b>
</div>
           </div>

           <Switch>
           <Route path='/cinvoice' component={Cinvoice}/>
          </Switch>
           </Router>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Sales?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting a Sales Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={deleteData}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  isadmin:state.auth.isadmin,
  store:state.auth.store,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,setId,fetchPayment})(Salesreport)