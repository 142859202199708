
import './Footer.css';
import { NavLink,BrowserRouter as Router,Route,Switch,Link,Redirect} from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import {connect} from 'react-redux';
import { logout } from './../actions/auth';
import React,{useEffect,useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faBuilding,faTimes,faBars,faSignOut,faUser,faUserAlt,faUserCircle} from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
function Footer({click,handleClick,adminMenuClick1,adminMenu,logout,useremail,fetchedUser,isAuthenticated,user1}) {
    const [names,setName]=useState("");
    const [ft,setFt]=useState(false);
    const [date1, setDate] = useState(new Date().toLocaleTimeString('en-US'));
    useEffect(()=>{   
        if(window.location.pathname == '/pos1'| window.location.pathname == '/login'){
            setFt(true)
        }else{
            setFt(false)
        }
        //document.title = date;
        const timerID = setInterval(() => tick(), 1000);
        return () => {
          clearInterval(timerID);
        };
      },[]) 
      const reLoadPage=()=>{
       
        window.location.reload();
    }

    if(!isAuthenticated){
  
       /* <Router>
       return <Redirect to='/login'/>
    </Router>*/}
    const Capitalize=(str)=>{
        return str.charAt(0).toUpperCase() + str.slice(1);
        }
        const tick = () => {
            setDate(new Date().toLocaleTimeString('en-US'));
          };
    return (
        <nav className={ft?'footer1':'footer'}>
          
       {window.location.pathname == '/pos1' && <div className="text-color" onClick={handleClick}>
       
       {ft? " Cashier Name: " + Capitalize(user1):""}
       <span className='ml-5'></span> {ft?date1:""}
        </div>}
        
        <div className={window.location.pathname == '/pos1'?"navbar_rightnew1":"navbar_rightnew"}onClick={adminMenuClick1} >
       
            SEJOPOS Version 1.8 | Copyright  &copy; 2024 All Rights Reserved
        
      
       
        </div>
        <div className={adminMenu ?'adminmenu ':'adminactive'}>
           <ul>
               <li className="newbtn"onClick={reLoadPage}><Link exact to={`/account/`} ><FontAwesomeIcon icon={faUserCircle}className="mr-2" />Account</Link></li>
               <li onClick={e =>{logout()}} className="newbtn"><FontAwesomeIcon icon={faSignOut}className="mr-2" />Logout</li>
           </ul>
       </div>
      
        </nav>
    )
}
const mapStateToProps=state=>({
    useremail:state.auth.useremail,
    user1:state.auth.user1,
    isAuthenticated:state.auth.isAuthenticated,
    fetchedUser:state.auth.fetchedUser
    });

export default connect(mapStateToProps,{logout})(Footer)
