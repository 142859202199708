import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import { ToastContainer, toast } from 'react-toastify';
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
 
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";
 
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import axios from "axios";
import useKeyboardShortcut from 'use-keyboard-shortcut'

import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation,useParams } from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import Select1 from 'react-select'
import './Addclass.css'
import Barcode from 'react-barcode'
import html2canvas from 'html2canvas'
const Addfee=({props,isAuthenticated,checkAuthenticated,load_user,isadmin,store,removeInvoice,id,useremail,payment,match,username1})=> {
 
  const [showhide,setShowhide]=useState(false);
  const [customerFetch,setCustomerFetch]=useState(false);
  const [amountvisible,setAmountVisible]=useState(false);
  const [valueState, setValueState] = useState('');
  const [partialamount,setPartialAmount]=useState('');
  const wrapper_ref = React.useRef();
  const [showhide1,setShowhide1]=useState(false);
  const [showhide2,setShowhide2]=useState(false);
  const [names, setName] = useState("");
  const [filteredinvoice, setFilteredInvoice] = useState("");
  const [name, setNames] = useState("");
  const [email,setEmail]=useState("");
  const [mobile,setMobile]=useState("");
  const [town,setTown]=useState("");
  const [id1,setId]=useState("");
  const [inumber,setInumber]=useState("hello");
  const [id2,setId2]=useState("");
  const [edit,setEdit]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [savebtn,setSavebtn]=useState(true);
  const [editbtn1,setEditbtn1]=useState(false);
  const [savebtn1,setSavebtn1]=useState(false);
  const [wait,setWait]=useState(false);
  const [wait1,setWait1]=useState(false);
  const [hide,setHide]=useState(true);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [data,setData]=useState([""]);
  const [dropopen,setDropOpen]=useState(false);
  const [dropdown,setDropDown]=useState("Unpaid");
  const [issuedate,setIssueDate]=useState("");
  const [duedate,setDueDate]=useState("");
  const [product,setProduct]=useState("");
  const [price,setPrice]=useState("");
  const [subtotal,setSubTotal]=useState("");
  const [search,setSearch]=useState("");
  const [searchcustomer,setSearchCustomer]=useState("");
  const [quantity,setQuantity]=useState("");
  const [index,setIndex]=useState("");
  const [dateentered,setDateEntered]=useState("");
  const [details,setDetails]=useState(['']);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [details2bkp,setDetails2bkp]=useState(['']);
  const [details1,setDetails1]=useState(['']);
  const [details2,setDetails2]=useState(['']);
  const [details3,setDetails3]=useState(['']);
  const [products,setProducts]=useState(['']);
  const [irecords,setIRecords]=useState([]);
  const [grandtotal,setGrandTotal]=useState('');
  const [grandtotal1,setGrandTotal1]=useState('');
  const [totalPartialAmount,setTotalPartialAmount]=useState('');
  const [invoiceNumber,setInvoiceNumber]=useState('');
  const [musyoki,setMusyoki]=useState('musyoki');
  const [inputList1,setInputList1]=useState([]);
  const [heading,setHeading]=useState(['Names', 'Mobile','Action']);
  const [inputList, setInputList] = useState([]);
  const [inputList2, setInputList2] = useState([]);
  const [payments, setPayments] = useState(['']);
  const [data2,setData2]=useState([""]);
  const inputRef = useRef(null);
  const [notFound,setNotFound]=useState(false);
  const [notInternet,setNoInternet]=useState(false);
  const [feetitle,setFtitle]=useState("");
  const [feetitleError,setFtitleError]=useState(false);
  const [amount,setAmount]=useState("");
  const [amountError,setAError]=useState(false);
  const [validated,setValidated]=useState(false);
  const [success,setSuccess]=useState(false);
  const [result,setResult]=useState(false);
  const showToastMessage = () => {

    toast.success('Record saved succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };
  const showToastMessage1 = () => {

    toast.success('Record updated succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };
  const showToastMessage2 = () => {

    toast.error('You dont have permission to perform this action', {
        position: toast.POSITION.TOP_CENTER
    });
  }; 

  useEffect(()=>{
    //check if edit is true
    const invoicenumber = /[^/]*$/.exec(window.location.href)[0];
     if(match.params.id>=0)
     {
      setResult(true)
      setSavebtn(false)
     }
         //fetch edit records when editing
         if(result){
         //fetch class records
         axios({
          method:'post',
          url:'/feetypetotal/',
          data:{adm:id},
        })
        .then((Response)=>{
         const datareceived=Response.data;
         console.log(datareceived)
       
          })
         //set edit
         setEdit(true);
         setEditbtn(true)
        
           //set local invoice
           setInvoiceNumber(invoicenumber)
          
         }else{
          
          
         }
    
         axios({
          method:'post',
          url:'/totalproducts/',
          data:{useremail:useremail,isadmin,store},
        })
        .then(res => {
             
            setData(res.data)
          
        })
        .catch(err => {}) 
      },[])

      useEffect(()=>{
        if(result){
         //fetch class records
         axios({
          method:'post',
          url:`/feetypesearch/`,
          data:{id:match.params.id,useremail:useremail}
        })
        .then((Response)=>{
         const datareceived=Response.data;
         console.log(datareceived)
        //set fields
        setFtitle(datareceived[0].feetitle)
        setAmount(datareceived[0].amount)
        
          })
         //set edit
         setEdit(true);
         setEditbtn(true)
        
        
        }
        
          },[result])
          useEffect(()=>{
            
        if(validated){
        
        //save fee
        if(useremail=="4")
          {
  showToastMessage2();
          }
          else{
        if(edit)
        {
        
        const editData = async () => {
        
        const result = await axios({
        method:'put',
        url:`/api/feetype/${match.params.id}/`,
        data:{feetitle:feetitle.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),amount,editedby:username1},
        })
        .then((Response)=>{
        if (Response.data){
       // setSaveSuccess(true)
        setWait(false)
        removeErrors()
        //showToastMessage1()
        //setSuccess(true)
        setValidated(false)
       // hidesuccess();
        
             }
        })
        .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
        setShowhide2(true)
        setWait(false)
        setNoInternet(true)
             }
        
        })
        
        }
        editData()
        }else
        {
        const saveData = async () => {
        
        const result = await axios({
        method:'post',
        url:'/api/feetype/',
        data:{feetitle:feetitle.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),amount,owner:useremail,postedby:username1},
        })
        .then((Response)=>{
        if (Response.data){
         setSaveSuccess(true)
         setWait(false)
         setSavebtn(true)
         removeErrors()
        // showToastMessage()
         //setSuccess(true)
         setValidated(false)
        // hidesuccess();
        clearFields()
               }
        })
        .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
         setShowhide2(true)
         setWait(false)
         setNoInternet(true)
               }
        
        })
        
        }
        saveData()
        }
      }
        }
            
              },[validated]) 
const removeErrors=()=>{
  setFtitleError(false)
  setAError(false)
}
  const validateFields=(e)=>{
    e.preventDefault()
    if(valueState && amount ){
      setValidated(true)
    onClick()
    }
    
    else{
      if(!valueState){
        setFtitleError(true)
       
        }
        if(amount.trim().length==0){
          setAError(true)
         
          }
         
    }}

    function hidesuccess() {
      return new Promise(resolve => {
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      });
    }
    const renderList=()=>{
      return (data.map(data =>({label:data.itemcode+',' + data.itemname+', Kshs ' + data.sprice })))
    }
    const handleSelectChange = (value) => {
    
      setValueState(value);
      
      if(value.length>0){
        console.log(value[0].label.split(',').shift())
      }
     
    }
    const clearFields=()=>{
setFtitle('')
setAmount('')

    }
   if(payment=="false"){
    return <Redirect to='/payment'/>}
    const onClick=(e)=>{
      const opt = {
         scale: 4
     }
     const elem = wrapper_ref.current;
     html2canvas(elem, opt).then(canvas => {
         const iframe = document.createElement('iframe')
         iframe.name = 'printf'
         iframe.id = 'printf'
         iframe.height = 0;
         iframe.width = 0;
         document.body.appendChild(iframe)
 
         const imgUrl = canvas.toDataURL({
             format: 'pdf',
             quality: '5.0'
         })
 
         const style=`
             height:;
             width:30vw;
             position:relative;
             left:0:
             top:0;
            
         `;
 
         const url = `<img style="${style}" src="${imgUrl}"/>`;
         var newWin = window.frames["printf"];
         for(let i=0; i<amount; i++){
          newWin.document.write(`<body onload="window.print()">${url}</body>`);
         }
        
         newWin.document.close();
 
     });
   }
 

  return (
    <div>
      <ToastContainer
       hideProgressBar={true}
      />
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record Deleted succesffully</div></div>
       <div className='path'><i className='fa fa-home'></i>/ Products / {edit?'Edit Fee Item':'Product Labels'}</div>
        <div className='bodysection col-lg-5'>
        {saveSuccess || updateSuccess &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>operation succesfull...</span>}
       
<div className=' col-lg-12 theader'>< h5>Print Product Labels</h5> </div>
<Form >
 
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input  type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />

</FormGroup>}
<FormGroup className='mr-2 ml-2'>
<Label>Product</Label>
<Select1
       value={valueState}
       defaultValue=""
       placeholder="Select Product"
       options={renderList()}
       onChange={ (value) => handleSelectChange(value) }
       name="ColumnSelect"
       label=""
       isClearable
/>
<div className={feetitleError ?'errmessage':'errmessagehide'}>Item Name is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 label2'>
<Label>No. of labels</Label>
<Input className={amountError &&'errorborder'} type="number" placeholder="No. of labels"value={amount} onChange={e => setAmount(e.target.value)} name="email"  required/>
<div className={amountError ?'errmessage':'errmessagehide'}>No. of labels is Reguired</div>
</FormGroup>
</Form>
      <div className='row ml-1 mb-1'>
  
    
    <Button color="primary"className=" mb-3 ml-2" type="submit"onClick={(e)=> validateFields(e)}>
      {editbtn && "Update"}
      {savebtn && "Print"}
      {wait && " Please wait.."}
    </Button>  
    </div>
    <div ref={wrapper_ref}className='setmyw '>
    <span className='setmyw '>{valueState && valueState.label.split(',')[1]}{valueState && valueState.label.split(',')[2]}
   
   {valueState &&
      <Barcode
      height={30}
     
      value={valueState && valueState.label.split(',').shift()}
      /> }</span> </div>
</div>

    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  isadmin:state.auth.isadmin,
  store:state.auth.store,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Addfee))