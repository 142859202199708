import React,{useEffect,useState} from 'react'
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './Vinvoice.css'
import { ToastContainer, toast } from 'react-toastify';
import { TailSpin} from  'react-loader-spinner'
  import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {connect} from 'react-redux';
import { setId} from './../actions/auth';
import { Table } from 'react-bootstrap';
import { NavLink,BrowserRouter as Router,Route,Switch,Link } from 'react-router-dom';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import { Card,Modal} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,faTrash} from '@fortawesome/free-solid-svg-icons'

import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Cinvoice from './Cinvoice';
import jsPDF from "jspdf";
import "jspdf-autotable";

const Saledetail=({isAuthenticated,checkAuthenticated,username1,pitems,setId,useremail,payment,isadmin,fetchPayment})=> {
  const [amount,setAmount]=useState("");
  const [loading,setLoading]=useState(false);
  const [notFound,setNotFound]=useState(false);
  const [successicon,setSuccessIcon]=useState(false);
  const [nosms,SetNoSms]=useState(false);
  const [emailSuccess,setEmailSuccess]=useState(false);
  const [messageSuccess,setMessageSuccess]=useState(false);
  const [message,setMessage]=useState(false);
  const [loadspinner,setLoadspinner]=useState(false);
  const [date,setDate]=useState("");
  const [search,setSearch]=useState("");
  const [deleteid,setDeleteId]=useState("");
  const [amountError,setAmountError]=useState(false);
  const [total,setTotal]=useState("");
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [data,setData]=useState([""]);
  const [dateError,setDateError]=useState(false);
  const [customer,setCustomer]=useState([""]);
  const [grandtotal,setGrandTotal]=useState('');
  const [data1,setData1]=useState("");
  const [invoices,setInvoices]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const [success,setSuccess]=useState(false);
  const [notreachable,setNotReachable]=useState(false);
  const [dataReceived,setDataReceived]=useState([]);
  const [heading,setHeading]=useState(['Product','Qty','Amount',]);
  const [percentage, setPercentage] = useState(0);
  const showToastMessage = () => {
    toast.success('Payment updated succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };  
 
  useEffect(()=>{
    
    //fetch total sales
  
   fetchAllSales()
  getTotal()
   
     },[]) 
     useEffect(()=>{
    
    getTotal()
     
       },[data]) 
     const getTotal=()=>{
  
      const res=data.reduce((prev,item)=>{
        
          return prev +(item.amount*item.quantity);
      },0)
      setGrandTotal(res)
      console.log(res);
      }
     const fetchAllSales=()=>{
      //get the serial
      const serialNumber = /[^/]*$/.exec(window.location.href)[0];
     
      let data4 ;
      axios({
       method:'post',
       url:'/totalsales2/',
       data:{useremail:useremail,serial:serialNumber},
     })
     .then(res => {
         data4 = res.data;
         setInvoices(res.data[0].dateentered)
         setData(data4);
         setLoading(false)
     })
     .catch(err => {})
   reLoadPage()
    }

     const reLoadPage=()=>{
       
      //get balance
    const serial = /[^/]*$/.exec(window.location.href)[0];
    axios({
      method:'post',
      url:'/searchamount/',
      data:{useremail:useremail,serial:serial},
    })
    .then(res => {
       
      setDetailsbkp(res.data);
       setData1(res.data[0].total)
        setLoading(false)
        console.log(res.data[0].total)
    })
    .catch(err => {})
  }

  function hidesuccess() {
    return new Promise(resolve => {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    });
  }
  const filterList = (event) => {
    //search staff
  
    var updatedList = detailsbkp;
    updatedList = updatedList.filter(detail => detail.name.toLowerCase().includes(search.toLowerCase()) )
    setData(updatedList)
    
    };
  const closeModal1=()=> {
    setShowhide1(false);
    setNotFound(false)
    setMessage(false)
    setMessageSuccess(false)
    setEmailSuccess(false)
    setSuccessIcon(false)
    SetNoSms(false)
    setNotReachable(false)
    }
    const showToastMessage2 = () => {
     
      toast.error('You dont have permission to perform this action', {
          position: toast.POSITION.TOP_CENTER
      });
    }; 
    const deleteData = async () => {
      if(useremail=="4")
      {
showToastMessage2();
      }
      else{
      handleModalShowHide1()
         const result = await axios({
           method:'put',
           url:`/api/sales1/${deleteid}/`,
           data:{isdeleted:"deleted"}
         })
         .then((Response)=>{
          showToastMessage()
           // setSuccess(true)
           // hidesuccess();
            fetchAllSales()
                  
         })
         .catch((error) => {
          if (error.code="ERR_BAD_RESPONSE"){
           
                  }
          
         })
        }
         }

         const handleModalShowHide1=(id)=> {
          setDeleteId(id)
          setShowhide1(!showhide1);
          console.log("clicked");
        }
        const handleSubmit=()=> {
          const serialNumber = /[^/]*$/.exec(window.location.href)[0];
    
          axios({
            method:'post',
            url:'/editamount/',
            data:{useremail:useremail,serial:serialNumber,amount: Number(amount)+Number(data1)},
          })
          .then(res => {
  showToastMessage()
  reLoadPage()
              setLoading(false)
          })
          .catch(err => {})  
          axios({
            method:'post',
            url:'/api/audit/',
            data:{username:username1,operation:'Sales payment inserted',table:'Payments',owner:useremail},
          })
          .then(res => {
             
          
          })
          .catch(err => {})
        }

        const validateFields=(e)=>{
    
          e.preventDefault()
          if( amount.trim().length>0 && date.trim().length>0){
             
      handleSubmit()
          }else{
             
              if(amount.trim().length==0){
                setAmountError(true)
               
                }
                if(date.trim().length==0){
                  setDateError(true)
                 
                  }
          }}

if(isAuthenticated=="false"){
  
  /*return <Redirect to='/login'/>*/}
  if(payment=="false"){
  
   return <Redirect to='/payment'/>}
    const value = 0.66;
    
  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record Deleted succesffully</div></div>
       <div className='path'><i className='fa fa-home'></i>/ Sales / View Sales</div>
      <Router>
        <div className='bodysection col-lg-4'>
  <div className=' col-lg-12 theader'>< h5>Products List</h5> </div>
  {grandtotal-data1>0 &&<div className='ml-1'>{pitems=="true"| isadmin=="true" &&<Button className='m-1'onClick={handleModalShowHide1}>Pay</Button>}</div>}

  <div className='row p-2'>
  
  <FormGroup className='mr-2 ml-2'>
<Label className='mt-2 ml-1'>Serial:</Label>    
<Label className='mt-2 ml-2'>{/[^/]*$/.exec(window.location.href)[0]}</Label>    
</FormGroup>

<FormGroup className='mr-2 ml-2'>
<Label className=' mt-2 ml-1'>Date:</Label>    
<Label className='mt-2 ml-2'>{invoices}</Label>    
</FormGroup>
</div>
<div class="table-responsive bg-light">
<Table className=" pl-0">
  <thead>
    <tr >
    {heading.map(head => <th>{head}</th>)}
    </tr>
  </thead>
 
                <tbody>     
                {data.length==0? <div>No Records found</div>:data.map(detail => (
                  <tr key={detail.id} >
                
                     <td className="col-2" >{ detail.product}</td>
                     <td className="col-2" >{ detail.quantity}</td>
                     <td className="col-2" >{ detail.amount*detail.quantity}</td>
                   
                   </tr>
                   
             ))}
    </tbody>
    <tr >
                
                     <td className="col-2" ></td>
                     <td className="col-2 " border >Total</td>
                     <td className="col-2 " >{grandtotal}</td>
                    
                   </tr>
                   <tr >
                
                <td className="col-2" ></td>
                
                <td className="col-2 totalborder" border >Balance</td>
                <td className="col-2 totalborder" >{grandtotal-data1}</td>
              </tr>
           </Table> 
           
           </div>
          
           </div>

           <Switch>
           <Route path='/cinvoice' component={Cinvoice}/>
          </Switch>
           </Router>
           <Modal show={showhide1}>
           <Modal.Header closeButton onClick={closeModal1}>
           <Modal.Title><h5>Add Payment</h5></Modal.Title>
           </Modal.Header>
           <Modal.Body>
           <Form >
 
 
 
 <FormGroup className='mr-2 ml-2 label2 mt-1'>
 
 <Input className={amountError &&'errorborder'} type="number" placeholder="Amount"value={amount} onChange={e => setAmount(e.target.value)} name="email"  required/>
 <div className={amountError ?'errmessage':'errmessagehide2'}>Amount is Reguired</div>
 </FormGroup>
 <FormGroup className='mr-2 ml-2 label2 mt-1'>

 <Input className={amountError &&'errorborder'} type="date" placeholder="Date"value={date} onChange={e => setDate(e.target.value)} name="email"  required/>
 <div className={dateError ?'errmessage':'errmessagehide2'}>Date is Reguired</div>
 </FormGroup>
  
       <div className='row ml-1'>
   <Button color="primary"className=" mb-3 ml-2" type="submit"onClick={(e)=> validateFields(e)}>
      
       {"Submit"}
       
     </Button>  
     <Button color="primary"className=" mb-3 ml-2" type="submit"onClick={(e)=> handleModalShowHide1(e)}>
      
      {"Close"}
    
    </Button>  
     </div>
     </Form>
</Modal.Body>

       </Modal>

        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  pitems:state.auth.pitems,
  isadmin:state.auth.isadmin,
  username1:state.auth.username1,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,setId,fetchPayment})(Saledetail)