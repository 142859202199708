import { useParams } from "react-router-dom";
import React, { useState,useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
export default function Employee() {
    const [tscnumber,setTscNumber]=useState("");
  const [tscnumberError,setTscNumberError]=useState(false);
  const [kra, setKra] = useState("");
  const [nhif, setNhif] = useState("");
  const [nssf ,setNssf] = useState("");
  const [salary,setSalary]=useState('');
  const [account,setAccount]=useState("");
  const [bank,setBank]=useState('');
  const [accountName,setAccountName]=useState('');
  const [idnumber,setIdNumber]=useState("");
  const [idnumberError,setIdNumberError]=useState(false);
  const [phonenumber,setPhoneNumber]=useState("");
  const [phonenumberError,setPhoneNumberError]=useState(false);
  const [firstname,setFirstName]=useState("");
  const [firstnameError,setFNameError]=useState(false);
  const [lastname,setLName]=useState("");
  const [lastnameError,setLnameError]=useState(false);
  const [emailError,setEmailError]=useState(false);
  const [county,setCounty]=useState("");
  const [countyError,setCountyError]=useState(false);
  const [townError,setTownError]=useState(false);
  const [street,setStreet]=useState("");
  const [subjects,setSubjects]=useState("");
  const [classes,setClasses]=useState("");
  const [gender, setGender] = useState("");
  const [streetError,setStreetError]=useState(false);
  const [validated,setValidated]=useState(false);
  const [email,setEmail]=useState("");
  const [town,setTown]=useState("");
  const { id } = useParams();
    useEffect(()=>{
        //fetch student records
   axios({
    method:'post',
    url:'/staffsearch/',
    data:{id:id},
   
  })
  .then((Response)=>{
   const datareceived=Response.data;
   console.log(datareceived)
  //set fields
  setTscNumber(datareceived[0].tscnumber)
  setFirstName(datareceived[0].firstname)
  setLName(datareceived[0].lastname)
  setIdNumber(datareceived[0].idnumber)
  setEmail(datareceived[0].email)
  setPhoneNumber(datareceived[0].phonenumber)
  setCounty(datareceived[0].county)
  setStreet(datareceived[0].street)
  setTown(datareceived[0].town)
  setGender(datareceived[0].gender)
  setSubjects(datareceived[0].subjects)
  setClasses(datareceived[0].classes)
  setSalary(datareceived[0].salary)
  setBank(datareceived[0].bank)
  setAccountName(datareceived[0].accountname)
  setAccount(datareceived[0].account)
  setKra(datareceived[0].kra)
  setNhif(datareceived[0].nhif)
  setNssf(datareceived[0].nssf)
  console.log(gender)
    })
       },[]) 
  return (
    <div>
      <div className='path'><i className='fa fa-home'></i> / Staff / View Staff / {id}</div>
  
    <div className=" bodysection4 col-md-6 p-4">
            <div className=" text-center  bg-light">
            <h3>{firstname +" "+ lastname}</h3>
<h6>{"Emp. No.:" +" "+ tscnumber}</h6>
<h6>{"Gender:" +" "+ gender}</h6>
<h6>{"Id:" +" "+ idnumber}</h6>
<h6>{"Phone:" +" "+ phonenumber}</h6>
<h6>{"Email:" +" "+ email}</h6>
<h6>{"County:" +" "+ county}</h6>
<h6>{"Town:" +" "+ town}</h6>
<h6>{"Street:" +" "+ street}</h6>
<h6>{subjects && "Subjects:" +" "+ subjects}</h6>
<h6>{classes && "Classes:" +" "+ classes}</h6>
<h6>{salary && "Basic Salary:" +" "+ salary}</h6>
<h6>{bank && "Bank:" +" "+ bank}</h6>
<h6>{accountName && "Account Name:" +" "+ accountName}</h6>
<h6>{account && "Account:" +" "+ account}</h6>
<h6>{kra && "Kra:" +" "+ kra}</h6>
<h6>{nhif && "Nhif No.:" +" "+ nhif}</h6>
<h6>{nssf && "Nssf:" +" "+ nssf}</h6>
                </div>
                </div></div>
  )
}
