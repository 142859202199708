import React, { useState,useEffect } from 'react';
import './Adminsidebar.css';
import { NavLink,BrowserRouter as Router,Route,Redirect,Switch,useLocation} from 'react-router-dom';
import { checkAuthenticated,load_user,fetchPayment } from './../actions/auth';
import {connect} from 'react-redux';
import image from './JIMS.png';
import logo from './logot.jpg';
import Customers from './Customers';
import Products from './Products';
import Dashboard from './Dashboard';
import Cinvoice from './Cinvoice';
import Stocktake from './Stocktake';
import SlideToggle from "react-slide-toggle";
import Vinvoice from './Vinvoice';
import Login from './Login';
import Settings from './Settings';
import Payment from './Payment';
import Addstudent from './Addstudent';
import Viewstudents from './Viewstudents';
import Newstaff from './Newstaff';
import Viewstaffs from './Viewstaffs';
import Viewstaff from './Viewstaff';
import Viewdorms from './Viewdorms';
import Addclass from './Addclass';
import Viewclass from './Viewclass';
import Addassignment from './Addassignment';
import Viewassignment from './Viewassignment';
import Addexam from './Addexam';
import Viewexam from './Viewexam';
import Recordresult from './Recordresult';
import Viewmarks from './Viewmarks';
import Addfee from './Addfee';
import Viewfeetype from './Viewfeetype';
import Makepayment from './Makepayment';
import Viewpayments from './Viewpayments';
import Addbook from './Addbook';
import Issuebook from './Issuebook';
import Issuedbooks from './Issuedbooks';
import Viewbooks from './Viewbooks';
import Allguardian from './Allguardian';
import Allstaff from './Allstaff';
import Selectguardian from './Selectguardian';
import Selectstaff from './Selectstaff';
import Adddorm from './Adddorm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown,faAngleUp,faUserFriends,faHome,faReceipt,faCreditCard,faBook,faCommentSms,faBookOpen,faBox,faProcedures,faWallet,faServer, faUser, faBriefcase} from '@fortawesome/free-solid-svg-icons'
import { FaGraduationCap, FaSms } from 'react-icons/fa';
import Student from './Student';
import Viewteacher from './Viewteacher';
import Createinv from './Createinv';
import Viewinv from './Viewinv';
import Inv from './Inv';
import Outbox from './Outbox';
import Sales from './Sales';
import Saledetail from './Saledetail';
import Addpurchase from './Addpurchase';
import Pos from './Pos';
import Purchases from './Purchases';
import Purchasesdetail from './Purchasesdetail';
import Category from './Category';
import Unit from './Unit';
import Stocktakedetail from './Stocktakedetail';
import Addstaff from './Addstaff';
import Employee from './Employee';
import Allowances from './Allowances';
import Deductions from './Deductions';
import Advance from './Advance';
import P9 from './P9';
import Payroll from './Payroll';
import Payslip from './Payslip';
import Editstaff from './Editstaff';
import Store from './Store';
const Adminsidebar =({click,isAuthenticated,name,sitems,pitems,vitems,asreturn,apurchase,vpurchase,apreturn,home,aitem,vitem,ditem,
  eitem,stocktake,acategory,vcategory,ecategory,dcategory,reconcile,auom,euom,duom,vuom,vsreport,vpreport,vprreport,
  vmonitor,astore,vstore,dstore,arole,vrole,drole,auser,vuser,duser,acustomer,vcustomer,ecustomer,dcustomer,
  asupplier,vsupplier,esupplier,dsupplier,astaff,vstaff,estaff,dstaff,asallowance,asdeduction,aallowance,vallowance,
  eallowance,vexpense,aexpense,dallowance,aquote,vquote,glabel,adeduction,vdeduction,ededuction,ddeduction,aoadvance,aaadvance,voadvance,vaadvance,
  vop9,vap9,vsreturns,vapayroll,gpayroll,vopayroll,checkAuthenticated,contacts,handleClick,fetchPayment,useremail,isadmin,isprimary,handleClick1,click1,handleClick2,click2,handleClick3,click3,handleClick10,click10,handleClick11,click11,handleClick12,click12,expenses,handleClick4,click8,handleClick8,click4,handleClick5,click5,handleClick6,click6,handleClick7,click9,handleClick9,click7,isstaff,isparent,sales,products,purchases,reports,contacts1,system,users,payroll})=> {
  const [clicked, setClick] = useState(false);
  const [assignment, setAssignment] = useState(false);
  const [mobilescreen, setMobileScreen] = useState(false);
  const [studentclicked, setStudent] = useState(false);
  const [staffclicked, setStaff] = useState(false);
  const [classclicked, setClass] = useState(false);
  const [dormclicked, setDorm] = useState(false);
  const [examclicked, setExam] = useState(false);
  const [active, setActive] = useState('active');
  const [sidenav, setSide] = useState('');
  const [feeclicked, setFee] = useState(false);
  const [libraryclicked, setLibrary] = useState(false);
  const [sms, setSms] = useState(false);
  const [postpage, setPosPage]=useState(false)
  const handleSubmenu = () => setClick(!clicked);
  const reLoadPage=()=>{
       
    //window.location.reload();
}

const { pathname } = useLocation();
const checkPath=()=>{
  if(window.location.pathname == '/addstudent'| window.location.pathname == '/viewstudents'){
   
setStudent(true)
setStaff(false)
setClass(false)
setDorm(false)
setExam(false)
setFee(false)
setSms(false)
setLibrary(false)
  }
  if(window.location.pathname == '/addstaff'|window.location.pathname == '/viewstaff'){
    setStudent(false)
    setStaff(true)
    setClass(false)
    setDorm(false)
    setExam(false)
    setFee(false)
    setSms(false)
    setLibrary(false)
      }
      if(window.location.pathname == '/addclass'|window.location.pathname == '/viewclass'){
        setStudent(false)
        setStaff(false)
        setClass(true)
        setDorm(false)
        setExam(false)
        setFee(false)
        setSms(false)
        setLibrary(false)
          }
          if(window.location.pathname == '/adddorm'|window.location.pathname == '/viewdorm'){
            setStudent(false)
            setStaff(false)
            setClass(false)
            setDorm(true)
            setExam(false)
            setFee(false)
            setSms(false)
            setLibrary(false)
              }
              if(window.location.pathname == '/addexam'|window.location.pathname == '/viewexam'|window.location.pathname == '/recordresult'){
                setStudent(false)
                setStaff(false)
                setClass(false)
                setDorm(false)
                setExam(true)
                setFee(false)
                setSms(false)
                setLibrary(false)
                  }
                  if(window.location.pathname == '/addfee'|window.location.pathname == '/viewfeetype'|window.location.pathname == '/makepayment'|window.location.pathname == '/viewpayments'){
                    setStudent(false)
                    setStaff(false)
                    setClass(false)
                    setDorm(false)
                    setExam(false)
                    setFee(true)
                    setSms(false)
                    setLibrary(false)
                      }
                      if(window.location.pathname == '/addbook'|window.location.pathname == '/issuebook'|window.location.pathname == '/issuedbooks'|window.location.pathname == '/viewbooks'){
                        setStudent(false)
                        setStaff(false)
                        setClass(false)
                        setDorm(false)
                        setExam(false)
                        setFee(false)
                        setLibrary(true)
                        setSms(false)
                          }
                          if(window.location.pathname == '/allguardian'|window.location.pathname == '/selectguardian'|window.location.pathname == '/allstaff'|window.location.pathname == '/selectstaff'){
                            setStudent(false)
                            setStaff(false)
                            setClass(false)
                            setDorm(false)
                            setExam(false)
                            setFee(false)
                            setSms(true)
                            setLibrary(false)
                              }
}
const combineMethods=()=>{
 // handleClick()
 // reLoadPage()
}
useEffect(()=>{
  checkAuthenticated();
 
 
  //check if mobile screen
 
  if (window.innerWidth < 960) {
    setMobileScreen(true);
  } else {
    setMobileScreen(false);
  }
  fetchPayment(useremail)
  if(window.location.pathname == '/pos1'){
    setPosPage(true)
  }
 
    },[]) 
    useEffect(()=>{
      if (postpage) {
        if (window.innerWidth > 960) {
        setSide('sidenav1');}
      } else {
        setSide('sidenav');
      }  
    
    
        },[postpage]) 
        useEffect(()=>{
          if (click) {
            setActive('active');
          } else {
            setActive('');
          }
          
            },[click]) 
    var testme=true;
    if(isAuthenticated=="false"){
     //sidenav + ' '+ active
      }
    return (
      <div className=''>{
        
        <Router>
          
        <div className={sidenav + ' '+ active}>
          
         <div className='closebtn1' onClick={handleClick}></div>
          <ul >
            
            <div className='toplogo pb-4'onClick={mobilescreen && handleClick}>
            <NavLink  to='/' state={{ previousPath:'/'}}> <div className='jimslogo  mt-2'> <img src={logo}height="30px" width={''} /></div>
          <h6 className=' mr-1 pb-2'>SEJOPOS</h6></NavLink>
            </div>
         
           
             {isadmin=="true" |  sales=="true" |sitems=="true" |pitems=="true" |vitems=="true" |asreturn?<div className='slide1 mm'>
              <SlideToggle collapsed className='slide1 '
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible" >
       
        <FontAwesomeIcon icon={faBox}className="linkitem1 mr-2" />
      <label className={studentclicked?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick1();}}>
       Sales
      </label><span className={studentclicked? 'whiteicon':''}> {click1? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
        {isadmin=="true"| sales=="true"| sitems=="true"? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/pos1'className='' activeClassName="activelink" > PoS
                </NavLink></li>:""}
                {isadmin=="true"| sales=="true"| vitems=="true"? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/sales'className='' activeClassName="activelink" > Sales
                </NavLink></li>:""}
                {isadmin=="true"| sales=="true"| asreturn=="true"? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/salesreturns'className='' activeClassName="activelink" > Sales Returns
                </NavLink></li>:""}
                {isadmin=="true"| aquote=="true"? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/addquote'className='' activeClassName="activelink" > Add Quote
                </NavLink></li>:""}
                {isadmin=="true"| vquote=="true"? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/quotations'className='' activeClassName="activelink" > View Quotations
                </NavLink></li>:""}
        </div>
      </div>
    </div>
  )}
/>
</div>:""}
{isadmin=="true" | products=="true"|aitem=="true" |vitem=="true" |ditem=="true" |eitem=="true" |stocktake=="true" |acategory=="true" |vcategory=="true" |ecategory=="true" |dcategory=="true" |reconcile=="true" |auom=="true" |euom=="true" |duom=="true" |vuom?<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
    
       <FontAwesomeIcon icon={faProcedures}className="linkitem1 mr-2" />
      <label className={staffclicked?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick2();}}>
        Products
      </label><span className={staffclicked? 'whiteicon':''}> {click2? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
             {isadmin=="true" | aitem=="true"? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/additem' activeClassName="activelink" > Add Item
                </NavLink></li>:""}
                {isadmin=="true" | vitem=="true"?<li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/productslist' activeClassName="activelink" > Products List
                </NavLink></li>:""}
                {isadmin=="true" | acategory=="true"| vcategory=="true"?<li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/category' activeClassName="activelink" > Category
                </NavLink></li>:""}
               {isadmin=="true" | auom=="true"| vuom=="true"? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/unit' activeClassName="activelink" > Units
                </NavLink></li>:""}
                {isadmin=="true" | glabel=="true"?  <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/labels' activeClassName="activelink" > Print Labels
                </NavLink></li>:""} 

        </div>
      </div>
    </div>
  )}
/>
</div>:""}
{isadmin=="true"| purchases=="true"|apurchase=="true"|vpurchase=="true"|apreturn=="true" ?<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
      <FontAwesomeIcon icon={faWallet}className="linkitem1 mr-2" />
       
      <label className={classclicked?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick3();}}>
        Purchases
      </label><span className={classclicked? 'whiteicon':''}>{click3? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
              {isadmin=="true"|apurchase=="true" ?<li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/addpurchase' activeClassName="activelink" > Add Purchase
                </NavLink></li>:""}
                {vpurchase=="true"|isadmin=="true" ?<li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/purchases' activeClassName="activelink" > Purchases List
                </NavLink></li>:""}
                {isadmin=="true"|apreturn=="true" ?<li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/purchasesreturns' activeClassName="activelink" > Purchases Returns
                </NavLink></li>:""}
        </div>
      </div>
    </div>
  )}
/>
</div>:""}

{assignment ? <div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
       <i class="fa fa-address-book linkitem1" aria-hidden="true"></i> 
      <label className="my-collapsible__toggle toggle1 mt-2 pr-1"  onClick={() => { toggle(); handleSubmenu();handleClick5();}}>
        Assignments
      </label><i class={click5 ? 'fa fa-angle-up ml-3 sideicon mt-2' : 'fa fa-angle-down ml-3 sideicon mt-2'} aria-hidden="true"></i>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
              <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/addassignment' activeClassName="activelink" > Add Assignment
                </NavLink></li>
                <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/viewassignment' activeClassName="activelink" > Assignments 
                </NavLink></li>
               
              

        </div>
      </div>
    </div>
  )}
/>
</div>:""}

{isadmin=="true" | reports=="true"|vsreport=="true" |vpreport=="true" |vprreport=="true"?<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
      <FontAwesomeIcon icon={faBriefcase}className="linkitem1 mr-2" />
      
      <label className={feeclicked?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick6()}}>
        Reports
      </label><span className={feeclicked? 'whiteicon':''}>{click6? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
                {isadmin=="true" |vsreport=="true" ?<li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/salesreport' activeClassName="activelink" > Sales
                </NavLink></li>:""}
              {isadmin=="true" |vpreport=="true" ?<li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/purchasesreport' activeClassName="activelink" > Purchases
                </NavLink></li>:""}
               {isadmin=="true" |vprreport=="true" ?<li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/profitreport' activeClassName="activelink" > Profit & Loss
                </NavLink></li>:""}

        </div>
      </div>
    </div>
  )}
/>
</div>:""    }  
{isadmin=="true"| system=="true"| vmonitor=="true"|astore=="true"|vstore=="true"|dstore=="true" ?<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
       
       <FontAwesomeIcon icon={faServer}className="linkitem1 mr-2" />
      <label className={libraryclicked?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick7();}}>
        System
      </label><span className={libraryclicked? 'whiteicon':''}>{click7? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
              {isadmin=="true"| system=="true"| vmonitor=="true" ?<li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/access' activeClassName="activelink" > Access Monitor
                </NavLink></li>:""}
                {isadmin=="true"| system=="true"|astore=="true"|vstore=="true"|dstore=="true" ?<li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/stores' activeClassName="activelink" > Stores
                </NavLink></li>:""}
                {isadmin=="true"| system=="true"|astore=="true"|vstore=="true"|dstore=="true" ?<li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/audit' activeClassName="activelink" > Audit trail
                </NavLink></li>:""}
        </div>
      </div>
    </div>
  )}
/>
</div>  :""  }

{isadmin=="true"| users=="true"| arole=="true"|vrole=="true"|drole=="true"|auser=="true"|vuser=="true"|duser=="true" ?<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
       
       <FontAwesomeIcon icon={faUserFriends}className="linkitem1 mr-2" />
      <label className={sms?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick8();}}>
        Users
      </label><span className={sms? 'whiteicon':''}>{click8? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       {isadmin=="true"| arole=="true"? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/addrole' activeClassName="activelink" > Add Role
                </NavLink></li>:""}
                {isadmin=="true"| vrole=="true"?<li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/viewroles' activeClassName="activelink" > View Roles
                </NavLink></li>:""}
              {isadmin=="true"| auser=="true"?<li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/adduser' activeClassName="activelink" > Add User
                </NavLink></li>:""}
                {isadmin=="true"| vuser=="true"?<li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/users' activeClassName="activelink" > Users List
                </NavLink></li>:""}
               
        </div>
      </div>
    </div>
  )}
/>
</div>:"" }
{isadmin=="true"| payroll=="true" | isstaff=="true"|astaff=="true"|vstaff=="true"|estaff=="true"|dstaff=="true"|asallowance=="true"|asdeduction=="true"|aallowance=="true"|vallowance=="true"|
  eallowance=="true"|dallowance=="true"|adeduction=="true"|vdeduction=="true"|ededuction=="true"|ddeduction=="true"|aoadvance=="true"|aaadvance=="true"|voadvance=="true"|vaadvance=="true"|
  vop9=="true"|vap9=="true"|vsreturns=="true"|vapayroll=="true"|gpayroll=="true"|vopayroll=="true"|vopayroll=="true" ?<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
       
       <FontAwesomeIcon icon={faReceipt}className="linkitem1 mr-2" />
      <label className={sms?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick10();}}>
        Payroll & HR
      </label><span className={sms? 'whiteicon':''}>{click10? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
        
                {isadmin=="true"| astaff=="true"? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/addemployee' activeClassName="activelink" > Add Employee
                </NavLink></li>:""}
                {isadmin=="true"| vstaff=="true"? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/viewemployees' activeClassName="activelink" > View Employees
                </NavLink></li>:""}
                {isadmin=="true"| aallowance=="true"| vallowance=="true"? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/allowances' activeClassName="activelink" > Allowances
                </NavLink></li>:""}
                {isadmin=="true"| adeduction=="true"| vdeduction=="true"?<li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/deductions' activeClassName="activelink" > Deductions
                </NavLink></li>:""}
                {isadmin=="true"| vaadvance=="true"| voadvance=="true" |isstaff=="true"?<li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/advances' activeClassName="activelink" > Advances
                </NavLink></li>:""}
                {isadmin=="truee"| vap9=="truee"| vop9=="truee"? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/p9forms' activeClassName="activelink" > P9 forms
                </NavLink></li>:""}
                {isadmin=="true" |isstaff=="true"| gpayroll=="true"| vopayroll=="true"| vapayroll=="true"? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink exact to='/payroll' activeClassName="activelink" > Generate Payroll
                </NavLink></li>:""}
              

        </div>
      </div>
    </div>
  )}
/>
</div>:"" }    
{isadmin=="true" | contacts1=="true"| acustomer=="true" |vcustomer=="true" |ecustomer=="true" |dcustomer=="true" |asupplier=="true" |vsupplier=="true" |esupplier=="true" |dsupplier=="true" ?<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
       
       <FontAwesomeIcon icon={faUser}className="linkitem1 mr-2" />
      <label className={sms?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick11();}}>
        Contacts
      </label><span className={sms? 'whiteicon':''}>{click11? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
             {isadmin=="true" | contacts1=="true"| acustomer=="true" |vcustomer=="true" |ecustomer=="true" |dcustomer=="true"? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/customers' activeClassName="activelink" > Customers
                </NavLink></li>:""}
               {isadmin=="true" | contacts1=="true"|asupplier=="true" |vsupplier=="true" |esupplier=="true" |dsupplier=="true" ? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/supplier' activeClassName="activelink" > Suppliers
                </NavLink></li>:""}
               
        </div>
      </div>
    </div>
  )}
/>
</div>:"" } 
{isadmin=="true" | expenses=="true"| aexpense=="true"| vexpense=="true" ?<div className='slide1'>
              <SlideToggle collapsed className='slide1'
  render={({ toggle, setCollapsibleElement }) => (
    <div className="my-collapsible">
       
       <FontAwesomeIcon icon={faCreditCard}className="linkitem1 mr-2" />
      <label className={sms?"whiteicon my-collapsible__toggle toggle1 mt-2 mr-4":" my-collapsible__toggle toggle1 mt-2 mr-4"}  onClick={() => { toggle(); handleSubmenu();handleClick12();}}>
        Expenses
      </label><span className={sms? 'whiteicon':''}>{click12? <FontAwesomeIcon icon={faAngleUp}className="ml-3 sideicon mt-2 updownicons" />:<FontAwesomeIcon icon={faAngleDown}className="ml-3 sideicon mt-2 updownicons" />}</span>
      <div className="my-collapsible__content" ref={setCollapsibleElement}>
        <div className="my-collapsible__content-inner slide2" onClick={checkPath}>
       
             {isadmin=="true" | expenses=="true"| aexpense=="true"| vexpense=="true" ? <li className='innermenu'onClick={mobilescreen && handleClick}> <NavLink  to='/expense' activeClassName="activelink" > Expense
                </NavLink></li>:""}
              
               
        </div>
      </div>
    </div>
  )}
/>
</div>:"" }    

          </ul>
         
          
         </div>
          <div className='col-lg-12 ml-5 content'>
          <Switch>
            
          
          <Route  path="/"exact={true}>
            <Dashboard />
          </Route>
          {isadmin=="true" && <Route exact path="/addstudent/:id" component={Addstudent}/>}
          {isadmin=="true" |isparent=="true"|isstaff=="true" && <Route exact path="/viewstudent/:id" component={Student}/>}
          {isadmin=="true" |vstaff=="true"&& <Route exact path="/viewstaff/:id" component={Employee}/>}
    
          {isadmin=="true" && <Route exact path="/editclass/:id" component={Addclass}/>}
          {isadmin=="true" && <Route exact path="/editdorm/:id" component={Adddorm}/>}
          {isadmin=="true"| eitem=="true" && <Route exact path="/editproduct/:pid" component={Newstaff}/>}
          {isadmin=="true" && <Route exact path="/editrole/:id" component={Recordresult}/>}
          {isadmin=="true" |isstaff=="true" && <Route exact path="/editexam/:id" component={Addexam}/>}
          {isadmin=="true" |isstaff=="true" && <Route exact path="/viewmarks/:id" component={Viewmarks}/>}
          {isadmin=="true" &&  <Route exact path="/editfeetype/:id" component={Addfee}/>}
          <Route exact path="/cinvoice">
            <Cinvoice />
          </Route> 
          {isadmin=="true" &&<Route exact path="/pos">
            <Addstudent />
          </Route> }
          {isadmin=="true"| acategory=="true"| vcategory=="true" &&<Route exact path="/category">
            <Category />
          </Route> }
          {isadmin=="true"| aquote=="true" &&<Route exact path="/addquote">
            <Vinvoice/>
          </Route> }
          {isadmin=="true"| vquote=="true" &&<Route exact path="/quotations">
            <Viewteacher/>
          </Route> }
          {isadmin=="true"| system=="true"|astore=="true"|vstore=="true"|dstore=="true" &&<Route exact path="/stores">
            <Store />
          </Route> }
          {isadmin=="true"| astaff=="true" &&<Route exact path="/addemployee">
            <Addstaff />
          </Route> }
          {isadmin=="true"| payroll=="true" &&<Route exact path="/employee">
            <Employee/>
          </Route> }
          {isadmin=="true"| aallowance=="true" | eallowance=="true"| dallowance=="true"| vallowance=="true"   &&<Route exact path="/allowances">
            <Allowances/>
          </Route> }
          {isadmin=="true"|adeduction=="true"| ededuction=="true"| ddeduction=="true"|vdeduction=="true"    &&<Route exact path="/deductions">
            <Deductions/>
          </Route> }
          {isadmin=="true"| voadvance=="true"| vaadvance=="true"|isstaff=="true" &&<Route exact path="/advances">
            <Advance/>
          </Route> }
          {isadmin=="true"| vop9=="true" &&<Route exact path="/p9forms">
            <P9/>
          </Route> }
          {isadmin=="true"| vapayroll=="true"| vopayroll=="true"| gpayroll=="true"| isstaff=="true" &&<Route exact path="/payroll">
            <Payroll/>
          </Route> }
          {isadmin=="true"| vopayroll=="true"| vapayroll=="true"| isstaff=="true" &&<Route exact path="/viewpayslip/:id">
            <Payslip/>
          </Route> }
          {<Route exact path="/quotedetail/:id">
            <Viewexam/>
          </Route> }
          {isadmin=="true"| vstaff=="true" &&<Route exact path="/viewemployees">
            <Viewstaffs />
          </Route> }
          {isadmin=="true"| auom=="true"| vuom=="true" &&<Route exact path="/unit">
            <Unit/>
          </Route> }
          {isadmin=="true"| vpurchase=="true" &&<Route exact path="/purchases">
            <Purchases />
          </Route> }
          {isadmin=="true"| purchases=="true" &&<Route exact path="/expense">
            <Viewstudents />
          </Route> }
          {isadmin=="true"| sitems=="true" &&<Route exact path="/pos1">
            <Pos />
          </Route> }
          {isadmin=="true"| vitems=="true" &&<Route exact path="/sales">
            <Sales />
          </Route> }

          {isadmin=="true" |isstaff=="true" && <Route exact path="/recordresult">
            <Recordresult />
          </Route> }
          {isadmin=="true"| auser=="true" &&<Route exact path="/adduser">
            <Allguardian />
          </Route> }
          {isadmin=="true"| arole=="true" &&<Route exact path="/addrole">
            <Recordresult />
          </Route> }
          {isadmin=="true"| vrole=="true"| drole=="true" &&<Route exact path="/viewroles">
            <Outbox />
          </Route> }
          {isadmin=="true" &&<Route exact path="/viewfeetype">
            <Viewfeetype />
          </Route> }
          {isadmin=="true" |apreturn=="true" |purchases=="true"&&<Route exact path="/purchasesreturns">
            <Issuedbooks />
          </Route> }
          {isadmin=="true" |isstaff=="true" &&<Route exact path="/viewbooks">
            <Viewbooks />
          </Route> }
          {isadmin=="true" &&<Route exact path="/allstaff">
            <Allstaff />
          </Route> }
          {isadmin=="true" && <Route exact path="/selectstaff">
            <Selectstaff />
          </Route> }
          {isadmin=="true" &&<Route exact path="/adddorm">
            <Adddorm />
          </Route>}
          {isadmin=="true" && <Route exact path="/viewdorms">
            <Viewdorms/>
          </Route>}
          {isadmin=="true"| vuser=="true" && <Route exact path="/users">
            <Selectguardian />
          </Route>}
          {isadmin=="true" && <Route exact path="/outbox">
            <Outbox/>
          </Route>}
          {<Route exact path="/labels">
            <Addfee />
          </Route> }
          {isadmin=="true" | vmonitor=="true" &&<Route exact path="/access">
            <Addbook />
          </Route> }
          {isadmin=="true" | vmonitor=="true" &&<Route exact path="/audit">
            <Viewmarks />
          </Route> }
          {isadmin=="true" |asreturn=="true" && <Route exact path="/salesreturns">
            <Issuebook />
          </Route>}
          {isadmin=="true" | contacts1=="true"|asupplier=="true" |vsupplier=="true" |esupplier=="true" |dsupplier=="true" &&<Route exact path="/supplier">
            <Createinv />
          </Route>}
          {isadmin=="true"| apurchase=="true" &&<Route exact path="/addpurchase">
            <Addpurchase/>
          </Route>}
          {isadmin=="true" | products=="true"&&<Route exact path="/stocktake/:st">
            <Stocktakedetail />
          </Route>}
          {isadmin=="true"| vitems=="true" &&<Route exact path="/saledetail/:serial">
            <Saledetail/>
          </Route>}
          {isadmin=="true"| vpurchase=="true" &&<Route exact path="/purchasesdetail/:id">
            <Purchasesdetail/>
          </Route>}
          {isadmin=="true"|eitem=="true" &&<Route exact path="/editinvoice/:inv">
            <Createinv />
          </Route>}
          
          {isadmin=="true" | vprreport=="true"&&<Route exact path="/profitreport">
            <Inv />
          </Route>}
          {isadmin=="true"| estaff=="true"| asallowance=="true"| asdeduction=="true" &&<Route exact path="/editstaff/:id">
            <Editstaff />
          </Route>}
          {isadmin=="true" && <Route exact path="/viewinvoices">
            <Viewinv/>
          </Route> }
         {isadmin=="true" |isstaff=="true" && <Route exact path="/addexam">
            <Addexam />
          </Route> }
        {isadmin=="true" |isstaff=="true" &&  <Route exact path="/viewexam">
            <Viewexam />
          </Route> }
        {isadmin=="true" |isstaff=="true" |isparent=="true"&&  <Route exact path="/viewstudents">
            <Viewstudents />
          </Route> }
          {isadmin=="true"| aitem=="true" && <Route exact path="/additem">
            <Newstaff/>
          </Route> }
         
          {isadmin=="true" &&  <Route exact path="/addclass">
            <Addclass/>
          </Route> }
          {isadmin=="true" && <Route exact path="/viewclass">
            <Viewclass/>
          </Route>}
          <Route exact path="/addassignment">
            <Addassignment/>
          </Route>
          <Route exact path="/viewassignment">
            <Viewassignment/>
          </Route>
          <Route exact path="/vinvoice">
            <Vinvoice/>
          </Route> 
         {isadmin=="true" | vsreport=="true"&& <Route exact path="/salesreport">
            <Addclass/>
          </Route>} 
          {isadmin=="true" | vpreport=="true"&&<Route exact path="/purchasesreport">
            <Adddorm/>
          </Route> }
          <Route exact path="/account">
            <Settings />
          </Route> 
          <Route exact path="/stocktake">
            <Stocktake />
          </Route> 
          <Route exact path="/payment">
            <Payment />
          </Route> 
      {isadmin=="true" | contacts1=="true"| acustomer=="true" |vcustomer=="true" |ecustomer=="true" |dcustomer=="true" &&<Route path="/customers">
            <Customers />
          </Route> }
          {isadmin=="true" | vitem=="true"&& <Route exact path="/productslist">
            <Products />
          </Route> }
          
          </Switch>
          </div>
          
          </Router>
         }
         </div>
      )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  isadmin:state.auth.isadmin,
  isprimary:state.auth.isprimary,
  isstaff:state.auth.isstaff,
  isparent:state.auth.isparent,
  sales:state.auth.sales,
  purchases:state.auth.purchases,
  reports:state.auth.reports,
  system:state.auth.system,
  users:state.auth.users,
  products:state.auth.products,
  contacts1:state.auth.contacts1,
  payroll:state.auth.payroll,
  expenses:state.auth.expenses,
  useremail:state.auth.useremail,
  name:state.auth.name, 
    sitems:state.auth.sitems, 
    pitems:state.auth.pitems, 
    vitems:state.auth.vitems,
    asreturn:state.auth.asreturn, 
    apurchase:state.auth.apurchase,
    vpurchase:state.auth.vpurchase, 
    apreturn:state.auth.apreturn, 
    home:state.auth.home, 
    aitem:state.auth.aitem, 
    vitem:state.auth.vitem, 
    ditem:state.auth.ditem,
    eitem:state.auth.eitem,
    stocktake:state.auth.stocktake,
    acategory:state.auth.acategory,
    vcategory:state.auth.vcategory,
    ecategory:state.auth.ecategory,
    dcategory:state.auth.dcategory,
    reconcile:state.auth.reconcile,
    euom:state.auth.euom,
    duom:state.auth.duom,
    vuom:state.auth.vuom,
    vsreport:state.auth.vsreport,
    vpreport:state.auth.vpreport,
    vprreport:state.auth.vprreport,
    vmonitor:state.auth.vmonitor,
    astore:state.auth.astore,
    vstore:state.auth.vstore,
    dstore:state.auth.dstore,
    arole:state.auth.arole,
    vrole:state.auth.vrole,
    drole:state.auth.drole,
    auser:state.auth.auser,
    vuser:state.auth.vuser,
    duser:state.auth.duser,
    acustomer:state.auth.acustomer,
    vcustomer:state.auth.vcustomer,
    ecustomer:state.auth.ecustomer,
    dcustomer:state.auth.dcustomer,
    asupplier:state.auth.asupplier,
    vsupplier:state.auth.vsupplier,
    esupplier:state.auth.esupplier,
    dsupplier:state.auth.dsupplier,
    astaff:state.auth.astaff,
    vstaff:state.auth.vstaff,
    estaff:state.auth.estaff,
    dstaff:state.auth.dstaff,
    asallowance:state.auth.asallowance,
    asdeduction:state.auth.asdeduction,
    aallowance: state.auth.aallowance,
    vallowance:state.auth.vallowance,
    eallowance:state.auth.eallowance,
    dallowance:state.auth.dallowance,
    adeduction:state.auth.adeduction,
    vdeduction:state.auth.vdeduction,
    ededuction:state.auth.ededuction,
    ddeduction:state.auth.ddeduction,
    aoadvance:state.auth.aoadvance,
    aaadvance:state.auth.aaadvance,
    voadvance:state.auth.voadvance,
    vaadvance:state.auth.vaadvance,
    vop9:state.auth.vop9,
    vap9:state.auth.vap9,
    vsreturns:state.auth.vsreturns,
    vapayroll:state.auth.vapayroll,
    gpayroll:state.auth.gpayroll,
    vopayroll:state.auth.vopayroll,
    vapayroll:state.auth.vapayroll,
    vexpense:state.auth.vexpense,
    aexpense:state.auth.aexpense,
    aquote:state.auth.aquote,
    vquote:state.auth.vquote,
    glabel:state.auth.glabel,
  });
export default connect(mapStateToProps,{checkAuthenticated,load_user,fetchPayment})(Adminsidebar)

