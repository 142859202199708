import React,{useEffect,useState} from 'react'
import axios from "axios";
import { Table } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Card,Modal} from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import cellEditFactory from 'react-bootstrap-table2-editor';
import ToolkitProvider, { CSVExport,Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import TableHeaderColumn from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { NavLink,BrowserRouter as Router,Route,Switch,Link } from 'react-router-dom';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,faTrash} from '@fortawesome/free-solid-svg-icons'


function Stocktakedetail({isAuthenticated,checkAuthenticated,useremail,payment,fetchPayment,username,match,isadmin,store}) {
  const [data,setData]=useState([""]);
  const [name,setName]=useState("");
  const [nameError,setNameError]=useState(false);
  const [categoryValue, setCategoryValue]= useState('');
  const [price,setPrice]=useState("");
  const [dateposted,setDateposted]=useState("");
  const [quantity,setQuantity]=useState("");
  const [date,setDate]=useState("");
  const [branch,setBranch]=useState("");
  const [dateError,setDateError]=useState(false);
  const [branchError,setBranchError]=useState(false);
  const [category,setCategory]=useState("");
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [deleteid,setDeleteId]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [id,setId]=useState("");
  const [edit,setEdit]=useState(false);
  const [wait,setWait]=useState(false)
  const [validated,setValidated]=useState(false);
  const [savebtn,setSavebtn]=useState(true)
  const [editbtn,setEditbtn]=useState(false);
  const [error1,setError]=useState(false);
  const [showhide,setShowhide]=useState(false);
  const [value, setValue]=useState("")
  const [heading,setHeading]=useState(['Name', 'Category', 'B. Price','S. Price','Quantity','Action']);
  const { ExportCSVButton } = CSVExport;
  const { st } = useParams();
   const { SearchBar } = Search;
   const options = {
     page: 1,
     sizePerPage: 10,
     nextPageText: '>',
     prePageText: '<',
     showTotal: true
   };
 
   const columns = [{
     dataField: 'name',
     text: 'Name',
     headerStyle: () => {
       return { width: "150px" };
     }
   }, {
     dataField: 'quantity',
     text: 'System qty',
     headerStyle: () => {
       return { width: "100px" };
     }
   } 
   , {
    dataField: 'actualqty',
    text: 'Actual qty',
    headerStyle: () => {
      return { width: "100px" };
    }
  } 
   ,  {
  dataField: "id",
  text: "Difference",
  headerStyle: () => {
    return { width: "100px" };
  },
  csvExport: false,
  editable: false,
  formatter: (cellContent, row) => {
    return (
      <div>
      
       {getDiff(row.actualqty,row.quantity)}
      </div>
    );
  },
}];
const showToastMessage1 = () => {

  toast.success('Record updated succesfully', {
      position: toast.POSITION.TOP_CENTER
  });
};
const handleInputChange = (e, index) => {
  alert(index)
  const { name, value } = e.target;
  const list = [...data];

  list[0][name] = value;
  setData(list);
  }
 const handleCategoryChange = (event) => {
    setBranch(event.target.value) 
  } 
  useEffect(()=>{
   
    checkAuthenticated();
   // alert(idf)
    fetchPayment(useremail);
  //set name
 
    let data1 ;

    axios({
      method:'post',
      url:'/totalstocktakedetail/',
      data:{useremail:useremail,idfield:st,isadmin,store},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
        console.log(data1)  
    })
    .catch(err => {})
//fetch stocktake
    axios({
      method:'post',
      url:'/totalstocktake/',
      data:{useremail:useremail,idfield:st,isadmin,store},
    })
    .then(res => {
        data1 = res.data;
        setName(res.data[0].name);
        setBranch(res.data[0].branch);
        setDate(res.data[0].date);
        console.log(res.data)  
    })
    .catch(err => {})
   /*  const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }*/
//fetch category
let data5 ;
axios({
 method:'post',
 url:'/totalcategory/',
 data:{useremail:useremail},
})
.then(res => {
   data5 = res.data;
   setCategory(data5)
   //setUnit(data5)
})
.catch(err => {})


      },[]) 
      const getDiff=(num1,num2)=>{
return num1-num2
      }
     const handleModalShowHide=()=> {
        setShowhide(!showhide);
    }
    const reLoadPage=()=>{
       
      window.location.reload();
  }
    const closeModal=()=> {
      setShowhide(false);
  }
  const refreshData=()=>{
    let data1 ;

    axios({
      method:'post',
      url:'/totalstocktakedetail/',
      data:{useremail:useremail,idfield:st},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
        console.log(data1)  
    })
    .catch(err => {})
    
  }
  const onAfterSaveCell = (value, name) => {
alert(name)
  }
  const handledate = () => {
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return date;
  }
  const showToastMessage5 = () => {

    toast.error('Operation not allowed on admin account', {
        position: toast.POSITION.BOTTOM_CENTER
    });
  }; 
  const handleSubmit = (e) => {
 if(!name){
setError(true)
 }
 else
    if(isadmin=="true"){
showToastMessage5()
    }
 else
    if(id){
      e.preventDefault();
      axios({
        method:'put',
        url:`/api/product/${id}/`,
        data:{name:name.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),category:category.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),price,quantity,handledate},
      }).then((Response)=>{
        //clear fields
        setEdit(false);
        setId('');
    cleaFields();
      })
      setUpdateSuccess(true)
    }
    else{
        //Save stock take
         //generate invoice id
       const unique_id = uuid();
       const small_id ="STT-"+ unique_id.slice(0,8).toUpperCase();
   axios({
     method:'post',
     url:'/savestocktake/',
     data:{branch:branch,date,idfield:small_id,owner:useremail,handledate},
   }).then((Response)=>{
    //clear fields
alert("ok")
    cleaFields();
   })
   setSaveSuccess(true);
  }
  };
  const editItem = (detail) => {
    setId(detail.id);
    setEdit(true);
    setShowhide(true);
    setName(detail.name);
    setCategory(detail.category);
    setPrice(detail.price);
    setQuantity(detail.quantity);
  };
  const handleDelete = (item) => {
    if(window.confirm('Are You Sure You want delete this record?')){
    console.log(item);
    axios({
      method:'delete',
      url:`/api/item/${item.id}/`,
    }).then((Response)=>{
      
      
      alert("record deleted succesfully");
      refreshData();
    })
  }
  };
  const closeModal1=()=> {
    setShowhide1(false);
   
    }
  const cleaFields=()=>{
    setName('');
    setCategory('');
    setPrice('');
    setQuantity('');
    refreshData();
  }
  useEffect(()=>{
    
    
      },[]) 
      const deleteData = async () => {
        if(useremail=="4")
        {
  showToastMessage2();
        }
        else{
        handleModalShowHide1()
           const result = await axios({
             method:'put',
             url:`/api/item/${deleteid}/`,
             data:{isdeleted:"deleted",deletedby:username}
           })
           .then((Response)=>{
            showToastMessage()
             // setSuccess(true)
             // hidesuccess();
              refreshData()
                    
           })
           .catch((error) => {
            if (error.code="ERR_BAD_RESPONSE"){
             
                    }
            
           })
          }
           }
           const showToastMessage2 = () => {
     
            toast.error('You dont have permission to perform this action', {
                position: toast.POSITION.TOP_CENTER
            });
          }; 
           const handleModalShowHide1=(id)=> {
            setDeleteId(id)
            setShowhide1(!showhide1);
            console.log("clicked");
          }
          const showToastMessage = () => {
            toast.success('Record deleted succesfully', {
                position: toast.POSITION.TOP_CENTER
            });
          }; 
if(isAuthenticated=="false"){
 return <Redirect to='/login'/>}
 const validateFields=(e)=>{
    e.preventDefault()
    if(name && date && branch &&  !branch.trim().length==0 &&
    branch!=="Select Branch"){
       
        handleSubmit()
      setValidated(true)}
    
    else{
      if(!name){
        setNameError(true)
       
        }
        if(!date){
            setDateError(true)
           
            }
        if(branch.trim().length==0 | branch=="Select Branch"){
          setBranchError(true)
          }
}}
 if(payment=="false"){
  return <Redirect to='/payment'/>}
  return (
    <div>
        <ToastContainer
       hideProgressBar={true}
      />
       <div className='path'><i className='fa fa-home'></i>{edit? '/ Products / Edit Item':'/ Products / Stocktakedetail List'}</div>
        <div className='bodysection col-lg-6'>
      
       <Modal show={showhide}>
           <Modal.Header closeButton onClick={closeModal}>
           <Modal.Title> New Product details</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
<FormGroup>

<Label>{name}</Label>
</FormGroup>

<FormGroup>
<Label>Branch</Label>
<Input type="text" placeholder="Enter Category"value={branch} onChange={e => setCategory(e.target.value)} name="photo"  required/>

</FormGroup>
<FormGroup>
<Label>d</Label>
<Input type="number" placeholder="Enter price"name="author" value={price} onChange={e => setPrice(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Quantity.</Label>
<Input type="number" placeholder="Enter price"name="author" value={quantity} onChange={e => setQuantity(e.target.value)} required />
</FormGroup>
<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       value={dateposted}
       required
     
     />
</FormGroup>
   
           <Button color="primary"className="mt-2 " type="submit" onClick={(e)=> handleSubmit(e)}>
Submit
</Button>  
<Button color="primary ml-2"className=" m-2 mt-3" type=""onClick={closeModal}>
Close
</Button>
</Form>

</Modal.Body>

       </Modal>
<div className=' col-lg-12 theader'>< h5>Stocktakedetail List</h5> </div>
 
           <div class=" bg-light ">
           <Form >
  <div className='row pl-3'>
 

<FormGroup className='mr-2 ml-2'>
<Label>{name}</Label>
<div className={nameError ?'errmessage1':'errmessagehide1'}>Item Name is Reguired</div>
</FormGroup>
<FormGroup className='mr-2 ml-2 '>
<Label>Branch: {branch}</Label>

<div className={branchError ?'errmessage1':'errmessagehide1'}>Branch is Reguired</div>
</FormGroup>

 
   </div>
  <div className='col-lg-6 ml-2 studenthealth' >
 
</div>
 
    </Form>
<ToolkitProvider
keyField="id"
data={[...data] }
columns={ columns }
exportCSV={{ onlyExportFiltered: true, exportAll: false }}
search
>
{
props => (
<div>
<ExportCSVButton { ...props.csvProps } className='btn btn-primary m-1'>Export </ExportCSVButton> 

<SearchBar { ...props.searchProps } />

<BootstrapTable keyField='id'
{...props.baseProps}
data={ data } 
columns={ columns } 
pagination={ paginationFactory(options) }className="pgnav"
wrapperClasses="table-responsive"
cellEdit={ cellEditFactory({ mode: 'click',blurToSave: true,afterSaveCell: (oldValue, newValue, row, column) => { 
 //alert(column)
 if(oldValue==newValue){
  return
  }else{
 axios({
  method:'put',
  url:`/api/stocktakedetail/${row.id}/`,
  data:{actualqty:newValue},
}).then((Response)=>{
 
  //clear fields
 showToastMessage1()
  refreshData()
})}

} }) 


}

>  

</BootstrapTable>
</div> 
)
}
</ToolkitProvider>
</div>
           </div>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Product?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting Product Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={deleteData}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username:state.auth.username1,
  isadmin:state.auth.isadmin,
  store:state.auth.store,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,fetchPayment})(Stocktakedetail)