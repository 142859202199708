import React, {useState,useEffect,useRef,useFocus} from 'react'
// Importing toastify module
import { ToastContainer, toast } from 'react-toastify'; 
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";
 import html2canvas from "html2canvas";
// Then import the virtualized Select HOC
import VirtualizedSelect from 'react-virtualized-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit,faTrash} from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Alert,
} from "reactstrap";
import Select1 from 'react-select'
import axios from "axios";
import  { encrypt , decrypt } from 'react-crypt-gsm';
import useKeyboardShortcut from 'use-keyboard-shortcut'
import { useSelect } from 'react-select-search';
import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation,useParams,NavLink,Link } from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import './Addstudent.css'
import SelectSearch from 'react-select-search';
import Select from 'react-dropdown-select'
import Barcode from 'react-barcode';
import BarcodeReader from 'react-barcode-reader'
const Pos=({props,isAuthenticated,checkAuthenticated,load_user,removeInvoice,store,isadmin,id,useremail,payment,match,username1})=> {
 
  const [showhide,setShowhide]=useState(false);
  const [customerFetch,setCustomerFetch]=useState(false);
  const [amountvisible,setAmountVisible]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [showhide1,setShowhide1]=useState(false);
  const [showhide2,setShowhide2]=useState(false);
  const [names, setName] = useState("");
  const [filteredinvoice, setFilteredInvoice] = useState("");
  const [name, setNames] = useState("");
  const [studentadm, setStudentadm] = useState("");
  const [studentadmError, setStudentadmError] = useState(false);
  const [first_name, setFname] = useState("");
  const [first_nameError, setFnameError] = useState(false);
  const [last_name, setLname] = useState("");
  const [last_nameError, setLnameError] = useState(false);
  const [data,setData]=useState([""]);
  const [dob, setDob] = useState("");
  const [dobError, setDobError] = useState(false);
  const [class_name, setCname] = useState("");
  const [class_nameError, setCnameError] = useState(false);
  const [dormitory, setDormitory] = useState("");
  const [dormitoryError, setDormitoryError] = useState(false);
  const [religion, setReligion] = useState("");
  const [religionError, setReligionError] = useState(false);
  const [adm_date, setAdmdate] = useState("");
  const [adm_dateError, setAdmdateError] = useState(false);
  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState(false);
  const [gender, setGender] = useState("");
  const [gender1, setGender1] = useState("Select Gender");
  const [genderError, setGenderError] = useState(false);
  const [county, setCounty] = useState("");
  const [countyError, setCountyError] = useState(false);
  const [health, setHealth] = useState("");
  const [healthError, setHealthError] = useState(false);
  const [parent_first_name, setPfname] = useState("");
  const [parent_first_nameError, setPfnameError] = useState(false);
  const [parent_last_name, setPlname] = useState("");
  const [parent_last_nameError, setPlnameError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [email,setEmail]=useState("");
  const [tendered,setTendered]=useState(0)
  const [emailError,setEmailError]=useState(false);
  const [mobile,setMobile]=useState("");
  const [mobileError,setMobileError]=useState(false);
  const [town,setTown]=useState("");
  const [townError,setTownError]=useState(false);
  const [id1,setId]=useState("");
  const [inumber,setInumber]=useState("hello");
  const [id2,setId2]=useState("");
  const [edit,setEdit]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [savebtn,setSavebtn]=useState(false);
  const [wait,setWait]=useState(false);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [dropopen,setDropOpen]=useState(false);
  const [dropdown,setDropDown]=useState("Unpaid");
  const [issuedate,setIssueDate]=useState("");
  const [duedate,setDueDate]=useState("");
  const [invoiceNumber,setInvoiceNumber]=useState('');
  const wrapper_ref = React.useRef();
  const wrapper_ref1 = React.useRef();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const refToLast = React.createRef();
  const refToLast1 = React.createRef();
  const [studentNumber,setSNumber]=useState('');
  const [studentnid,setSNumberid]=useState('');
  const [relation,setRelation]=useState('');
  const [relationError,setRelationError]=useState('');
  const [dorm,setDom]=useState('');
  const [dorm1,setDom1]=useState('');
  const [musyoki,setMusyoki]=useState('musyoki');
  const [inputList1,setInputList1]=useState([]);
  const [heading,setHeading]=useState(['Item     ', 'Qty','Vat','Price','Amount',''])
  const [InputList, setInputList] = useState([]);
  const [inputList, setinputList] = useState([]);
  const [notInternet,setNoInternet]=useState(false);
  const [print,setPrint]=useState(true);
  const [success,setSuccess]=useState(false);
  const [hide,setHide]=useState(false);
  const [quantity1,setQuantity1]= useState('');
  const [quantity,setQuantity]= useState('');
  const [month,setMonth]= useState('');
  const [year,setYear]= useState('');
  const [validated,setValidated]=useState(false);
  const [branch2,setBranch2]=useState([]);
  const [branch,setBranch]=useState([]);
  const [dateposted,setDateposted]=useState("");
  const [result,setResult]=useState(false);
  const [search,setSearch]=useState("");
  const [search1,setSearch1]=useState("");
  const [classstream,setCsream]=useState("");
  const [counter,setcounter]=useState(0);
  const [inputList2, setInputList2] = useState([]);
  const [deleteid,setDeleteId]=useState("");
  const [details,setDetails]=useState([""]);
  const [items,setItems]=useState([""]);
  const [detailsbkp,setDetailsbkp]=useState(['']);
  const [data1,setData1]=useState([""]);
  const [names2, setNames2] = useState("");
  const [paybill,setPaybill]=useState("");
  const [grandtotal,setGrandTotal]=useState('');
  const [valueState, setValueState] = useState('');
  const [valueState1, setValueState1] = useState('');
  const [branchError,setBranchError]=useState(false);
  const [options, setOptions] = useState( [
    { id: 1, country: "1 Blue" },
    { id: 2, country: "2 Blue" }, 
    { id: 3, country: "3 Blue" },
    { id: 4, country: "4 Blue" },
] )
const [inputStyle, setInputStyle] = useState( { })
const [inputStyle1, setInputStyle1] = useState( { })
const [inputStyle2, setInputStyle2] = useState( { })
const [inputStyle3, setInputStyle3] = useState( { })
const [inputStyle4, setInputStyle4] = useState( { })
const [show, setShow] = useState(false);

  const handleShow = () =>{
    setShow(true);
    //window.print()
  } 
  const handleClose = () =>{
    setShow(false)
    setInputList([])
    //window.print()
  } 
const [options1, setOptions1] = useState( [
  { id: 1, country: "Twiga" },
  { id: 2, country: "Ndovu" }, 
  { id: 3, country: "Kiboko" },
  { id: 4, country: "Fisi" },
] )

const [options2, setOptions2] = useState( [
  { id: 1, country: "Christian" },
  { id: 2, country: "Muslim" }, 
  { id: 3, country: "Hindu" },
  { id: 4, country: "Pagan" },
] )

const [options3, setOptions3] = useState( [
  { id: 1, country: "Boarding" },
  { id: 2, country: "Day" }, 
  
] )

const [options4, setOptions4] = useState( [
  { id: 1, country: "Male" },
  { id: 2, country: "Female" }, 
  
] )
const [options5, setOptions5] = useState( [
  { id: 1, country: "Father" },
  { id: 2, country: "Mother" }, 
  { id: 3, country: "Relative" }, 
  { id: 4, country: "Sponsor" }, 
  
] )
const [selectedOptions, setSelectedOptions] = useState([])
const [selectedOptions1, setSelectedOptions1] = useState([])
const [selectedOptions2, setSelectedOptions2] = useState([])
const [selectedOptions3, setSelectedOptions3] = useState([])
const [selectedOptions4, setSelectedOptions4] = useState([])
const [adminMenu, setadminMenu] = useState(false);
const options7 = ['One', 'Two', 'Three', 'Four', 'Five'];



  toast.configure()
  const showToast = () => toast("Operation succesful",{position: toast.POSITION.TOP_CENTER});
  const adminMenuClick = () => {
    setadminMenu(!adminMenu);
   
  }
  const handleCategoryChange1 = (event) => {
    setBranch2(event.target.value) 
    closeModal()
   // filterItems()
  } 
  const handleSelectChange = (value) => {
    
    setValueState(value);
    
    if(value){
      console.log(value.label.split(',').shift())
    }
   
  }
  const handleSelectChange1 = (value) => {
    
    setValueState1(value);
    
    if(value){
      console.log(value.label.split(',').shift())
      var updatedList = detailsbkp;
      updatedList = updatedList.filter(detail => detail.itemname.toLowerCase().includes(value.label.split(',').shift().toLowerCase()) )
      
      //set fields
      if(!edit){
        if(updatedList[0].quantity<1){
       
          showToastMessage1()
                  }else{
                  var updatedList1 
      updatedList1 = InputList.filter(detail => detail.name1.toLowerCase().includes(value.label.split(',').shift().toLowerCase()) )
      if (updatedList1.length>0){
showToastMessage7()
      }else{
        setInputList([...InputList, {id:updatedList[0].id, no:InputList.length+1,name1: updatedList[0].itemname, quantity1: 1,quantity:updatedList[0].quantity,vat:'16%',price1:updatedList[0].sprice}]);
        showToastMessage6()
      }}
    
    }
      
    }
   
  }
  const renderList=()=>{
    return (data.map(data =>({label:data.mobile+',' + data.name })))
  }
  const renderList1=()=>{
    return (items.map(data =>({label:data.itemname+', Qty ' + data.quantity+', Price ' + data.sprice })))
  }
  const handleModalShowHide=()=> {
    setSaveSuccess(false)
      setShowhide(true);
  }
  const showToastMessage = () => {

    toast.error('Select Products to sell', {
        position: toast.POSITION.TOP_CENTER
    });
  };
  const showToastMessage1 = () => {

    toast.error('You cant sell an item with zero quantity', {
        position: toast.POSITION.TOP_CENTER
    });
  };
  const showToastMessage4 = () => {

    toast.error('You dont have permission to perform this action', {
      position: toast.POSITION.TOP_CENTER
  });
  };
  const showToastMessage7 = () => {

    toast.error('Item already added. Increase the item quantity', {
      position: toast.POSITION.TOP_CENTER
  });
  }; 
  const showToastMessage2 = () => {

    toast.error('No Items to sell', {
        position: toast.POSITION.TOP_CENTER
    });
  };   
  const showToastMessage3 = () => {

    toast.success('Sell updated succesfully', {
        position: toast.POSITION.BOTTOM_CENTER
    });
  };     
  const showToastMessage5 = () => {

    toast.error('Operation not allowed on admin account', {
        position: toast.POSITION.BOTTOM_CENTER
    });
  };  
  const showToastMessage6 = () => {

    toast.success('Item added succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  }; 
  const handleScan=(data)=>{
    console.log(data)
    var updatedList = detailsbkp;
    updatedList = updatedList.filter(detail => detail.code.toLowerCase().includes(data.toLowerCase()) | detail.serial.toLowerCase().includes(data.toLowerCase()) )
    
    //set fields
    if(!edit){
      if(updatedList[0].quantity<1){
       
        showToastMessage1()
                }else
    setInputList([...InputList, {id:updatedList[0].id, no:InputList.length+1,name1: updatedList[0].itemname, quantity1: 1,quantity:updatedList[0].quantity,vat:'16%',price1:updatedList[0].sprice}]);
    }
      getTotal();
      showToastMessage6()
      setSearch("")
      scrollTo()
  }
 const fetchProducts=()=>{
 
  let data6 ;
  axios({
   method:'post',
   url:'/totalitem/',
   data:{useremail:useremail,isadmin:"false",store:isadmin=='true' && branch2.length>0? branch2.split(" ")[0]:store},
 })
 .then(res => {
     data6 = res.data;
     setItems(data6);
   setDetailsbkp(data6)
   console.log(res.data)
 })
 .catch(err => {})
 }
  useEffect(()=>{
 
    
      axios({
       method:'post',
       url:'/totalcustomer/',
       data:{useremail:useremail},
     })
     .then(res => {
          
         setData(res.data)
       
     })
     .catch(err => {}) 
//check if edit is true

const invoicenumber = /[^/]*$/.exec(window.location.href)[0];
//const result = invoicenumber.includes("-") && invoicenumber.length<40 ? true : false;
if(match.params.id>=0)

 {
  setResult(true)
  setSavebtn(false)
 }
   //fecth products
   
   fetchProducts()
   //checkAuthenticated();
   const reloadCount4 = sessionStorage.getItem('reloadCount4');
   if(reloadCount4 < 2) {
     sessionStorage.setItem('reloadCount4', String(reloadCount4 + 1));
     window.location.reload();
   } else {
     sessionStorage.removeItem('reloadCount4');
   }
   if (refToLast.current) {
   refToLast.current.focus();
   
   }
   axios({
    method:'post',
    url:'/totalstore/',
    data:{useremail:useremail},
   })
   .then(res => {
     
      setBranch(res.data)
      //setUnit(data5)
   })
   .catch(err => {})
      },[])
      useEffect(()=>{
        if(branch2.length>0){
         fetchProducts()
        }
     
       },[branch2])
       useEffect(()=>{
       
        if(branch2.length>0 && items.length>0){
          var updatedList = items;
          updatedList = updatedList.filter(detail =>(detail.store && detail.store==(branch2.split(' ')[0]) ))
          setItems(updatedList)
        }
      
       },[])
      useEffect(()=>{
       // alert('hello')
      fetchProducts() 
      fetchProducts() 
      },[InputList])
      useEffect(()=>{
        // alert('hello')
        setTimeout(() => {
          if(items.length>0){
            if(isadmin=="true"){
              handleModalShowHide()
            }
           
           }
        }, 5000)
      
        
         },[branch])
    
  const clearFields=()=>{
    setStudentadm('')
    setFname('')
    setLname('')
    setDob('')
    setSelectedOptions([])
    setSelectedOptions1([])
    setSelectedOptions2([])
    setSelectedOptions3([])
    setSelectedOptions4([])
    setCounty('')
    //setTown('')
    setAdmdate('')
    setHealth('')
    setPfname('')
    setPlname('')
    //setEmail('')
    setPhone('')
   
    

  }
 

   
        useEffect(()=>{
          getTotal();
          
            },[InputList]) 
            useEffect(()=>{
             if(show){
              //window.print()
             }
              
                },[show]) 
             //fetch school info
             useEffect(()=>{
    let data ;

    axios({
      method:'post',
      url:'/totalcompany/',
      data:{useremail:useremail},
    })
    .then(res => {
        data = res.data;
        setData1(data1);
        console.log(res.data)
         //set fields
    setNames2(data[0].names)
    setEmail(data[0].email)
    setMobile(data[0].mobile)
    setTown(data[0].town)
   setPaybill(data[0].till)
   //alert(town)
    })
    .catch(err => {})
  },[])
       
  if(isAuthenticated=="false"){
   return <Redirect to='/login'/>}
  
   if(payment=="false"){
   return <Redirect to='/payment'/>}
   

    const handleSubmit = (e) => {
      e.preventDefault()
     
      if(InputList.length==0){
      //alert("select customer,products and invoice details")
      showToastMessage2()
         }
         else
         if(useremail=="10")
         {
        showToastMessage4();
         } 
        
         else
      if(edit){
    
        //showToastMessage4()
      }
      else{
        if(branch2.length==0 && isadmin=="true"){
 
           handleModalShowHide()
             }else{
        setEditbtn(false)
      setSavebtn(false)
      setWait(true)
       //generate invoice id
       const unique_id = uuid();
       const small_id ="RCT-"+ unique_id.slice(0,8).toUpperCase();
       setInumber(small_id)  
      
       //save the sales details
       
       let cartLen = InputList.length;
    var newQuantity;
       for (let i = 0; i < cartLen; i++) {
          const invoicenumber1=small_id;
          const name1=InputList[i].name1;
          const quantity1=InputList[i].quantity1;
          if(InputList[i].quantity>0){
            newQuantity=InputList[i].quantity-quantity1
          }else{
            newQuantity=InputList[i].quantity
          }
          
          const total=InputList[i].price1;
          const id= InputList[i].id;
        
       axios({
        method:'post',
        url:'/api/sales1/',
        data:{serial:small_id,product:name1,amount:total,quantity:quantity1,postedby:username1,owner:useremail,store:isadmin=="true"?branch2.split(" ")[0]:store},
      })
      .then((Response)=>{
        if (Response.data){
          
                }
      })
      .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
         
                }
        
      })
    
      //edit quantity
      axios({
        method:'put',
        url:`/api/item/${id}/`,
        data:{quantity:newQuantity,owner:useremail},
      })
      .then((Response)=>{
        if (Response.data){
          
                }
      })
      .catch((error) => {
        if (error.code="ERR_BAD_RESPONSE"){
         
                }
        
      })
   
    }
    axios({
      method:'post',
      url:'/api/audit/',
      data:{username:username1,operation:'Sell inserted',table:'Sales',owner:useremail},
    })
    .then(res => {
       
    
    })
    .catch(err => {})
     //save payment
     if(!valueState)
     {
      
     }
     if(!tendered){
      
      setTendered(0)
     }
     axios({
       method:'post',
       url:'/api/amount/',
       data:{saleid:small_id,mobile:valueState?valueState.label.split(',').shift():11,total:tendered>= grandtotal?grandtotal:tendered,amount:grandtotal,owner:useremail,store:isadmin=="true"?branch2.split(" ")[0]:store},
     })
     .then((Response)=>{
      fetchProducts()
      //clear produts
      //setInputList([])
      fetchProducts()
      showToastMessage3()
    handleShow()
    if(print){
      onClick()
    }
   
    handleClose()
     })
     .catch((error) => {
       if (error.code="ERR_BAD_RESPONSE"){
        
               }
       
     })
     
   
  
  if(!notInternet)
  {
    clearFields();
  }
   
  
  
  //setEdit(false);
  setId('');
  //window.location.reload();
    }}}
    const onOptionChangeHandler = (event) => {
      setDom(event.target.value) 
  }   
  const onOptionChangeHandler1 = (event) => {
    setCname(event.target.value) 
}  

const onOptionChangeHandler2 = (event) => {
  setCategory(event.target.value) 
}  
const onOptionChangeHandler3 = (event) => {
  setGender(event.target.value) 
} 
const onOptionChangeHandler4 = (event) => {
  setRelation(event.target.value) 
} 
const onOptionChangeHandler5 = (event) => {
  setReligion(event.target.value) 
} 

const onOptionChangeHandler6 = (event) => {
  setCname(event.target.value) 
} 
const handleInputChange = (e, index) => {
  if(edit){
  const { name, value } = e.target;
  const list = [...inputList1];

  list[index][name] = value;
  setInputList1(list);
  }else{
    const { name, value } = e.target;
  const list = [...InputList];

  list[index][name] = value;
  setInputList(list);
  }
};
    const validateFields=(e)=>{
    
   e.preventDefault()
  
      if(inputList.length>0){
                 
        setValidated(true)}
        else{
      
                showToastMessage()
          }
    }
    const reLoadPage=()=>{
       
      window.location.reload();
  }
    const handleRemoveClick = (index,id )=> {
      const list = [...InputList];
      list.splice(index, 1);
      setInputList(list);
     
      getTotal();}
    const getTotal=()=>{
  
      const res=InputList.reduce((prev,item)=>{
        
          return prev +(item.price1*item.quantity1);
      },0)
      setGrandTotal(res)
      console.log(res);
      }
    const addProduct=(data)=>{
      console.log(data)

      //set fields
      if(!edit){
        if(data.quantity<1){
showToastMessage1()
        }else{
          var updatedList1 
          updatedList1 = InputList.filter(detail => detail.name1.toLowerCase().includes(data.itemname.toLowerCase()) )
          if (updatedList1.length>0){
    showToastMessage7()
          }else{
      setInputList([...InputList, {id:data.id, no:InputList.length+1,name1: data.itemname, quantity1: 1,quantity:data.quantity,vat:'16%',price1:data.sprice}]);
      showToastMessage6()
      if (refToLast.current) {
        refToLast.current.focus();
       }
       setSearch("")
        scrollTo()} 
    }
      
      //inputRefTwo.current.focus()
        getTotal();
        
       
   }}
    const filterList = (event) => {
      //search staff
    //alert("++")
      var updatedList = detailsbkp;
      updatedList = updatedList.filter(detail =>detail.itemname && detail.itemname.toLowerCase().includes(search.toLowerCase()))
      setItems(updatedList)
      
      };
      function printDiv() {
        const bodyElement = document.getElementsByTagName('body')[0];

    bodyElement.classList.add('printing');
    window.print();
    bodyElement.classList.remove('printing');
    handleClose()
   }
   const Capitalize=(str)=>{
    return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const onClick=(e)=>{
      const opt = {
         scale: 4
     }
     const elem = wrapper_ref.current;
     html2canvas(elem, opt).then(canvas => {
         const iframe = document.createElement('iframe')
         iframe.name = 'printf'
         iframe.id = 'printf'
         iframe.height = 0;
         iframe.width = 0;
         document.body.appendChild(iframe)
 
         const imgUrl = canvas.toDataURL({
             format: 'pdf',
             quality: '5.0'
         })
 
         const style=`
            
             width:32vw;
             background-color:#72A0C1 ;
             position:absolute;
             left:0:
             top:0;
             
         `;
 
         const url = `<img style="${style}" src="${imgUrl}"/>`;
         var newWin = window.frames["printf"];
      
          newWin.document.write(`<body onload="window.print()">${url}</body>`);
        
         newWin.document.close();
 
     });
   }
   
   const closeModal=()=> {
    setShowhide(false);
}
   function checkPress(e){
		if(e.key === "Enter"){
      var updatedList = detailsbkp;
      updatedList = updatedList.filter(detail => detail.itemname.toLowerCase().includes(search.toLowerCase()) )  
    
      if(updatedList[0].quantity<1){
        
        showToastMessage1()
                }else{
                  var updatedList1 
                  updatedList1 = InputList.filter(detail => detail.name1.toLowerCase().includes(search.toLowerCase()) )
                  if (updatedList1.length>0){
            showToastMessage7()
                  }else{
     setInputList([...InputList, {id:updatedList.id, no:InputList.length+1,name1: updatedList[0].itemname, quantity1: 1,quantity:updatedList.quantity,vat:'16%',price1:updatedList[0].sprice}]);
    setSearch("")
   scrollTo()}
   //ref2[0].current.focus()
    }
		}
	}
  function checkPress1(e){
		if(e.key === "Enter"){
     setSearch("")
   scrollTo1()
   if (refToLast.current) {
    refToLast.current.focus();
   }else{
    refToLast1.current.focus();
   }
  
		}
	}
  function scrollTo1() {
    const project = document.getElementById('search');
    project.scrollIntoView();
  }
  function scrollTo() {
    const project = document.getElementById('table');
    project.scrollIntoView({'block':'end'});
  }
  return (
    <div>
       <ToastContainer
       className='dontprint'
       hideProgressBar={true}
      />
      <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record {edit?"Updated":"Saved"} succesffully</div></div>
 
        <div className='bodysection80 col-lg-12 mr-2'>
        {saveSuccess || updateSuccess &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>operation succesfull...</span>}
      

<div className=' col-lg-12 theader1 d-flex justify-content-center dontprint mt-2'>< h5 className=''><b>SEJOPOS SOFTWARE LICENSED TO {names2.toUpperCase()}</b></h5> </div>
 <div className='row dontprint'>
  <div className='col '>
    {hide &&<div className='col-lg-6 pl-0'>

    <Select1
    ref={refToLast1}
       value={valueState1}
       defaultValue=""
       placeholder="Search Product name"
       options={renderList1()}
       onChange={ (value) => handleSelectChange1(value) }
       name="ColumnSelect"
       label=""
       isClearable
/>
    </div>}
 
<div class="mb-1 bg-light mt-1">

        
          <Table bordered className=" pl-0" responsive id='table'>
  <thead>
    <tr >
    <th style={{ fontSize: "16px", color:'#000000',minWidth:"120px" }}>
                    Item
                  </th>
                  <th style={{ fontSize: "16px", color:'#000000' }}>
                    Qty
                  </th>
                  <th style={{ fontSize: "16px", color:'#000000' }}>
                   Vat
                  </th>
                  <th style={{ fontSize: "16px", color:'#000000' }}>
                    Price
                  </th>
                  <th style={{ fontSize: "16px", color:'#000000',paddingRight:"2",maxWidth:"80px" }}>Amount</th>
                  <th style={{ fontSize: "16px", color:'#000000' }}>
                  
                  </th> 
    </tr>
  </thead>
  <span ></span>   
                <tbody>
                 
                {InputList.length==0? '':InputList.map((x, i) => {
                   return (
                  <tr key={x.id} >
                
                     <td className="col-4" ><Label className='mt-1'>{x.name1}</Label></td>
                     <td className="col-2 minqty" >    <Input
            type="number"
              className="ml10 cborder"
              name="quantity1"
   placeholder="Enter Quantity"
              value={x.quantity1}
              onChange={e =>{ handleInputChange(e, i);getTotal()}}
              onKeyDown={(e,i)=> checkPress1(e)}
              ref={ref2}
              autoFocus
            /></td>
<td className="col-2" >
<Label className='mt-1'>{x.vat}</Label></td>
            <td className="col-2" >
            <Input
                className="ml10 cborder"
                name="total"
     placeholder=""
                value={ x.price1}
                onChange={e => handleInputChange(e, i)}
              /></td>
               <td className="col-2" >
               <Input
              className="ml10 cborder"
              name="total"
   placeholder=""
              value={x.price1 && x.quantity1 && x.price1*x.quantity1}
              onChange={e => handleInputChange(e, i)}
            /></td>
                 <td className="col-1" >
                 <div className='rmleft '>
           
              { InputList.length > 0 && <i 
                className="fa fa-trash  mr10 mt-1 btn1  mt-1 "
                onClick={() => handleRemoveClick(i,x.id)}></i>}
              
            </div></td>     
                   </tr>
                   
                   )
                })}
                  
    </tbody>
 
   
           </Table> 
        
           </div>
           <div className='row ml-2 mt-2'>
           <FormGroup className='mr-2 ml-2 label2 mt-2'>
<Label >Total</Label>
<Input className='' type="text" placeholder="Total"value={grandtotal} onChange={e => setCsream(e.target.value)} name="email"  required/>

</FormGroup>
<FormGroup className='mr-2 ml-2 label2 mt-2'>
<Label>Amount Paid</Label>
<Input className='' type="text" placeholder="Tendered amount"value={tendered} onChange={e => setTendered(e.target.value)} name="email"  required/>

</FormGroup>
<FormGroup className='mt-1 mr-2 ml-2 label2 mt-2'>
<Label>Change</Label>
<Input className='' type="text" placeholder="Change"value={tendered>grandtotal?tendered-grandtotal:'0'} onChange={e => setCsream(e.target.value)} name="email"  required/>

</FormGroup>
<FormGroup className='mt-1 mr-2 ml-2 label2 mt-2'>
<Label>Balance</Label>
<Input className='' type="text" placeholder="Balance"value={tendered=="" | tendered>grandtotal?'0':grandtotal-tendered} onChange={e => setCsream(e.target.value)} name="email"  required/>

</FormGroup>
<FormGroup className='m-2 mt-3 mb-2'>
<Label></Label>

<Select1
       value={valueState}
       defaultValue=""
       placeholder="Select Customer"
       options={renderList()}
       onChange={ (value) => handleSelectChange(value) }
       name="ColumnSelect"
       label=""
       isClearable
/>
</FormGroup>
        </div>
        <FormGroup className='ml-4 pl-1 mt-2'> <Input   type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setPrint(e.target.checked)} required checked={print}/>Print Receipt</FormGroup><br/>
   
           <span id='search'></span>
          <span className='mb-1 ml-3 mt-2 floatright'><Button className='p-3 btn primary' onClick={handleSubmit}><i class="fa fa-diamond product1" aria-hidden="true"></i>Sell</Button></span>
  </div>
  {!hide &&<div className='col-md-6'>
    <Button onClick={(e)=> setHide(true)}className='mr-5 ml-3 mt-1'>Hide Items</Button>
    <div><FormGroup className='mr-5 ml-1 mt-1 col-md-10 '>
<input type="text" className='searchwidth' ref={refToLast} placeholder="Search Product..." name="town"onKeyDown={(e)=> checkPress(e)} value={search} onChange={e =>{ setSearch(e.target.value);filterList()}} onKeyUp={filterList} required />

   <BarcodeReader
   onScan={handleScan}
   />     
</FormGroup></div>

    <div className='productparent ml-3 row' >
    {items.length==0? <div>No Records found</div>:items.map(detail => (
      <div className='productsquare col' onClick={(e)=> addProduct(detail)}>
      <i class="fa fa-image product"></i>
    <span className='productname'>{detail.itemname && Capitalize(detail.itemname)}</span>
    <span className='price'>Kshs {detail.sprice}</span>
    <span className='price'>Stock: {detail.quantity}</span>
      </div>
      ))}
     </div>

  </div>}
 </div>
</div>
<div className=''>
<Modal
        className="invoice-modal px-5 centermodal2 pr-1"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className='d-flex justify-content-between dontprint'> 
         
          <div>
          <Button
            className="invoice-print-button"
            variant="contained"
            onClick={printDiv}
          >
            Print
          </Button>
          <Button
            className="invoice-print-button ml-1"
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>

          </div>
         
        </Modal.Header>
        <Modal.Body id="testInvoicePage" className='modal-body1'>
          <div className="d-flex flex-column justify-content-center pr-1"ref={wrapper_ref} id='sectiontoprint'>
            <div className="invoice-heading d-flex flex-column text-center printing">
             
              <h6 style={{ fontSize: "23px" }} className=" pb-0">
                {names2.toUpperCase() || "Salon Name"}
              </h6>
             
              <span>{town}</span>
              <span>{mobile}</span>
              <span>Sales Receipt</span>
            </div>
            
            <div className="d-flex justify-content-between">
              <span style={{fontSize:'14px'}}>
                <b>Receipt No:</b> {inumber}
              </span>
              <span style={{fontSize:'14px'}}>
                <b> Date:</b>{" "}
                {Date().toString().slice(0, 24) ||
                  "13 May 2021"}
              </span>
            </div>
          
            <div className=' col-lg-12 bg-dark'></div>
          <b>  <div className='row mb-1 btb'>
              <div className='col'>ITEM</div>
              <div className='col'>QTY</div>
              <div className='col'>PRICE</div>
              <div className='col'>AMOUNT</div>
            </div></b>
            <div className=' col-lg-12 bg-dark mb-1'></div>
            { InputList.map((x, i) => {
        return (
          <div className="box row  mr-2 dontwrap mb-1">
          <div className='col-lg-5'>
          
          <Label>{x.name1.toUpperCase()}
          </Label>
          </div>
          <div className='col'>
          <Label>{x.quantity1}x
          </Label>   
        
  </div>
           <div className='col'>
           <Label>{x.price1}
          </Label>
         </div>
           <div className='col text-alignme'>
           <Label>{ x.quantity1*x.price1}
          </Label>
          </div>
         
        </div>
        );
      })}
            <hr></hr>
            <div className='row dontwrap'>
              <div className='col'><b>Total</b></div>
              <div className='col-md-6 textalign mr-3'><b>{grandtotal}.00</b></div>
            </div>
            <hr></hr>
            <div className='row dontwrap'>
              <div className='col'><b>Received</b></div>
              <div className='col-md-6 textalign mr-3'><b>{tendered}.00</b></div>
            </div>
            <hr></hr>
            <div className='row dontwrap'>
              <div className='col'><b>Change</b></div>
              <div className='col-md-6 textalign mr-3'><b>{tendered>grandtotal? tendered-grandtotal:0}.00</b></div>
            </div>
            <hr></hr>
            <h6 className='ml-2'>Served by: {Capitalize(username1)}</h6>
            <h6 className='ml-auto mr-auto'>Thank you for shopping with us</h6>
            <h6 className='ml-auto mr-auto powered'>Powered by: SEJOPOS +254 701 833 970</h6>
          </div>
        </Modal.Body>
      </Modal>
     
      </div>
      <div className='col-lg-2'>
       <Modal show={showhide}className='col-lg-4 d-flex ml-auto mr-auto'>
           <Modal.Header closeButton onClick={closeModal}>
           <Modal.Title>Select Store</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
        
  { <FormGroup className='mr-2 ml-2 '>

<div style={{width:'220px'}}className={branchError &&'errorborder'} >
              
          <select onChange={handleCategoryChange1} className={branchError ? 'errorborder newselect1':'newselect'}>
 
 <option>{'Select Branch Name'}</option>
 { branch.map((option, index) => {
     return <option key={index} >
         {option.id + ' '+ option.name}
     </option>
 })}
</select>    
          
            </div>
<div className={branchError ?'errmessage1':'errmessagehide1'}>Branch is Reguired</div>
</FormGroup>}

<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       value={dateposted}
       required
     
     />
</FormGroup>
   
</Form>

</Modal.Body>

       </Modal>
       </div>
    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  store:state.auth.store,
  isadmin:state.auth.isadmin,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Pos))