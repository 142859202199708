export const LOGIN_SUCCESS='LOGIN_SUCCESS';
export const LOGIN_FAIL='LOGIN_FAIL'
export const USER_LOADED_SUCCESS='LOAD_USER_SUCCESS'
export const USER_LOADED_FAIL='LOAD_USER_FAIL'
export const AUTHENTICATED_SUCCESS='AUTHENTICATED_SUCCESS'
export const AUTHENTICATED_FAIL='AUTHENTICATED_FAIL'
export const LOGOUT='LOGOUT'
export const HOMERELOAD='HOMERELOAD'
export const HOMERELOADFALSE='HOMERELOADFALSE'
export const USER_SUCCESS='USER_SUCCESS'
export const IS_ACTIVE='IS_ACTIVE'
export const NOT_ACTIVE='NOT_ACTIVE'
export const INVOICENUMBER_SET='INVOICENUMBER_SET'
export const INVOICENUMBER_REMOVE='INVOICENUMBER_REMOVE'
export const USER_FETCHED='USER_FETCHED'
export const PASSWORD_RESET_SUCCESS='PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAIL='PASSWORD_RESET_FAIL'
export const PASSWORD_RESET_CONFIRM_SUCCESS='PASSWORD_RESET_CONFIRM_SUCCESS'
export const PASSWORD_RESET_CONFIRM_FAIL='PASSWORD_RESET_CONFIRM_FAIL'
export const PAYMENT_FAIL='PAYMENT_FAIL'
export const PAYMENT_SUCCESS='PAYMENT_SUCCESS'
export const REGISTER_FAIL='REGISTER_FAIL'
export const REGISTER_SUCCESS='REGISTER_SUCCESS'
