import React, {useState,useEffect,useRef} from 'react'
// Importing toastify module
import { ToastContainer, toast } from 'react-toastify';
 import './Newstaff.css'
 import './Recordresult.css'
 import validator from 'validator'
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import  { encrypt , decrypt } from 'react-crypt-gsm';
 // toast-configuration method,
 // it is compulsory method.
 import jsPDF from "jspdf";
 import "jspdf-autotable";

import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  
  
} from "reactstrap";
//import bcrypt from "bcrypt";
import axios from "axios";

import useKeyboardShortcut from 'use-keyboard-shortcut'
import bcrypt from 'bcryptjs'
import Select1 from 'react-select'
import {connect} from 'react-redux';
import { checkAuthenticated,load_user,removeInvoice,fetchPayment } from './../actions/auth';
import { Switch,Route,BrowserRouter as Router,Redirect,withRouter,useLocation} from 'react-router-dom';
import { Card,Modal,Table} from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { useHotkeys } from 'react-hotkeys-hook';
import { toBeRequired } from '@testing-library/jest-dom/dist/matchers';
import './Addstudent.css'

const Recordresult=({props,isAuthenticated,checkAuthenticated,load_user,removeInvoice,id,useremail,payment,match,username1})=> {
 
  const [name, setName]=useState();
  const [nameError,setNError]=useState(false);
  const [phone, setPhone]=useState();
  const [phoneError,setPhError]=useState(false);
  const [email, setEmail]=useState();
  const [emailError,setEError]=useState(false);
  const [username, setUsername]= useState();
  const [emailEMesaage, setEEMassage]= useState('');
  const [usernameEMessage, setUsernameEMessage]= useState('');
  const [passwordEMessage, setPasswordEMesaage]= useState('');
  const [password, setPassword]= useState('');
  const [cpassword, setCPassword]= useState('');
  const [usernameError,setUError]=useState(false);
  const [storeError,setStoeError]=useState(false);
  const [sellingprice, setSPrice]=useState();
  const [passwordError,setPError]=useState(false);
  const [editbtn,setEditbtn]=useState(false);
  const [edit,setEdit]=useState(false);
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [notInternet,setNoInternet]=useState(false);
  const [buyingprice,setBPrice]=useState();
  const [buyingPError,setBPError]=useState(false);
  const [savebtn,setSavebtn]=useState(true)
  const [wait,setWait]=useState(false)
  const [cpasswordError,setCPError]=useState(false)
  const [serial,setSerial]=useState();
  const [validated,setValidated]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [success,setSuccess]=useState(false);
  const [result,setResult]=useState(false);
  const [result1,setResult1]=useState(false);
  const [data, setData] = useState([])
  const [sales,setSales]=useState(false);
  const [home,setHome]=useState(false);
  const [apurchase,setApurchase]=useState(false);
  const [vpurchase,setVpurchase]=useState(false);
  const [apreturn,setApreturn]=useState(false);
  const [sitems,setSitems]=useState(false);
  const [products,setProducts]=useState(false);
  const [vitems,setVitems]=useState(false);
  const [pitems,setPitems]=useState(false);
  const [vsreturns,setVsreturns]=useState(false);
  const [asreturn,setAsreturn]=useState(false);
  const [aitem,setAitem]=useState(false);
  const [vitem,setVitem]=useState(false);
  const [eitem,setEitem]=useState(false);
  const [stocktake,setStocktake]=useState(false);
  const [reconcile,setReconcile]=useState(false);
  const [acategory,setAcategory]=useState(false);
  const [vcategory,setVcategory]=useState(false);
  const [ecategory,setEcategory]=useState(false);
  const [dcategory,setDcategory]=useState(false);
  const [ditem,setDitem]=useState(false);
  const [auom,setAuom]=useState(false);
  const [vuom,setVuom]=useState(false);
  const [euom,setEuom]=useState(false);
  const [duom,setDuom]=useState(false);
  const [vsreport,setVsreport]=useState(false);
  const [vpreport,setVpreport]=useState(false);
  const [vprreport,setVprreport]=useState(false);
  const [vmonitor,setVmonitor]=useState(false);
  const [vstore,setVstore]=useState(false);
  const [astore,setAstore]=useState(false);
  const [dstore,setDstore]=useState(false);
  const [arole,setArole]=useState(false);
  const [vrole,setVrole]=useState(false);
  const [drole,setDrole]=useState(false);
  const [auser,setAusser]=useState(false);
  const [vuser,setVusser]=useState(false);
  const [duser,setDusser]=useState(false);
  const [acustomer,setAcustomer]=useState(false);
  const [vcustomer,setVcustomer]=useState(false);
  const [ecustomer,setEcustomer]=useState(false);
  const [dcustomer,setDcustomer]=useState(false);
  const [asupplier,setAsupplier]=useState(false);
  const [vsupplier,setVsupplier]=useState(false);
  const [esupplier,setEsupplier]=useState(false);
  const [dsupplier,setDsupplier]=useState(false);
  const [astaff,setAstaff]=useState(false);
  const [vstaff,setVstaff]=useState(false);
  const [estaff,setEstaff]=useState(false);
  const [asallowance,setAsallowance]=useState(false);
  const [asdeduction,setAsdeduction]=useState(false);
  const [dstaff,setDstaff]=useState(false);
  const [aallowance,setAallowance]=useState(false);
  const [adeduction,setAdeduction]=useState(false);
  const [eallowance,setEallowance]=useState(false);
  const [vallowance,setVallowance]=useState(false);
  const [dallowance,setDallowance]=useState(false);
  const [vdeduction,setVdeduction]=useState(false);
  const [ededuction,setEdeduction]=useState(false);
  const [ddeduction,setDdeduction]=useState(false);
  const [aquote,setAquote]=useState(false);
  const [vquote,setVquote]=useState(false);
  const [glabel,setGlabel]=useState(false);
  const [aoadvance,setAoadvance]=useState(false);
  const [aaadvance,setAaadvance]=useState(false);
  const [vaadvance,setVaadvance]=useState(false);
  const [voadvance,setVoadvance]=useState(false);
  const [vop9,setVop9]=useState(false);
  const [vap9,setVap9]=useState(false);
  const [gpayroll,setGpayroll]=useState(false);
  const [vapayroll,setVapayroll]=useState(false);
  const [vopayroll,setVopayroll]=useState(false);
  const [vexpense,setVexpense]=useState(false);
  const [aexpense,setAexpense]=useState(false);
  const [purchases,setPurchases]=useState(false);
  const [expenses,setExpenses]=useState(false);
  const [reports,setReports]=useState(false);
  const [system,setSystem]=useState(false);
  const [payroll,setPayroll]=useState(false);
  const [contacts,setContact]=useState(false);
  const [users,setUsers]=useState(false);
  const [partialamount,setPartialAmount]=useState('');
  const [amountvisible,setAmountVisible]=useState(false);
  const [category, setCategory] = useState( [] );
  const [valueState, setValueState] = useState('');
  const [roles, setRoles] = useState( []);
const [categoryError,setCategoryError]=useState(false);
  const [serialError,setSerialError]=useState(false)
  const [unit,setUnit]=useState([
    { id: 1, country: "Male" },
    { id: 2, country: "Female" }, 
  ])
  const [unitError,setunitError]=useState(false)
  //const bcrypt = require('bcrypt');
  const handleSelectChange = (value) => {
    
    setValueState(value);
    
    if(value.length>0){
      console.log(value[0].label.split(',').shift())
    }
   
  }
  const renderList=()=>{
    return (data.map(data =>({label:data.id+' ' + data.name })))
  }
  const showToastMessage = () => {
    toast.success('Record saved succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };   
  const showToastMessage1 = () => {
    toast.success('Record updated succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };   
  const showToastMessage2 = () => {

    toast.error('You dont have permission to perform this action', {
        position: toast.POSITION.TOP_CENTER
    });
  }; 
  const removeErrors=()=>{
    setNError(false)
    setPError(false)
    setEError(false)
    setPError(false)
    setUError(false)
    setPhError(false)
  }
  const validateFields=(e)=>{
    e.preventDefault()
    if(name){
      setValidated(true)}
    
    else{
      if(!name){
        setNError(true)
       
        }
       
    }
  }
  function hidesuccess() {
    return new Promise(resolve => {
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    });
  }
  const clearFields=()=>{
    setName('')
    setUsername('')
    setEmail('')
    setPhone('')
   setCPassword('')
   setPassword('')
  }
 
  useEffect(()=>{
//check if edit is true
const itemnumber = /[^/]*$/.exec(window.location.href)[0];
//alert(match.params.id1)
let pid= match.params.id
 if(pid>=0)
 {
setResult1(true)
 setSavebtn(false)
 //alert('ok')
 }
     //fetch edit records when editing
     if(result1){
      
     //fetch Item records
     axios({
      method:'post',
      url:`/searchrole/`,
      data:{id:match.params.pid},
    })
    .then((Response)=>{
     const datareceived=Response.data;
     console.log(datareceived)
    //set fields
 
    setHome(datareceived[0].home);
    setApurchase(datareceived[0].apurchase);
    setVpurchase(datareceived[0].vpurchase);
    setApreturn(datareceived[0].apreturn);
    setSitems(datareceived[0].sitems);
   setVitems(datareceived[0].vitems);
    setPitems(datareceived[0].pitems);
    setVsreturns(datareceived[0].vsreturns);
    setAsreturn(datareceived[0].asreturn);
    setAitem(datareceived[0].aitem);
    setVitem(datareceived[0].vitem);
    setEitem(datareceived[0].eitem);
    setStocktake(datareceived[0].stocktake);
    setReconcile(datareceived[0].reconcile);
    setAcategory(datareceived[0].acategory);
    setVcategory(datareceived[0].vcategory);
    setEcategory(datareceived[0].ecategory);
    setDcategory(datareceived[0].dcategory);
    setDitem(datareceived[0].ditem);
    setAuom(datareceived[0].auom);
    setVuom(datareceived[0].vuom);
   setEuom(datareceived[0].euom);
    setDuom(datareceived[0].duom);
    setVsreport(datareceived[0].vsreport);
    setVpreport(datareceived[0].vpreport);
    setVprreport(datareceived[0].vprreport);
    setVmonitor(datareceived[0].vmonitor);
    setVstore(datareceived[0].vstore);
    setAstore(datareceived[0].astore);
    setDstore(datareceived[0].dstore);
    setArole(datareceived[0].arole);
    setVrole(datareceived[0].vrole);
    setDrole(datareceived[0].drole);
    setAusser(datareceived[0].auser);
    setVusser(datareceived[0].vuser);
    setDusser(datareceived[0].duser);
    setAcustomer(datareceived[0].acustomer);
    setVcustomer(datareceived[0].vcustomer);
    setEcustomer(datareceived[0].ecustomer);
    setDcustomer(datareceived[0].dcustomer);
    setAsupplier(datareceived[0].asupplier);
    setVsupplier(datareceived[0].vsupplier);
    setEsupplier(datareceived[0].esupplier);
    setDsupplier(datareceived[0].dsupplier);
    setAstaff(datareceived[0].astaff);
    setVstaff(datareceived[0].vstaff);
    setEstaff(datareceived[0].estaff);
    setAsallowance(datareceived[0].asallowance);
    setAsdeduction(datareceived[0].asdeduction);
    setDstaff(datareceived[0].dstaff);
    setAallowance(datareceived[0].aallowance);
    setAdeduction(datareceived[0].adeduction);
    setEallowance(datareceived[0].eallowance);
    setVallowance(datareceived[0].vallowance);
    setDallowance(datareceived[0].dallowance);
    setVdeduction(datareceived[0].vdeduction);
    setEdeduction(datareceived[0].ededuction);
    setDdeduction(datareceived[0].ddeduction);
    setAoadvance(datareceived[0].aoadvance);
    setAaadvance(datareceived[0].aaadvance);
    setVaadvance(datareceived[0].vaadvance);
    setVoadvance(datareceived[0].voadvance);
   setVop9(datareceived[0].vop9);
    setVap9(datareceived[0].vap9);
    setGpayroll(datareceived[0].gpayroll);
    setVapayroll(datareceived[0].vapayroll);
    setVopayroll(datareceived[0].vopayroll);
      })
     //set edit
     setEdit(true);
     setEditbtn(true)
    
       //set local invoice
      // setInvoiceNumber(invoicenumber)
      
     }else{
      
      
     }
//fetch category
let data5 ;
axios({
 method:'post',
 url:'/totalcategory/',
 data:{useremail:useremail},
})
.then(res => {
   data5 = res.data;
   setCategory(data5)
   //setUnit(data5)
})
.catch(err => {})


//fetch unit
let data7 ;
axios({
 method:'post',
 url:'/totalunit/',
 data:{useremail:useremail},
})
.then(res => {
   data7 = res.data;
   //setCategory(data5)
   setUnit(data7)
})
.catch(err => {})
/*  const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }*/
    axios({
      method:'post',
      url:'/totalstore/',
      data:{useremail:useremail},
     })
     .then(res => {
        data7 = res.data;
        //setCategory(data5)
        setData(data7)
     })
     .catch(err => {})

  },[])

  useEffect(()=>{
    
if(result1){
  //fetch Item records
  axios({
    method:'post',
    url:`/searchrole/`,
    data:{id:match.params.id},
  })
  .then((Response)=>{
   const datareceived=Response.data;
   console.log(datareceived)
  //set fields
  setName(datareceived[0].name);
  setHome(datareceived[0].home);
  setApurchase(datareceived[0].apurchase);
  setVpurchase(datareceived[0].vpurchase);
  setApreturn(datareceived[0].apreturn);
  setSitems(datareceived[0].apreturn);
 setVitems(datareceived[0].vitems);
  setPitems(datareceived[0].pitems);
  setVsreturns(datareceived[0].vsreturns);
  setAsreturn(datareceived[0].asreturn);
  setAitem(datareceived[0].aitem);
  setVitem(datareceived[0].vitem);
  setEitem(datareceived[0].eitem);
  setStocktake(datareceived[0].stocktake);
  setReconcile(datareceived[0].reconcile);
  setAcategory(datareceived[0].acategory);
  setVcategory(datareceived[0].vcategory);
  setEcategory(datareceived[0].ecategory);
  setDcategory(datareceived[0].dcategory);
  setDitem(datareceived[0].ditem);
  setAuom(datareceived[0].auom);
  setVuom(datareceived[0].vuom);
 setEuom(datareceived[0].euom);
  setDuom(datareceived[0].duom);
  setVsreport(datareceived[0].vsreport);
  setVpreport(datareceived[0].vpreport);
  setVprreport(datareceived[0].vprreport);
  setVmonitor(datareceived[0].vmonitor);
  setVstore(datareceived[0].vstore);
  setAstore(datareceived[0].astore);
  setDstore(datareceived[0].dstore);
  setArole(datareceived[0].arole);
  setVrole(datareceived[0].vrole);
  setDrole(datareceived[0].drole);
  setAusser(datareceived[0].auser);
  setVusser(datareceived[0].vuser);
  setDusser(datareceived[0].duser);
  setAquote(datareceived[0].aquote);
  setVquote(datareceived[0].vquote);
  setGlabel(datareceived[0].glabel);
  setAcustomer(datareceived[0].acustomer);
  setVcustomer(datareceived[0].vcustomer);
  setEcustomer(datareceived[0].ecustomer);
  setDcustomer(datareceived[0].dcustomer);
  setAsupplier(datareceived[0].asupplier);
  setVsupplier(datareceived[0].vsupplier);
  setEsupplier(datareceived[0].esupplier);
  setDsupplier(datareceived[0].dsupplier);
  setAstaff(datareceived[0].astaff);
  setVstaff(datareceived[0].vstaff);
  setEstaff(datareceived[0].estaff);
  setAsallowance(datareceived[0].asallowance);
  setAsdeduction(datareceived[0].asdeduction);
  setDstaff(datareceived[0].dstaff);
  setAallowance(datareceived[0].aallowance);
  setAdeduction(datareceived[0].adeduction);
  setEallowance(datareceived[0].eallowance);
  setVallowance(datareceived[0].vallowance);
  setDallowance(datareceived[0].dallowance);
  setVdeduction(datareceived[0].vdeduction);
  setEdeduction(datareceived[0].ededuction);
  setDdeduction(datareceived[0].ddeduction);
  setAoadvance(datareceived[0].aoadvance);
  setAaadvance(datareceived[0].aaadvance);
  setVaadvance(datareceived[0].vaadvance);
  setVoadvance(datareceived[0].voadvance);
 setVop9(datareceived[0].vop9);
  setVap9(datareceived[0].vap9);
  setGpayroll(datareceived[0].gpayroll);
  setVapayroll(datareceived[0].vapayroll);
  setVopayroll(datareceived[0].vopayroll);
    })
   //set edit
   setEdit(true);
   setEditbtn(true)
}
  },[result1])
  useEffect(()=>{
    //save 
if(validated){

//save staff
if(useremail=="4")
{
showToastMessage2();
}
else{
if(editbtn)
{

const editData = async () => {

const result = await axios({
method:'put',
url:`/api/roles/${match.params.id}/`,
data:{name,sitems,pitems,vitems,asreturn,apurchase,vpurchase,apreturn,home,aitem,vitem,ditem,
  eitem,stocktake,acategory,vcategory,ecategory,dcategory,reconcile,auom,euom,duom,vuom,vsreport,vpreport,vprreport,
  vmonitor,astore,vstore,dstore,arole,vrole,drole,auser,vuser,duser,acustomer,vcustomer,ecustomer,dcustomer,
  asupplier,vsupplier,esupplier,dsupplier,astaff,vstaff,estaff,dstaff,asallowance,asdeduction,aallowance,vallowance,
  eallowance,aquote,vquote,glabel,dallowance,adeduction,vdeduction,ededuction,ddeduction,aoadvance,aaadvance,voadvance,vaadvance,
  vop9,vap9,vsreturns,vapayroll,gpayroll,vopayroll,vexpense,aexpense,owner:useremail},
})
.then((Response)=>{
if (Response.data){
//setSaveSuccess(true)
setWait(false)
removeErrors()
showToastMessage1()
//setSuccess(true)
setValidated(false)
//hidesuccess();

     }
})
.catch((error) => {
if (error.code="ERR_BAD_RESPONSE"){

setWait(false)
setNoInternet(true)
     }

})
axios({
  method:'post',
  url:'/api/audit/',
  data:{username:username1,operation:'Role edited',table:'Roles',owner:useremail},
})
.then(res => {
   

})
.catch(err => {})
}
editData()
}else
{

  
const saveData = async () => {

 const encryptedStr = encrypt(password);

const result = await axios({
method:'post',
url:'/api/roles/',
data:{name,sitems,pitems,vitems,asreturn,apurchase,vpurchase,apreturn,home,aitem,vitem,ditem,
  eitem,stocktake,acategory,vcategory,ecategory,dcategory,reconcile,auom,euom,duom,vuom,vsreport,vpreport,vprreport,
  vmonitor,astore,vstore,dstore,arole,vrole,drole,auser,vuser,duser,acustomer,vcustomer,ecustomer,dcustomer,
  asupplier,vsupplier,esupplier,dsupplier,astaff,vstaff,estaff,dstaff,asallowance,asdeduction,aallowance,vallowance,
  eallowance,dallowance,adeduction,vdeduction,ededuction,ddeduction,aoadvance,aaadvance,voadvance,vaadvance,
  vop9,vap9,vsreturns,aquote,vquote,glabel,vapayroll,gpayroll,vopayroll,vexpense,aexpense,owner:useremail},
})
.then((Response)=>{
  
if (Response.data=='exists'){
 
  setUsernameEMessage('Username already Exists')
  setUError(true)
       }else{
       
        setSaveSuccess(true)
        setWait(false)
        setSavebtn(true)
        removeErrors()
        showToastMessage()
        //setSuccess(true)
        setValidated(false)
       //hidesuccess();
      clearFields()
      axios({
        method:'post',
        url:'/api/audit/',
        data:{username:username1,operation:'Role Inserted',table:'Roles',owner:useremail},
      })
      .then(res => {
         
      
      })
      .catch(err => {})
       }
})
.catch((error) => {
if (error.code="ERR_BAD_RESPONSE"){

 setWait(false)
 setNoInternet(true)
       }

})



}
saveData()
}
}
}
    
      },[validated]) 
const renderCategory=()=>{
return (category.map(data =>({label:data.bookid})))
  }
   if(payment=="false"){
    return <Redirect to='/payment'/>}
  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
       <div className={success? 'success row':'successhide row'}><div className='col-lg-3 mt-1'><i class="fa fa-check check green succcessicon" aria-hidden="true"></i></div><div className='col'>Record {edit?"Updated":"Saved"} succesffully</div></div>
       <div className='path'><i className='fa fa-home'></i>{edit? '/ Users / Add Role':'/ Users / Add Role'}</div>
        <div className='bodysection col-lg-6  '>
        {saveSuccess || updateSuccess &&<span className='pl-2' style={{'background-color':'#fcffa4','position':'absolute','left':'40%',top:'5px','border-radius':'5px'}}>operation succesfull...</span>}
       
<div className=' col-lg-12 theader'>< h5>Role Details</h5> </div>
<Form >
<FormGroup className='mr-2 ml-2'>
<Label>Role Name</Label>
<Input className={nameError &&'errorborder'} type="text" placeholder="Name"value={name} onChange={e => setName(e.target.value)} name="email"  required/>
<div className={nameError ?'errmessage':'errmessagehide2'}>Name is Reguired</div>
</FormGroup> 
  <div className='row pl-4 pr-4 mt-2 pt-4 pr-2'>
 
{amountvisible && <FormGroup className='mr-5 ml-3'>
<Label>Amount</Label>
<Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
</FormGroup>}

 {amountvisible && <FormGroup className='mr-5 ml-3'>
 <Label>Amount</Label>
 <Input type="text"style={{width:"150px",height:"37px",'border-color':"gainsboro"}} placeholder="Enter amount"name="author" value={partialamount} onChange={e => setPartialAmount(e.target.value)} required />
 </FormGroup>}
 <FormGroup className='mr-2 ml-4'>
<span className='ml-2'><b>Sales</b></span><br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setSitems(e.target.checked)} required checked={sitems}/>Sell Items<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVitems(e.target.checked)} required checked={ vitems}/>View Sales<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setPitems(e.target.checked)} required checked={pitems}/>Pay for Items<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVsreturns(e.target.checked)} required checked={vsreturns}/>View Sales Returns<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setAsreturn(e.target.checked)} required checked={asreturn}/>Add Sales Returns<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setAquote(e.target.checked)} required checked={aquote}/>Add Quote<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVquote(e.target.checked)} required checked={vquote}/>View Quotations<br/>
 
</FormGroup>
<FormGroup className='mr-2 ml-4'>
<span className='ml-2'><b>Purchases</b></span><br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setApurchase(e.target.checked)} required checked={apurchase}/>Add Purchase<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVpurchase(e.target.checked)} required checked={vpurchase}/>View Purchases<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setApreturn(e.target.checked)} required checked={apreturn}/>Add P. Returns<br/>
 
</FormGroup>

<FormGroup className='mr-2 ml-4'>
<span className=''><b>Home</b></span><br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setHome(e.target.checked)} required checked={home}/>View Home<br/>
 
</FormGroup>
<div className=' col-lg-12 ml-0  bg-grey '></div>
<FormGroup className='mr-2 ml-4'>
<span className='ml-2'><b>Products</b></span><br/>
<div className='row'>
 <div className='col'>
   <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setAitem(e.target.checked)} required checked={aitem}/>Add Items<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVitem(e.target.checked)} required checked={vitem}/>View Items<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setEitem(e.target.checked)} required checked={eitem}/>Edit Items<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setDitem(e.target.checked)} required checked={ditem}/>Delete Items<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setStocktake(e.target.checked)} required checked={stocktake}/>Do Stocktake<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setReconcile(e.target.checked)} required checked={reconcile}/>Reconcile Stock<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setAcategory(e.target.checked)} required checked={acategory}/>Add Category<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVcategory(e.target.checked)} required checked={vcategory}/>View Category<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setGlabel(e.target.checked)} required checked={glabel}/>Print Labels<br/>
 
 </div> 
 <div className='col'>
  <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setEcategory(e.target.checked)} required checked={ecategory}/>Edit Category<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setDcategory(e.target.checked)} required checked={dcategory}/>Delete Category<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setAuom(e.target.checked)} required checked={auom}/>Add U.O.M<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVuom(e.target.checked)} required checked={vuom}/>View U.O.M<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setEuom(e.target.checked)} required checked={euom}/>Edit U.O.M<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setDuom(e.target.checked)} required checked={duom}/>Delete U.O.M<br/>
 </div>
 </div>
</FormGroup>

<FormGroup className='mr-2 ml-4'>
<span className='ml-2'><b>Reports</b></span><br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVsreport(e.target.checked)} required checked={vsreport}/>View Sales Report<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVpreport(e.target.checked)} required checked={vpreport}/>View P. Report<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVprreport(e.target.checked)} required checked={vprreport}/>View Profit Report<br/>
 
</FormGroup>
<div className=' col-lg-12 ml-0  bg-grey '></div>
<FormGroup className='mr-2 ml-4'>
<span className='ml-2'><b>System</b></span><br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVmonitor(e.target.checked)} required checked={vmonitor}/>View Access Monitor<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setAstore(e.target.checked)} required checked={astore}/>Add Store<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVstore(e.target.checked)} required checked={vstore}/>View Stores<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setDstore(e.target.checked)} required checked={dstore}/>Delete Stores<br/>
 
</FormGroup>
<FormGroup className='mr-2 ml-4'>
<span className='ml-2'><b>Users</b></span><br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setArole(e.target.checked)} required checked={arole}/>Add Role<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVrole(e.target.checked)} required checked={vrole}/>View Roles<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setDrole(e.target.checked)} required checked={drole}/>Delete Role<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setAusser(e.target.checked)} required checked={auser}/>Add User<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVusser(e.target.checked)} required checked={vuser}/>View User<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setDusser(e.target.checked)} required checked={duser}/>Delete User<br/>
 
</FormGroup>
<FormGroup className='mr-2 ml-4'>
<span className='ml-2'><b>Contacts</b></span><br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setAcustomer(e.target.checked)} required checked={acustomer}/>Add Customers<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVcustomer(e.target.checked)} required checked={vcustomer}/>View Customers<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setEcustomer(e.target.checked)} required checked={ecustomer}/>Edit Customers<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setDcustomer(e.target.checked)} required checked={dcustomer}/>Delete Customers<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setAsupplier(e.target.checked)} required checked={asupplier}/>Add Suppliers<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVsupplier(e.target.checked)} required checked={vsupplier}/>View Suppliers<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setEsupplier(e.target.checked)} required checked={esupplier}/>Edit Suppliers<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setDsupplier(e.target.checked)} required checked={dsupplier}/>Delete Suppliers<br/>

</FormGroup>
<div className=' col-lg-12 ml-0  bg-grey '></div>
<FormGroup className='mr-2 ml-4'>
<span className='ml-2'><b>Payroll & Hr</b></span><br/>
<div className='row'>
 <div className='col'>
   <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setAstaff(e.target.checked)} required checked={astaff}/>Add Employee<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVstaff(e.target.checked)} required checked={vstaff}/>View Employee<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setEstaff(e.target.checked)} required checked={estaff}/>Edit Employee<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setAsallowance(e.target.checked)} required checked={asallowance}/>Assign Allowances<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setAsdeduction(e.target.checked)} required checked={asdeduction}/>Assign Deducts<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setDstaff(e.target.checked)} required checked={dstaff}/>Delete Employee<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setAallowance(e.target.checked)} required checked={aallowance}/>Add Allowances<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVallowance(e.target.checked)} required checked={vallowance}/>View Allowances<br/>

 </div> 
 <div className='col'>
  <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setEallowance(e.target.checked)} required checked={eallowance}/>Edit Allowances<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setDallowance(e.target.checked)} required checked={dallowance}/>Delete Allowances<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setAdeduction(e.target.checked)} required checked={adeduction}/>Add Deductions<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVdeduction(e.target.checked)} required checked={vdeduction}/>View Deductions<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setEdeduction(e.target.checked)} required checked={ededuction}/>Edit Deductions<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setDdeduction(e.target.checked)} required checked={ddeduction}/>Delete Deducts<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setAoadvance(e.target.checked)} required checked={aoadvance}/>Apply own Advance<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setAaadvance(e.target.checked)} required checked={aaadvance}/>Apply all Advance<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVoadvance(e.target.checked)} required checked={voadvance}/>View own Advance<br/>
 
 </div>
 <div className='col'>
  <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVaadvance(e.target.checked)} required checked={vaadvance}/>View all Advances<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVop9(e.target.checked)} required checked={vop9}/>View own P9<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVap9(e.target.checked)} required checked={vap9}/>View all P9<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setGpayroll(e.target.checked)} required checked={gpayroll}/>Generate Payroll<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVopayroll(e.target.checked)} required checked={vopayroll}/>View own Payslip<br/>
 <Input  type="checkbox" placeholder="Quantity"name="author"  onChange={(e) => setVapayroll(e.target.checked)} required checked={vapayroll}/>View all Payslips<br/>
 
 </div>
 </div>
</FormGroup>


   </div>
  
 
      <div className='row pl-4'>
  <Button color="primary"className=" ml-2 mb-3 mt-4" type="submit"onClick={(e)=> validateFields(e)}>
      {editbtn && "Update"}
      {savebtn && !editbtn && "Submit"}
      {wait && " Please wait.."}
    </Button>  
    
    </div>
    </Form>
       
</div>

    </div>
  )
}

const mapStateToProps=state=>({
  id:state.auth.id,
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  username1:state.auth.username1,
  payment:state.auth.payment
})

export default withRouter(connect(mapStateToProps,{checkAuthenticated,load_user,removeInvoice,fetchPayment})(Recordresult))