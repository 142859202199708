import React, {useState,useEffect,useRef,useLocation} from 'react'
import './Dashboard.css';
import { checkAuthenticated,load_user,login,fetchPayment,reloadHome,reloadHomeFalse } from './../actions/auth';
import {Redirect} from 'react-router-dom';
import { NavLink,BrowserRouter as Router,Route,Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import { FaAccessibleIcon} from 'react-icons/fa';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  Table } from 'react-bootstrap';
import { CChart } from '@coreui/react-chartjs'
import { faHome,faGraduationCap,faUserFriends,faBox,faBuilding,faPallet,faProcedures,faUserCircle,faWallet } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
function Dashboard({isAuthenticated,checkAuthenticated,username,home,useremail,user1,email1,payment,fetchPayment,isstaff,isparent,isadmin,homereload,store}) {
  const [totalInvoice, setTotalInvoice] = useState([]);
  const [loading,setLoading]=useState(false);
  const [sales, setSales] = useState();
  const [todaysales, setTodaySales] = useState();
  const [stock, setStock] = useState();
  const [amount, setAmount] = useState();
  const [names2, setNames2] = useState();
  const [names, setNames] = useState();   
  const [idno, setIdno] = useState();
  const [kra, SetKra] = useState();
  const [empno, SetEmpno] = useState();
  const [users, setUsers] = useState([]);
  const [gender, setGender]= useState([])
  const [stNum, setStNum]= useState([])
  const [staffGender, setStaffGender]= useState([])
  const [staffGender1, setStaffGender1]= useState([])
  const [heading,setHeading]=useState(['Dorm Name','Action']);
  const [detailsbkp,setDetailsbkp]=useState('');
  const [data1,setData1]=useState([""]);
  const [data3,setData3]=useState([""]);
  const [classes, setClasses]= useState([])
  const history = useHistory();
 
  const data2 = {
    labels: ['Mon','Tue','Wed','Thurs','Fri'],
    datasets: [
        {
            label: 'Attendance for Week 1',
            data: [25,24,25,25,3],
            borderColor: ['rgba(255,206,86,0.2)'],
            backgroundColor: ['rgba(232,99,132,1)',
            'rgba(232,211,6,1)',
            'rgba(54,162,235,1)',
            'rgba(255,159,64,1)',
            'rgba(153,102,255,1)' ],
            pointBackgroundColor: 'rgba(255,206,86,0.2)',
        }

    ]
}


const data = {
  options: {
    legend: {
      display: '',
    },
  },
  labels: classes,
  datasets: [
    {
      backgroundColor: ['#72A0C1','#1560bd','#008E97','#1034A6','#003399','#073980', '#7CB9E8','#6CB4EE','#6CB4EE','#6699CC','#318CE7','#0a2351','#00BFFF','#034694'],
      data: stNum,
      
      
    }


// Sample data
  ]}

  const reLoadPage=()=>{
       
    window.location.reload();
} 

  useEffect(()=>{
    history.push('/', { from: '/', someOtherProp: '' }) 
  if(isAuthenticated=="false"){
    
   window.location.href = "/login";
  }
  if(isparent=="true"|isstaff=="true"){
    
    //window.location.href = "/payroll";
   }
   //window.location.reload();
  },[]) 
  useEffect(()=>{
    if(useremail){
    //  alert('hello')
    const invoicenumber="99999"
    checkAuthenticated();
    fetchPayment(useremail)
    fetchAllSales()
    //fetch total items
    axios({
      method:'post',
      url:'/totalitem/',
      data:{useremail:useremail,isadmin,store},
    })
    .then((Response)=>{
       //filter record
     const datareceived=Response.data
     setTotalInvoice(datareceived)
     console.log(datareceived)
    
      })

//get total sales
axios({
  method:"post",
  url:"/totalsales/",
  data:{useremail:useremail,isadmin,store}
})
.then((result)=>{
  console.log(result.data)
  setStaffGender(result.data)
}

)

//get todaysales
axios({
  method:"post",
  url:"/todaysales/",
  data:{owner:useremail,isadmin,store}
})
.then((result)=>{
  console.log(result.data)
  setStaffGender1(result.data)
}

)
 //get total Users 
axios({
  method:"post",
  url:"/totalusers/",
  data:{owner:useremail,isadmin,store}
})
.then((result)=>{
  console.log(result.data)
  setUsers(result.data)
}

) 
if(isstaff=="true"){
    
  let data1 ;
 
  axios({
    method:'post',
    url:'/searchstaffs/',
    data:{useremail:useremail,id:username},
  })
  .then(res => {
      data1 = res.data;
      console.log(res.data)
      setNames2(data1[0].firstname)
      setNames(data1[0].lastname)
      setIdno(data1[0].idnumber)
      SetKra(data1[0].kra)
      SetEmpno(data1[0].tscnumber)
  })
  .catch(err => {})
 }    
    }
      },[useremail]) 
      useEffect(()=>{

 if(staffGender.length!=0){
 //calculate total sales

 const res=staffGender.reduce((prev,item)=>{
        

  return prev +(item.amount*item.quantity);
},0)
setSales(res?res:0) 
 }
 
      },[staffGender]) 
      useEffect(()=>{

        if(staffGender.length!=0){
        //calculate total sales
       
        const res=staffGender.reduce((prev,item)=>{
               
       
         return prev +(item.amount*item.quantity);
       },0)
       setSales(res?res:0) 
        }
        
             },[staffGender]) 
             useEffect(()=>{
               
              if(staffGender1.length!=0){
               
              //calculate today sales
              const currentDate = new Date().toLocaleDateString('en-GB')
              var new_str = currentDate.replace(/-/g, '');
              const newlist = staffGender1.filter(detail => detail.created_at.replace(/-/g, '/').includes(currentDate) )
              const newlist1=staffGender1.reduce((prev,item)=>{
               return prev +(item.amount*item.quantity);
             },0)
             setTodaySales(newlist1?newlist1:0) 
             
              }
              
                   },[staffGender1])
      useEffect(()=>{

        if(totalInvoice.length!=0){
        //calculate total stock
        const res=totalInvoice.reduce((prev,item)=>{
               
         return prev +(item.bprice*item.quantity);
       },0)
       setStock(res?res:0)
        }
        
             },[totalInvoice]) 
      const getTotalBalances1=(inv)=>{

      }
      useEffect(()=>{
checkAuthenticated()

      },[isAuthenticated])
  
      useEffect(()=>{
    
        //fetch total payment
        
       fetchAllPayment()
      
     /*  const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }*/
         },[]) 
         useEffect(()=>{
        
            
           
             },[data1]) 
         const fetchAllPayment=async()=>{
          
          let data4 ;
    
         const fee= await axios({
           method:'post',
           url:'/totalsales3/',
           data:{useremail:useremail,isadmin,store},
         })
         .then(res => {
             data4 = res.data;
             setData1(data4);
             const total=(data4.reduce((total,currentItem) =>  total = total + currentItem.amount , 0 ));
             console.log(data4) 
             setDetailsbkp(total);
             setLoading(false)
         })
         .catch(err => {})
       
    }
    const fetchAllSales=()=>{
      axios({
        method:'post',
        url:'/totalsales/',
        data:{useremail:useremail,isadmin,store},
      })
      .then((Response)=>{
         //set record
       const datareceived=Response.data
       setData3(datareceived)
      
        })}
        const getTotalInvoice=(inv)=>{
         
          const res=data3.reduce((prev,item)=>{
                 
            return prev +(item.price*item.quantity);
          },0)
          return res;
        } 

 if(payment=="false"){
  
  /*return <Redirect to='/payment'/>*/}
 
 
  return (
    <div>
{isstaff=="true"|home=="false" &&isadmin=="false"?<div className='bodysection3 col-lg-10 '>
<h4 className='mt-3'>Dashboard</h4>

<div class="  d-flex   row">
  <div class=" col ">
    <div class="card-body pl-1">
    <Router>
   <ul >
    <li className='divborder1 setbg pl-2'>Quick Menu</li>
    <li onClick={reLoadPage}className='divborder1 pl-2 '><NavLink exact to='/advances' activeClassName="" >Apply Advance</NavLink></li>
    <li onClick={reLoadPage}className='divborder1 pl-2 '><NavLink  to='/payroll' activeClassName="" >View Payslips</NavLink></li>
   </ul>
   </Router>
    </div>
  </div>
  <div class=" ml-1 col-md-9 mt-1 col ">
    <div class="card-body ">
    <h4 class="card-title"><b>Account Details</b> </h4>
    {isstaff=="true"&& <h5 class="card-title">Names: {names +" "+ names2}</h5>}
    {isstaff=="false"&& <h5 class="card-title">Name: {user1}</h5>}
   {isstaff=="false"&& <h5 class="card-title">Email.: {email1}</h5>}
    <h5 class="card-title">ID No.: {idno}</h5>
    <h5 class="card-title">Emp. No.: {empno}</h5>
    <h5 class="card-title">Kra Pin: {kra}</h5>
    </div>
  </div>
 
  
</div>

</div>:
<div className='bodysection3 col-lg-10 '>
<h4 className='mt-3'>Dashboard</h4>
<div className='row no-gutters '>
<div className='col orderscol1 d-flex align-items-center justify-content-center p-3 mobilecenter salesdiv'>
<div className='ordersrow1 row  '>
  <div className='col-lg-2 d-flex align-items-center justify-content-center mr-5 mobile '>
  
  <FontAwesomeIcon icon={faBox} className="mobile stbg p-3 ml-2 "/>
             
  </div>
  <div className='ordersnumber col mobilediv'><h5>{'Sales'}</h5>
  <div className='ordertext'><h5 className='salestext'>Kshs. {sales}</h5></div>
  </div>
  </div>
 </div>
 <div className='col orderscol1 d-flex align-items-center justify-content-center p-3 mobilecenter stockdiv'>
<div className='ordersrow1 row'>
  <div className='col-lg-2 d-flex align-items-center justify-content-center mr-5'>
  
  <FontAwesomeIcon icon={faProcedures} className="mobile stbg1 p-3 ml-4"/>
  </div>
  <div className='ordersnumber col mobilediv'><h5 >{'Stock'}</h5>
  <div className='ordertext'><h5 className='stocktext' >Kshs. {stock}</h5></div>
  </div>
  </div>
 </div>
 
 <div className='col orderscol d-flex align-items-center justify-content-center p-3 mobilecenter usersdiv'>
<div className='ordersrow row'>
  <div className='col-lg-2 d-flex align-items-center justify-content-center mr-5'>
 

  <FontAwesomeIcon icon={faUserCircle}className="mobile stbg3 p-3" />
  </div>
  <div className='ordersnumber col mobilediv'><h5>{'All Users'}</h5>
  <div className='ordertext'><h5 className='userstext'>{users.length}</h5></div>
  </div>
  </div>
 </div>
 <div className='col orderscol d-flex align-items-center justify-content-center p-3 mobilecenter itemsdiv'>
<div className='ordersrow row'>
  <div className='col-lg-2 d-flex align-items-center justify-content-center mr-5 mobile'>
 

  <FontAwesomeIcon icon={faWallet}className=" stbg2 p-3 mobile" />
  </div>
  <div className='ordersnumber col mobilediv'><h5>{'Items'}</h5>
  <div className='ordertext'><h5 className='itemstext'>{totalInvoice.length}</h5></div>
  </div>
  </div>
 </div>
</div>
<div class="  d-flex  mt-5  row">
  <div class=" col divborder1">
    <div class="card-body ">
    <h4 class="card-title">Top Ten fast moving products</h4>
    <div class="table-responsive bg-light">
<Table className=" pl-0">
  
 
                <tbody>     
                {data1.length==0? <div>No Records found</div>:data1.map(detail => (
                  <tr key={detail.id} >
                
                     <td className="col-2" >{ detail.product}</td>
                     <td className="col-2" >Kshs. { detail.amount}</td>
                     
                   </tr>
             ))}
    </tbody>
    
     
           </Table> 
           
           </div>
    </div>
  </div>
  <div class=" ml-1 col-md-3 mt-1 mb-5 col divborder1">
    <div class="card-body ">
    <h4 class="card-title">Today's Sales </h4>
    <h5 class="card-title">Today's Sales Total: {todaysales>0? todaysales:'0'}</h5>
    </div>
  </div>
 
  
</div>

</div>}

    </div>
  )
}
const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  isstaff:state.auth.isstaff,
  isparent:state.auth.isparent,
  isadmin:state.auth.isadmin,
  store:state.auth.store,
  payment:state.auth.payment,
  homereload:state.auth.homereload,
  home:state.auth.home,
  user1:state.auth.user1,
  username1:state.auth.username1,
  email1:state.auth.email1,
  username:state.auth.username1
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,fetchPayment,reloadHome,reloadHomeFalse})(Dashboard)