import React,{useEffect,useState} from 'react'
import axios from "axios";
import { Table } from 'react-bootstrap';
import { Card,Modal} from 'react-bootstrap';
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import {Redirect,Link} from 'react-router-dom';
import {connect} from 'react-redux';
import ToolkitProvider, { CSVExport,Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import TableHeaderColumn from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { ToastContainer, toast } from 'react-toastify';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
function Createinv({isAuthenticated,checkAuthenticated,asupplier,username1,useremail,payment,fetchPayment,esupplier,dsupplier,isadmin}) {
  const [data,setData]=useState([""]);
  const [names,setName]=useState("");
  const [email,setEmail]=useState("");
  const [mobile,setMobile]=useState("");
  const [town,setTown]=useState("");
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [id,setId]=useState("");
  const [edit,setEdit]=useState(false);
  const [showhide,setShowhide]=useState(false);
  const [heading,setHeading]=useState(['Names', 'Email', 'Mobile','Town','Action']);
  const [deleteid,setDeleteId]=useState("");
  const [showhide1,setShowhide1]=useState(false);
  const { ExportCSVButton } = CSVExport;
   const { SearchBar } = Search;
   const options = {
     page: 1,
     sizePerPage: 10,
     nextPageText: '>',
     prePageText: '<',
     showTotal: true
   };
   const closeModal1=()=> {
    setShowhide1(false);
    
    }
   const columns = [{
     dataField: 'name',
     text: 'Name',
     headerStyle: () => {
       return { width: "150px" };
     }
   }, {
     dataField: 'email',
     text: 'Email',
     headerStyle: () => {
       return { width: "200px" };
     }
   } 
   , {
    dataField: 'mobile',
    text: 'Mobile',
    headerStyle: () => {
      return { width: "100px" };
    }
  },
   {
    dataField: 'town',
    text: 'Town',
    headerStyle: () => {
      return { width: "100px" };
    }
  }
 , {
     dataField: "id",
     text: "Action",
     headerStyle: () => {
       return { width: "100px" };
     },
     csvExport: false,
     editable: false,
     formatter: (cellContent, row) => {
       return (
         <div>
       <div className='row '><div className="border">
                      {isadmin=="true"|esupplier=="true" ?<span className=''><i  class="fa fa-edit btn1"onClick={() => editItem(row)} ></i></span>:""}</div>{isadmin=="true"|dsupplier=="true" ?<span className='border'><i class="fa fa-trash btn1 border1" onClick={e =>{ handleModalShowHide1(row.id)}}></i></span>:""}</div>
       </div>
       );
     },
 },];
  useEffect(()=>{
    let data1 ;

    axios({
      method:'post',
      url:'/totalsupplier/',
      data:{useremail:useremail},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
      console.log(data1)
    })
    .catch(err => {})
    checkAuthenticated();
    fetchPayment(useremail)
    
      if(!payment){
        return <Redirect to='/payment'/>}
      },[]) 
      const handleModalShowHide=()=> {
        setShowhide(!showhide);
    }
    const closeModal=()=> {
      setShowhide(false);
  }
  const refreshData=()=>{
    let data1 ;

    
    axios({
      method:'post',
      url:'/totalsupplier/',
      data:{useremail:useremail},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
       
    })
    .catch(err => {})
    
  }
  const handledate = () => {
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return date;
  }
  const handleSubmit = (e) => {

    if(!names|!email|!mobile|!town){
     
       }else
    if(id){
      e.preventDefault();
    
      axios({
        method:'put',
        url:`/api/supplier/${id}/`,
        data:{name:names.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),email:email.toLowerCase(),mobile,town:town.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),handledate},
      }).then((Response)=>{
        setEdit(false);
        setId('');
        //clear fields
    cleaFields();
      })
      setUpdateSuccess(true)
      axios({
        method:'post',
        url:'/api/audit/',
        data:{username:username1,operation:'Supplier edited',table:'Suppliers',owner:useremail},
      })
      .then(res => {
         
      
      })
      .catch(err => {})
    }
    else{
     
   axios({
     method:'post',
     url:'/api/supplier/',
     data:{name:names.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),email:email.toLowerCase(),mobile,town:town.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase()),owner:useremail,handledate},
   }).then((Response)=>{
    //clear fields
    cleaFields();
   })
   axios({
    method:'post',
    url:'/api/audit/',
    data:{username:username1,operation:'Supplier inserted',table:'Suppliers',owner:useremail},
  })
  .then(res => {
     
  
  })
  .catch(err => {})
   setSaveSuccess(true)
  }
  };
  const editItem = (detail) => {
    setId(detail.id);
    setEdit(true);
    setShowhide(true);
    setName(detail.name);
    setEmail(detail.email);
    setMobile(detail.mobile);
    setTown(detail.town);

  };
  const showToastMessage = () => {
    toast.success('Record deleted succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  };  
  const handleDelete = (item) => {

    console.log(item);
    axios({
      method:'delete',
      url:`/api/supplier/${deleteid}/`,
    }).then((Response)=>{
      handleModalShowHide1()
      showToastMessage()
      refreshData();
    })
    axios({
      method:'post',
      url:'/api/audit/',
      data:{username:username1,operation:'Supplier deleted',table:'Suppliers',owner:useremail},
    })
    .then(res => {
       
    
    })
    .catch(err => {})
  };
  const cleaFields=()=>{
    setName('');
    setEmail('');
    setMobile('');
    setTown('');
    refreshData();
  }
  useEffect(()=>{
    checkAuthenticated();
    
      },[]) 
      useEffect(()=>{
     
      },[isAuthenticated]) 
      const handleModalShowHide1=(id)=> {
        setDeleteId(id)
        setShowhide1(!showhide1);
        console.log("clicked");
      }
        if(payment=="false"){
          return <Redirect to='/payment'/>}
  return (
    <div>
      <div className='path'><i className='fa fa-home'></i>{edit? '/ Products / Edit Item':'/ Contacts / Suppliers'}</div>
        <ToastContainer
       hideProgressBar={true}
      />
        <div className='bodysection col-lg-6'>
        <div className=' col-lg-12 theader'>< h5>Suppliers List</h5> </div>
        {asupplier=="true"| isadmin=="true" ?<Button  onClick={handleModalShowHide} className="m-2 btn-primary">
           + New
       </Button>:""}
       <Modal show={showhide}>
           <Modal.Header closeButton onClick={closeModal}>
           <Modal.Title>New Supplier details</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
<FormGroup>
<Label>Names</Label>
<Input type="text" placeholder="Enter name"name="title" onChange={e => setName(e.target.value)} value={names} required/>
</FormGroup>

<FormGroup>
<Label>Email</Label>
<Input type="text" placeholder="Enter Email"value={email} onChange={e => setEmail(e.target.value)} name="email"  required/>

</FormGroup>
<FormGroup>
<Label>Mobile.</Label>
<Input type="number" placeholder="Enter mobile"name="author" value={mobile} onChange={e => setMobile(e.target.value)} required />
</FormGroup>
<FormGroup>
<Label>Town.</Label>
<Input type="text" placeholder="Enter Town"name="town" value={town} onChange={e => setTown(e.target.value)} required />
</FormGroup>
<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       
       required
     
     />
</FormGroup>
   
           <Button color="primary"className="mt-2 " type="submit" onClick={(e)=> handleSubmit(e)}>
Submit
</Button>  
<Button color="primary ml-2"className=" m-2 mt-3" type=""onClick={closeModal}>
Close
</Button>
</Form>

</Modal.Body>

       </Modal>
       <ToolkitProvider
keyField="id"
data={[...data] }
columns={ columns }
exportCSV={{ onlyExportFiltered: true, exportAll: false }}
search
>
{
props => (
<div>
<ExportCSVButton { ...props.csvProps } className='btn btn-primary m-1'>Export </ExportCSVButton> 

<SearchBar { ...props.searchProps } />

<BootstrapTable keyField='id'
{...props.baseProps}
data={ data } 
columns={ columns } 
pagination={ paginationFactory(options) }className="pgnav"
wrapperClasses="table-responsive">  

</BootstrapTable>
</div> 
)
}
</ToolkitProvider>

           </div>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Supplier?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting a Supplier Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={handleDelete}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  esupplier:state.auth.esupplier,
  dsupplier:state.auth.dsupplier,
  asupplier:state.auth.asupplier,
  username1:state.auth.username1,
  isadmin:state.auth.isadmin,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,fetchPayment})(Createinv)