import axios from 'axios';
import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_FAIL,
    AUTHENTICATED_SUCCESS,
    LOGOUT,
    IS_ACTIVE,
    NOT_ACTIVE,
    INVOICENUMBER_SET,
    INVOICENUMBER_REMOVE,
    USER_SUCCESS,
    USER_FETCHED,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    PAYMENT_FAIL,
    PAYMENT_SUCCESS,
    REGISTER_FAIL,
    REGISTER_SUCCESS,HOMERELOAD, HOMERELOADFALSE
} from './types';
import axiosInstance from '../axios'
import {Link,Redirect,Router,useNavigate} from 'react-router-dom';

localStorage.setItem('homereload', 'false')
export const checkAuthenticated=()=> async dispach=>{
    
if(localStorage.getItem('access')){
    localStorage.setItem('homereload', 'false')
    const body=localStorage.getItem('access');
    try{
const res = axios.post('/validate/',{token:body})
if((await res).data.code== 'true')
{
    dispach({
        type: AUTHENTICATED_SUCCESS
    });

}else{
    
    dispach({
        type: AUTHENTICATED_FAIL
    });
    
}
    }catch(err){
       
    }
}else{
    dispach({
        type: AUTHENTICATED_FAIL
    });
    
    return <Redirect to='/login'/>
  
}

};

export const load_user=()=>async dispach=>{

   const config={
    headers:{
        Authorization: `JWT ${localStorage.getItem('access')}`,
        'Content-Type':'application/json',
        acccept:'application/json'
    }
   };
        try{
            const res= await axiosInstance.get('/users/me/',config);
            dispach({
                type: USER_SUCCESS,
                payload: res.data
            })
              
            }catch(err){
                dispach({
                    type: USER_LOADED_FAIL,
                })
            } 
            


};

export const register_user=(data)=>async dispach=>{

    const config={
         'Content-Type':'application/json'
    };
         try{
             const res= await axiosInstance.post('/users/',data,config);
             dispach({
                 type: REGISTER_SUCCESS,
                 payload: res.data
             })
               
             }catch(err){
                 dispach({
                     type: REGISTER_FAIL,
                 })
             } 
             
 
 
 };
export const saveLogin=(email,id)=>async dispach=>{
   
    axios({
        method:'post',
        url:'/api/access/',
        data:{owner:id,postedby:email},
      })
    .then(res => {
        console.log(res.data)
    }) 
    .catch(err => {})
}

export const login=(email,password)=>async dispach=>{
                                                                   
try{
   
const res= await axios
.post('/login/',{
    username:email,
    password:password,
})
.then(res => {
   if(res.data.schoolid){
   
    localStorage.setItem('access', res.data.access)
    localStorage.setItem('sales',res.data.sales); 
    localStorage.setItem('useremail',res.data.schoolid); 
    localStorage.setItem('products',res.data.products);
    localStorage.setItem('reports',res.data.reports);  
    localStorage.setItem('system',res.data.system); 
    localStorage.setItem('users',res.data.users); 
    localStorage.setItem('purchases',res.data.purchases); 
    localStorage.setItem('expenses',res.data.expenses); 
    localStorage.setItem('user1',res.data.lastname); 
    localStorage.setItem('username',res.data.username); 
    localStorage.setItem('email',res.data.email); 
    localStorage.setItem('isadmin',res.data.isadmin); 
    localStorage.setItem('isprimary',res.data.isprimary); 
    localStorage.setItem('isstaff',res.data.isstaff); 
    localStorage.setItem('isparent',res.data.isparent); 
    localStorage.setItem('store',res.data.store); 
    localStorage.setItem('payroll',res.data.payroll); 
    localStorage.setItem('contacts',res.data.contacts); 
    if(res.data.roles){
    localStorage.setItem('name',res.data.roles[0].name); 
    localStorage.setItem('sitems',res.data.roles[0].sitems); 
    localStorage.setItem('pitems',res.data.roles[0].pitems); 
    localStorage.setItem('vitems',res.data.roles[0].vitems); 
    localStorage.setItem('asreturn',res.data.roles[0].asreturn); 
    localStorage.setItem('apurchase',res.data.roles[0].apurchase); 
    localStorage.setItem('vpurchase',res.data.roles[0].apurchase); 
    localStorage.setItem('apreturn',res.data.roles[0].apreturn); 
    localStorage.setItem('home',res.data.roles[0].home); 
    localStorage.setItem('aitem',res.data.roles[0].aitem); 
    localStorage.setItem('vitem',res.data.roles[0].vitem); 
    localStorage.setItem('ditem',res.data.roles[0].ditem); 
    localStorage.setItem('eitem',res.data.roles[0].eitem);
    localStorage.setItem('stocktake',res.data.roles[0].stocktake);
    localStorage.setItem('acategory',res.data.roles[0].acategory);
    localStorage.setItem('vcategory',res.data.roles[0].vcategory);
    localStorage.setItem('ecategory',res.data.roles[0].ecategory);
    localStorage.setItem('dcategory',res.data.roles[0].dcategory);
    localStorage.setItem('reconcile',res.data.roles[0].reconcile);
    localStorage.setItem('auom',res.data.roles[0].auom);
    localStorage.setItem('euom',res.data.roles[0].euom);
    localStorage.setItem('duom',res.data.roles[0].duom);
    localStorage.setItem('vuom',res.data.roles[0].vuom);
    localStorage.setItem('vsreport',res.data.roles[0].vsreport);
    localStorage.setItem('vpreport',res.data.roles[0].vpreport);
    localStorage.setItem('vprreport',res.data.roles[0].vprreport);
    localStorage.setItem('vmonitor',res.data.roles[0].vmonitor);
    localStorage.setItem('astore',res.data.roles[0].astore);
    localStorage.setItem('vstore',res.data.roles[0].vstore);
    localStorage.setItem('dstore',res.data.roles[0].dstore);
    localStorage.setItem('aquote',res.data.roles[0].aquote);
    localStorage.setItem('glabel',res.data.roles[0].vquote);
    localStorage.setItem('dstore',res.data.roles[0].dstore);
    localStorage.setItem('arole',res.data.roles[0].arole);
    localStorage.setItem('vrole',res.data.roles[0].vrole);
    localStorage.setItem('drole',res.data.roles[0].drole);
    localStorage.setItem('auser',res.data.roles[0].auser);
    localStorage.setItem('vuser',res.data.roles[0].vuser);
    localStorage.setItem('duser',res.data.roles[0].duser);
    localStorage.setItem('acustomer',res.data.roles[0].acustomer);
    localStorage.setItem('vcustomer',res.data.roles[0].vcustomer);
    localStorage.setItem('ecustomer',res.data.roles[0].ecustomer);
    localStorage.setItem('dcustomer',res.data.roles[0].dcustomer);
    localStorage.setItem('asupplier',res.data.roles[0].asupplier);
    localStorage.setItem('vsupplier',res.data.roles[0].vsupplier);
    localStorage.setItem('esupplier',res.data.roles[0].esupplier);
    localStorage.setItem('dsupplier',res.data.roles[0].dsupplier);
    localStorage.setItem('astaff',res.data.roles[0].astaff);
    localStorage.setItem('vstaff',res.data.roles[0].vstaff);
    localStorage.setItem('estaff',res.data.roles[0].estaff);
    localStorage.setItem('dstaff',res.data.roles[0].dstaff);
    localStorage.setItem('asallowance',res.data.roles[0].asallowance);
    localStorage.setItem('asdeduction',res.data.roles[0].asdeduction);
    localStorage.setItem('aallowance',res.data.roles[0].aallowance);
    localStorage.setItem('vallowance',res.data.roles[0].vallowance);
    localStorage.setItem('eallowance',res.data.roles[0].eallowance);
    localStorage.setItem('dallowance',res.data.roles[0].dallowance);
    localStorage.setItem('adeduction',res.data.roles[0].adeduction);
    localStorage.setItem('vdeduction',res.data.roles[0].vdeduction);
    localStorage.setItem('ededuction',res.data.roles[0].ededuction);
    localStorage.setItem('ddeduction',res.data.roles[0].ddeduction);
    localStorage.setItem('aoadvance',res.data.roles[0].aoadvance);
    localStorage.setItem('aaadvance',res.data.roles[0].aaadvance);
    localStorage.setItem('voadvance',res.data.roles[0].voadvance);
    localStorage.setItem('vaadvance',res.data.roles[0].vaadvance);
    localStorage.setItem('vop9',res.data.roles[0].vop9);
    localStorage.setItem('vap9',res.data.roles[0].vap9);
    localStorage.setItem('vsreturns',res.data.roles[0].vsreturns);
    localStorage.setItem('vapayroll',res.data.roles[0].vapayrol);
    localStorage.setItem('gpayroll',res.data.roles[0].gpayroll);
    localStorage.setItem('vopayroll',res.data.roles[0].vopayroll);
    localStorage.setItem('vapayroll',res.data.roles[0].vapayroll);}
    dispach(load_user())
    dispach(saveLogin(email,res.data.schoolid))
    
dispach({
   type: LOGIN_SUCCESS,
   payload: res.data
});

window.location.href = "/";
   }else{
    dispach({
        type: LOGIN_FAIL,
        
     });
   }
});

}
catch(err){
   
    dispach({
        type: LOGIN_FAIL,
    })
    dispach(checkAuthenticated());
    console.log(err);

}

};

export const fetchUser=(email)=>async dispach=>{
   
try{
  
const [res]= axios
.post('/totalcompany/',{
    useremail:email
})
.then((ress)=>{
console.log(ress.data)
ress.data.length && localStorage.setItem('adminname', JSON.stringify(ress.data[0].names));

dispach({
    type: USER_FETCHED,
    payload: ress.data
})

})

      
}
catch(err){
   

}

};

export const fetchPayment=(email)=>async dispach=>{
  
    try{
        
    const [res]=  axios
    .post('/paysearch/',{
        useremail:email
    })
    .then((ress)=>{
        if(new Date().getDate()- new Date(ress.data[0].dateentered).getDate()<=31*ress.data[0].month){
    localStorage.setItem('payment', JSON.stringify(true));
    dispach({
        type: PAYMENT_SUCCESS,
        payload: ress.data
    })}else{
        
    localStorage.setItem('payment', JSON.stringify(false));
    dispach({
        type: PAYMENT_FAIL,
    })}
    
    })
    
          
    }
    catch(err){
       
    
    }
    
    };

export const logout=()=>dispach=>{
    localStorage.removeItem('useremail');
    window.location.href = "/login";
    dispach({
        type:LOGOUT
    });
};
export const audit1=(usernm,operation,table,useremail)=>dispach=>{
   
    axios({
        method:'post',
        url:'/api/audit/',
        data:{username:usernm,operation,table,useremail},
      })
      .then(res => {
         
      dispach({
        
     })
      
      })
      .catch(err => {})
     
 
};
export const setId=(invoice)=>dispach=>{
    
    localStorage.setItem('id', JSON.stringify(invoice));
    dispach({
        type:INVOICENUMBER_SET,
        payload:invoice
    });
};
export const reloadHome=()=>dispach=>{
    
    localStorage.setItem('homereload','true');
    dispach({
        type:HOMERELOAD,
       
    });
};
export const reloadHomeFalse=()=>dispach=>{
    
    localStorage.setItem('homereload','false');
    dispach({
        type:HOMERELOADFALSE,
       
    });
};
export const removeInvoice=()=>dispach=>{
    dispach({
        type:INVOICENUMBER_REMOVE,
    });
};

export const fetchUser1=(useremail)=>dispach=>{
     //fetch user
     let data1 ;
     axios({
       method:'post',
       url:'/totalcompany/',
       data:{useremail:useremail},
     })
     .then(res => {
         data1 = res.data;
          //set fields
     dispach({
        type: USER_FETCHED,
        payload: data1[0].names
    })
     
     })
     .catch(err => {})
    
};

export const checkActive=()=>dispach=>{

    if(window.location.pathname == '/poster'|window.location.pathname == '/business-card' |window.location.pathname == '/wedding-card' |window.location.pathname == '/logo'){
    dispach({
        type:IS_ACTIVE
    })}else{
        dispach({
            type:NOT_ACTIVE
        })
    }
};
export const resetPassword=(email,token)=> async dispach=>{
    const config={
        headers:{
            'Content-Type':'application/json',
        }
       };
       const body={email:email,token:token};
       try{
         axios.post("/sendresetemail/",body)
         .then(res => {
           const data1 = res.data;
         if(data1=="success"){
            dispach({
                type:PASSWORD_RESET_SUCCESS
                
            })
            localStorage.setItem('preset',true);
         }else{
            dispach({
                type:PASSWORD_RESET_FAIL
            })
         }
        
        })
        .catch(err => {
            console.log('error here')

        })
       }
       catch(err){
        
        dispach({
            type:PASSWORD_RESET_FAIL
        })
       }
}

export const resetPasswordConfirm=(uid,token,new_password,re_new_password)=> async dispach=>{
   
    const headers={
            'Content-Type':'application/json',
        
       };
       const body=JSON.stringify({uid,token,password:new_password,re_new_password});
       try{
        await axios.post("/resetpassword/",{uid,token,password:new_password,re_new_password})
        dispach({
            type:PASSWORD_RESET_CONFIRM_SUCCESS
        })
       }
       catch(err){
        dispach({
            type:PASSWORD_RESET_CONFIRM_FAIL
        })
       }
}