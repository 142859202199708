import React,{useEffect,useState} from 'react'
import axios from "axios";
import { Table } from 'react-bootstrap';
import { Card,Modal} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { checkAuthenticated,load_user,login,fetchPayment } from './../actions/auth';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

function Category({isAuthenticated,username1,checkAuthenticated,useremail,acategory,ecategory,dcategory,isadmin,payment,fetchPayment,username}) {
  const [data,setData]=useState([""]);
  const [name,setName]=useState("");
  const [price,setPrice]=useState("");
  const [dateposted,setDateposted]=useState("");
  const [quantity,setQuantity]=useState("");
  const [category,setCategory]=useState("");
  const [saveSuccess,setSaveSuccess]=useState(false);
  const [updateSuccess,setUpdateSuccess]=useState(false);
  const [id,setId]=useState("");
  const [edit,setEdit]=useState(false);
  const [error1,setError]=useState(false);
  const [showhide1,setShowhide1]=useState(false);
  const [showhide,setShowhide]=useState(false);
  const [showhide2,setShowhide2]=useState(false);
  const [deleteid,setDeleteId]=useState("");
  const [heading,setHeading]=useState(['Name','Action']);
const [categoryError,setCategoryError]= useState('')
const [branch2,setBranch2]=useState([]);
  const [branch,setBranch]=useState([]);
  const [branchError,setBranchError]=useState(false);
  
  useEffect(()=>{
   
    checkAuthenticated();
    
    fetchPayment(useremail);
  
    let data1 ;

    axios({
      method:'post',
      url:'/totalcategory/',
      data:{useremail:useremail},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
        console.log(data1)  
    })
    .catch(err => {})
    axios({
      method:'post',
      url:'/totalstore/',
      data:{useremail:useremail},
     })
     .then(res => {
       
        setBranch(res.data)
        //setUnit(data5)
     })
     .catch(err => {})
      },[]) 
      const showToastMessage5 = () => {

        toast.error('Operation not allowed on admin account', {
            position: toast.POSITION.BOTTOM_CENTER
        });
      };  
     const handleModalShowHide=()=> {
      setSaveSuccess(false)
        setShowhide(!showhide);
    }
    const closeModal=()=> {
      setShowhide(false);
  }
  const refreshData=()=>{
    let data1 ;

    axios({
      method:'post',
      url:'/totalcategory/',
      data:{useremail:useremail},
    })
    .then(res => {
        data1 = res.data;
        setData(data1);
        console.log(data1)  
    })
    .catch(err => {})
    
  }
  const showToastMessage2 = () => {
     
    toast.error('You dont have permission to perform this action', {
        position: toast.POSITION.TOP_CENTER
    });
  }; 

  const showToastMessage = () => {
    toast.success('Record deleted succesfully', {
        position: toast.POSITION.TOP_CENTER
    });
  }; 
  const handledate = () => {
    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return date;
  }
  const handleSubmit = (e) => {
    e.preventDefault()
 if(!category){
setError(true)
 }
 else
 if(useremail=="")
 {
showToastMessage2();
 }else
    if(edit){
   
      axios({
        method:'put',
        url:`/api/category/${id}/`,
        data:{name:category,owner:useremail},
      }).then((Response)=>{
        //clear fields
        setEdit(false);
        setId('');
    cleaFields();
      })
      setUpdateSuccess(true)
      axios({
        method:'post',
        url:'/api/audit/',
        data:{username:username1,operation:'Category edited',table:'Categories',owner:useremail},
      })
      .then(res => {
         
      
      })
      .catch(err => {})
    }
    else{
      if(branch2.length==0 && isadmin=="true"){
        closeModal()
        handleModalShowHide2()
          }else{
   axios({
     method:'post',
     url:'/api/category/',
     data:{name:category,owner:useremail},
   }).then((Response)=>{
    //clear fields
    cleaFields();
   })
   setSaveSuccess(true);
  }
  axios({
    method:'post',
    url:'/api/audit/',
    data:{username:username1,operation:'Category inserted',table:'Categories',owner:useremail},
  })
  .then(res => {
     
  
  })
  .catch(err => {})
}
  };
  const editItem = (detail) => {
    setId(detail.id);
    setEdit(true);
    setShowhide(true);
    setCategory(detail.name);
  };
  const handleDelete = (item) => {
    if(window.confirm('Are You Sure You want delete this record?')){
    console.log(item);
    axios({
      method:'delete',
      url:`/api/category/${item.id}/`,
    }).then((Response)=>{
      
      
      alert("record deleted succesfully");
      refreshData();
    })
  }
  };
  const cleaFields=()=>{
    setName('');
    setCategory('');
    setPrice('');
    setQuantity('');
    refreshData();
  }
  useEffect(()=>{
    
   /*  const reloadCount = sessionStorage.getItem('reloadCount');
    if(reloadCount < 2) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
    } else {
      sessionStorage.removeItem('reloadCount');
    }*/
    setTimeout(() => {
      
      if(isadmin=="true"){
        handleModalShowHide2()
      }
    
  }, 3000)
      },[]) 
     
      const handleModalShowHide2=()=> {
        setSaveSuccess(false)
          setShowhide2(true);
      }  
      const deleteData = async () => {
        if(useremail=="4")
        {
  showToastMessage2();
        }
        else{
        handleModalShowHide1()
           const result = await axios({
             method:'put',
             url:`/api/category/${deleteid}/`,
             data:{isdeleted:"deleted",deletedby:username}
           })
           .then((Response)=>{
            showToastMessage()
             // setSuccess(true)
             // hidesuccess();
              //fetchAllExams()
                refreshData()    
           })
           .catch((error) => {
            if (error.code="ERR_BAD_RESPONSE"){
             
                    }
            
           })
           axios({
            method:'post',
            url:'/api/audit/',
            data:{username:username1,operation:'Category deleted',table:'Categories',owner:useremail},
          })
          .then(res => {
             
          
          })
          .catch(err => {})
          }
           }

           const closeModal1=()=> {
            setShowhide1(false);
            
            }          
if(isAuthenticated=="false"){
 return <Redirect to='/login'/>}
 const closeModal2=()=> {
  setShowhide2(false);
}
 const handleCategoryChange1 = (event) => {
  setBranch2(event.target.value) 
  closeModal2()
 // filterItems()
} 
 const handleModalShowHide1=(id)=> {

  setDeleteId(id)
  setShowhide1(!showhide1);
  console.log("clicked");
}
 if(payment=="false"){
  return <Redirect to='/payment'/>}
  return (
    <div>
       <ToastContainer
       hideProgressBar={true}
      />
       <div className='path'><i className='fa fa-home'></i>{edit? '/ Products / Edit Item':'/ Products / Category'}</div>
        <div className='bodysection col-lg-3'>
        <div className=' col-lg-12 theader'>< h5>Category List</h5> </div>
        {isadmin=="true"| acategory=="true" ?<Button  onClick={handleModalShowHide} className="m-2 btn-primary">
           + Category
       </Button>:""}
       <Modal show={showhide}>
           <Modal.Header closeButton onClick={closeModal}>
           <Modal.Title> Category Details</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
<FormGroup>

<Label>Name</Label>
<Input type="text" placeholder="Enter name"name="title" onChange={e => setCategory(e.target.value)} value={category} required/>
</FormGroup>


   
           <Button color="primary"className="mt-2 " type="submit" onClick={(e)=> handleSubmit(e)}>
Submit
</Button>  
<Button color="primary ml-2"className=" m-2 mt-3" type=""onClick={closeModal}>
Close
</Button>
</Form>

</Modal.Body>

       </Modal>
 
<Table responsive className="">
  <thead>
    <tr >
    {heading.map(head => <th>{head}</th>)}
    </tr>
  </thead>
 
                <tbody>     
                {data.length==0? <div>No Records found</div>:data.map(detail => (
                  <tr key={detail.id} >
                     <td className="col-2" >{detail.name}</td>
                     <td class="col-1">{isadmin=="true"| ecategory=="true" ?<span className='border'><i class="fa fa-edit btn1" onClick={() => editItem(detail)}></i></span>:""}{isadmin=="true"| dcategory=="true" ?<span className='border'><i class="fa fa-trash btn1" onClick={e =>{ handleModalShowHide1(detail.id)}}></i></span>:""}</td>
                   </tr>
             ))}
    </tbody>
    
     
           </Table> 
           </div>
           <div className='col-lg-1 centermodal'>
           <Modal show={showhide1} className='modal1 modal-dialog-centered'>
           
           <Modal.Body className='modalbody pb-1 mb-1'>
            <label style={{color:'red','font-size':'18px'}}>Delete Category?</label><br/>
           <label style={{'font-size':'15px'}}>Deleting Category Record is Irreversible. Would you like to Proceed?</label>
           <div className='deletebtns'> 
            <Button color="danger ml-2 "className="  pb-1 " type=""onClick={deleteData}>
Yes
</Button>
            <Button color="primary ml-2  "className="  pb-1 " type=""onClick={closeModal1}>
No
</Button>
</div>
</Modal.Body>

       </Modal>
       </div>
       <div className='col-lg-2'>
       <Modal show={showhide2}className='col-lg-4 d-flex ml-auto mr-auto'>
           <Modal.Header closeButton onClick={closeModal2}>
           <Modal.Title>Select Store</Modal.Title>
           </Modal.Header>
           <Modal.Body>
           {saveSuccess&& <div class="alert alert-success" role="alert">
  Record saved succesfully
</div>}
{updateSuccess && <div class="alert alert-success" role="alert">
  Record updated succesfully
</div>}
           <Form  >
        
  { <FormGroup className='mr-2 ml-2 '>

<div style={{width:'220px'}}className={branchError &&'errorborder'} >
              
          <select onChange={handleCategoryChange1} className={branchError ? 'errorborder newselect1':'newselect'}>
 
 <option>{'Select Branch Name'}</option>
 { branch.map((option, index) => {
     return <option key={index} >
         {option.id + ' '+ option.name}
     </option>
 })}
</select>    
          
            </div>
<div className={branchError ?'errmessage1':'errmessagehide1'}>Branch is Reguired</div>
</FormGroup>}

<FormGroup>

<Input
       type="hidden"
       id="todo-description"
       name="dateposted"
       value={dateposted}
       required
     
     />
</FormGroup>
   
</Form>

</Modal.Body>

       </Modal>
       </div>
        </div>
  )
}

const mapStateToProps=state=>({
  isAuthenticated:state.auth.isAuthenticated,
  useremail:state.auth.useremail,
  isadmin:state.auth.isadmin,
 acategory:state.auth.acategory,
 ecategory:state.auth.ecategory,
 dcategory:state.auth.dcategory,
 username1:state.auth.username1,
  payment:state.auth.payment
  });
export default connect(mapStateToProps,{login,checkAuthenticated,load_user,fetchPayment})(Category)