
import './Adminnav.css';
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Alert,
  } from "reactstrap";
  import { Offline, Online } from "react-detect-offline";
  import { Oval } from 'react-loader-spinner'
import logo from './logot.jpg';
import { NavLink,BrowserRouter as Router,Route,Switch,Link,Redirect} from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import {connect} from 'react-redux';
import { logout } from './../actions/auth';
import React,{useEffect,useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding,faTimes,faBars,faSignOut,faUser,faUserAlt,faUserCircle,faBell} from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
function Adminnav({click,handleClick,aitem,sitems,apurchase,adminMenuClick1,adminMenu,logout,useremail,isstaff,isadmin,sales,products,purchases,fetchedUser,isAuthenticated,user1,username}) {
    const [names,setName]=useState("");
    const [offline,setOffline]=useState(false);
    const [count,setCount]=useState("");
    useEffect(()=>{   
        if(isadmin=="true"){
        axios({
            method:'post',
            url:'/returnviews/',
            data:{useremail:useremail,username:username}
          })
          .then(res => {
             setCount(res.data)
          })
          .catch(err => {})
        }
        window.addEventListener('offline', function(e) {
            setOffline(true); });
            
            window.addEventListener('online', function(e) {setOffline(false);
            });
      },[]) 
      useEffect(() => {
       
        if(username=="test2024"){
            logout()
        }
    }, []);
      const editAccess=()=>{
      
        let data4 ;
        axios({
         method:'post',
         url:'/editaccess/',
         data:{useremail:useremail,username:username},
       })
       .then(res => {
          
       })
       .catch(err => {})}
      const reLoadPage=()=>{
       
        window.location.reload();
    }
    if(!isAuthenticated){
  
       /* <Router>
       return <Redirect to='/login'/>
    </Router>*/}
    const Capitalize=(str)=>{
        return str.charAt(0).toUpperCase() + str.slice(1);
        }
    return (
        <nav className={window.location.pathname == '/pos1'?"navbar1 dontprint":'navbar dontprint'}>
           <Router>
           {window.location.pathname !== '/pos1'&& <div className='toplogo1 pb-4' onClick={reLoadPage}>
            <NavLink  to='/' state={{ previousPath:'/'}}> <div className='jimslogo  mt-2'> <img src={logo}height="30px" width={''} /></div>
          <h6 className=' mr-1 pb-2'>SEJOPOS</h6></NavLink>
            </div> }
           
        <div className="nav_icon" onClick={handleClick}>
       
        {click? <FontAwesomeIcon icon={faTimes}className="mobilebtn" />:<FontAwesomeIcon icon={faBars}className="mobilebtn" />}
        </div>
        
         <div className="navbar_right " onClick={reLoadPage}>
       {isadmin=="true" && <span className='mgn mr-5'>
            <h6 className='req'> advance requests</h6>
        <FontAwesomeIcon icon={faBell}className="wticon mt-2" />
<span className='noti mr-5 '>{count}</span>
        </span>}
        {isadmin=="true"| aitem=="true"|apurchase=="true"|sitems=="true"?<NavLink  to='/' activeClassName="" ><div className='navbtn' ><i class="fa fa-home product3"></i><span >Home</span></div></NavLink>:""}
       {isadmin=="true"| sitems=="true"?<NavLink  to='/pos1' activeClassName="" ><div className='navbtn' onClick={reLoadPage}><i class="fa fa-shopping-cart product3"></i><div className='iconb'>PoS</div></div></NavLink>:""}
       {isadmin=="true"| aitem=="true"?<NavLink  to='/additem' activeClassName="" ><div className='navbtn' onClick={reLoadPage}><i class="fa fa-shopping-basket product3"></i>+ Item</div></NavLink>:""}
      {isadmin=="truehh"|apurchase=="truehh"?<NavLink  to='/addpurchase' activeClassName="" ><div className='navbtn' onClick={reLoadPage}><i class="fa fa-shopping-cart product3"></i>+ Purchases</div></NavLink>:""}
      
      
        </div>
        <div className="navbar_right1 ml-5">
        <Offline> { <div className='offline'>
                <span>Oops! unable to connect to the server. 
                    Check your internet connection.
                    </span>
                    <div className='mr-2'>Trying to reconnect{' '}

<Oval
  visible={true}
  height=""
  width="14"
  color="#4fa94d"
  ariaLabel="oval-loading"
  wrapperStyle={{'display':"inline"}}
  wrapperClass=""
  />
</div>
                
            </div>}</Offline>
        <a href="#" className='text-white' onClick={adminMenuClick1}>
            {isstaff=="true"? Capitalize(user1) :Capitalize(username)}
        
        <FontAwesomeIcon icon={faUserCircle}className="usericon  ml-2" onClick={adminMenuClick1}/>
        </a>
        
        </div> 
      {useremail=="3"&& <div className='navbar_right2 '><marquee width="40%" direction="right" height="100px">
This is a sales agent account used for demo purposes only.
</marquee></div> }
        <div >
       
       </div>
       <div className={adminMenu ?'adminmenu1 ':'adminactive'} onClick={adminMenuClick1}>
           <ul>
               <li className="newbtn"onClick={reLoadPage}><Link exact to={`/account/`} ><FontAwesomeIcon icon={faUserCircle}className="mr-2" onClick={adminMenuClick1}/>Account</Link></li>
               <li onClick={e =>{logout();editAccess()}} className="newbtn"><FontAwesomeIcon icon={faSignOut}className="mr-2" />Logout</li>
           </ul>
       </div>
       </Router>
        </nav>
    )
}
const mapStateToProps=state=>({
    useremail:state.auth.useremail,
    user1:state.auth.user1,
    isAuthenticated:state.auth.isAuthenticated,
    fetchedUser:state.auth.fetchedUser,
    isadmin:state.auth.isadmin,
    sales:state.auth.sales,
    products:state.auth.products,
    purchases:state.auth.purchases,
    isstaff:state.auth.isstaff,
    aitem:state.auth.aitem,
    apurchase:state.auth.apurchase,
    sitems:state.auth.sitems,
     username:state.auth.username1
    });

export default connect(mapStateToProps,{logout})(Adminnav)
